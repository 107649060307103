import { orderConstants } from './constants';
import { isFinishedOrder } from '../../helpers/order';
import { isNull } from '../../views/SmartTrade/Common';

// const orders = JSON.parse(localStorage.getItem('orders'));
const initialState = {
  availableAmounts: [],
  orders: [],
  myGroups: [],
  convert: {},
};

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.ACTION_GET_ORDERS:
    case orderConstants.ACTION_LOCAL_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case orderConstants.ACTION_CONVERT_ORDER:
      return {
        ...state,
        convert: action.payload,
      };
    case orderConstants.ACTION_POST_ORDER:
      return {
        ...state,
        orders: state.orders.concat(action.payload),
      };
    case orderConstants.ACTION_GET_ORDER: {
      if (isNull(action.payload) || isNull(action.payload.OrderId))
        return state;
      // Si ordre fini disparait
      if (isFinishedOrder(action.payload.State)) {
        const orderId = action.payload.OrderId;
        return {
          ...state,
          orders: state.orders.filter(
            (x) => x.OrderId !== orderId && x.BaseOrderId !== orderId,
          ),
        };
      }

      // sinon refresh
      const index = state.orders.findIndex(
        (x) => x.OrderId === action.payload.OrderId,
      );
      const { orders } = state;
      if (index !== -1) {
        orders[index] = action.payload;
      } else {
        orders.push(action.payload);
      }
      return {
        ...state,
        orders: [...orders],
      };
    }
    case orderConstants.ACTION_DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter(
          (x) =>
            x.OrderId !== action.payload && x.BaseOrderId !== action.payload,
        ),
      };
    case orderConstants.ACTION_LOCAL_AVAILABLE_AMOUNT:
      return {
        ...state,
        availableAmounts: action.payload,
      };
    case orderConstants.ACTION_GET_AVAILABLE_AMOUNT: {
      const aas = action.payload;
      if (!aas || aas.length === 0) return state;
      for (const aa of aas) {
        if (!aa || !Object.hasOwn(aa, 'Pairs')) continue;
        const value = aa.Pairs;
        aa.PairSelect = value.map((obj) => ({
          label: obj.Pair,
          value: obj.Pair,
          pair: obj.Pair,
        }));
      }
      return {
        ...state,
        availableAmounts: [...aas],
      };
    }
    case orderConstants.ACTION_GET_MY_GROUPS:
    case orderConstants.ACTION_LOCAL_MY_GROUPS:
      return {
        ...state,
        myGroups: action.payload,
      };
    default:
      return state;
  }
}
