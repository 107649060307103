export const traderConstants = {
  ACTION_GET_TRADERS: '@trader/ACTION_GET_TRADERS',
  ACTION_GET_TRADER: '@trader/ACTION_GET_TRADER',
  ACTION_DELETE_TRADER: '@trader/ACTION_DELETE_TRADER',
  ACTION_GET_STAT_TRADER: '@trader/ACTION_GET_STAT_TRADER',

  ACTION_POST_SUBSCRIPTION_GROUP: '@trader/ACTION_POST_SUBSCRIPTION_GROUP',
  ACTION_POST_UNSUBSCRIBE_GROUP: '@trader/ACTION_POST_UNSUBSCRIBE_GROUP',

  ACTION_PAIEMENT: '@trader/ACTION_PAIEMENT',

  ACTION_LOCAL_SCHOLARS: '@trader/ACTION_LOCAL_SCHOLARS',
  ACTION_GET_SCHOLARS: '@trader/ACTION_GET_SCHOLARS',
  ACTION_TOGGLE_FOLLOWER: '@trader/ACTION_TOGGLE_FOLLOWER',
  ACTION_REFRESH_FOLLOWER: '@trader/ACTION_REFRESH_FOLLOWER',
  ACTION_GET_FOLLOWER: '@trader/ACTION_GET_FOLLOWER',
  ACTION_DELETE_FOLLOWER: '@trader/ACTION_DELETE_FOLLOWER',
};
