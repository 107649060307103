import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  DataTypeProvider,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { createScholarGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import {
  DateProvider,
  NumberEditorBase,
  numberFilterOperations,
} from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';

import withWidth from '@material-ui/core/withWidth';
import { getColumnsAssets, getColumnsCard } from '../../helpers/card';
import { getQuestColumnOrders } from '../../redux/config/reducer';

class TableScholar extends React.PureComponent {
  render() {
    const {
      columnWidths,
      colorColumns,
      columnOrder,
      rows,
      columns,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      totalSummaryItems,
      onFiltersChange,
      onPageSizeChange,
      onColumnOrderChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          <SummaryState totalItems={totalSummaryItems} />
          {/*<SelectionState />*/}

          {/*<GroupingState />*/}
          {/*<IntegratedGrouping />*/}

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          {/*<IntegratedSelection />*/}

          <DragDropProvider />
          <DateProvider for={['Date']} />
          <DataTypeProvider
            for={[
              'Prana',
              'Cta',
              'Trisel',
              'Level',
              'NextLevelExpThreshold',
              'NextLevelExpRequired',
              'CurrentLevelPercent',
              'LevelExpTotal',
              'CurrentEraDuelCount',
              'CurrentEraWinCount',
              'CurrentEraRatio',
              'CurrentEraMmr',
              'CardCount',
              'CardPowerSum',
              'FreeMintCount',
              'FreeAnimationLevelCount',
            ].concat(
              getQuestColumnOrders().filter(
                (x) => x.indexOf('RewardClaimed') === -1,
              ),
            )}
            editorComponent={NumberEditorBase}
            availableFilterOperations={numberFilterOperations}
          />

          <Table />
          {/*<TableSelection showSelectAll={true} />*/}
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableSummaryRow />
          <TableFilterRow showFilterSelector={true} />
          <PagingPanel pageSizes={pageSizes} />
          {/*<TableGroupRow />*/}
          {/*<Toolbar />*/}
          {/*<GroupingPanel showSortingControls={true} />*/}
        </Grid>
      </Card>
    );
  }
}

const getQuest = (type) => {
  return [
    {
      name: type + 'NbQuest',
      title: type + 'NbQuest',
      getCellValue: (row) => row?.Quests[type]?.NbQuest,
    },
    {
      name: type + 'NbQuestClaimed',
      title: type + 'NbQuestClaimed',
      getCellValue: (row) => row?.Quests[type]?.NbQuestClaimed,
    },
    {
      name: type + 'NbQuestCompleted',
      title: type + 'NbQuestCompleted',
      getCellValue: (row) => row?.Quests[type]?.NbQuestCompleted,
    },
    {
      name: type + 'RewardClaimed',
      title: type + 'RewardClaimed',
      getCellValue: (row) => (row?.Quests[type]?.RewardClaimed ? 'Yes' : 'No'),
    },
  ];
};

const mapStateToProps = (state, props) => {
  const { config } = state;
  const { scholarGrid } = config;
  const { scholar } = props;
  const {
    filters,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
  } = scholarGrid;
  const codeLang = getActiveCode(state);
  let columns = [
    { name: 'Date', title: 'Date' },

    { name: 'Prana', title: 'Prana' },
    { name: 'Cta', title: 'Cta' },
    { name: 'Trisel', title: 'Trisel' },
    { name: 'Level', title: 'Level' },

    { name: 'NextLevelExpThreshold', title: 'NextLevelExpThreshold' },
    { name: 'NextLevelExpRequired', title: 'NextLevelExpRequired' },
    { name: 'CurrentLevelPercent', title: 'CurrentLevelPercent' },
    { name: 'LevelExpTotal', title: 'LevelExpTotal' },

    { name: 'CurrentEraDuelCount', title: 'CurrentEraDuelCount' },
    { name: 'CurrentEraWinCount', title: 'CurrentEraWinCount' },
    { name: 'CurrentEraRatio', title: 'CurrentEraRatio' },
    { name: 'CurrentEraMmr', title: 'CurrentEraMmr' },
    { name: 'CardCount', title: 'CardCount' },
    { name: 'CardPowerSum', title: 'CardPowerSum' },
    { name: 'FreeMintCount', title: 'FreeMintCount' },
    { name: 'FreeAnimationLevelCount', title: 'FreeAnimationLevelCount' },

    { name: 'Description', title: 'Description' },
    // {
    //   name: 'QuestDay',
    //   title: 'D. Completed',
    //   getCellValue: (row) => row.Quests['DAILY'].NbQuest,
    // },
  ].concat(
    getQuest('DAILY')
      .concat(getQuest('WEEKLY'))
      .concat(getQuest('EVENT'))
      .concat(getQuest('GENERAL')),
  );
  let ret = {
    codeLang: codeLang,
    rows: scholar,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    filters: filters,
    pageSizes: pageSizes,
    sorting: sorting,
    totalSummaryItems: totalSummaryItems,
    columns: columns,
  };
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(createScholarGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createScholarGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createScholarGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createScholarGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createScholarGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createScholarGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createScholarGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createScholarGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createScholarGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createScholarGridAction('columnWidths', widths)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableScholar));
