import { configConstants } from './constants';
import { getColumnsOrderCard, getColumnsWidthsCard } from '../../helpers/card';
import {listTokenType} from "../../helpers/general";
import {createExchangesGridAction} from "./actions";

const localTheme = JSON.parse(localStorage.getItem('theme'));
const noPopupTrading = JSON.parse(localStorage.getItem('noPopupTrading'));
const collapseMenu = JSON.parse(localStorage.getItem('collapseMenu'));
const exchangesGrid = JSON.parse(localStorage.getItem('exchangesGrid'));
const cardsGrid = JSON.parse(localStorage.getItem('cardsGrid'));
const scholarsGrid = JSON.parse(localStorage.getItem('scholarsGrid'));
const scholarGrid = JSON.parse(localStorage.getItem('scholarGrid'));
const suppliesGrid = JSON.parse(localStorage.getItem('suppliesGrid'));
const workersGrid = JSON.parse(localStorage.getItem('workersGrid'));
const defaultExchangesGrid = {
  // table
  columns: [],
  id: 1,
  rows: [],
  sorting: [{ columnName: 'CardId', direction: 'asc' }],
  pageSizes: [5, 10, 20, 50, 0],
  selection: [],
  selected: [],
  totalSummaryItems: [
    { columnName: 'Count', type: 'sum' },
    { columnName: 'Count', type: 'avg' },
    { columnName: 'FPPrice', type: 'sum' },
    { columnName: 'FPPrice', type: 'avg' },
    { columnName: 'LSPrice', type: 'sum' },
    { columnName: 'LSPrice', type: 'avg' },
    { columnName: 'OnSellPrice', type: 'sum' },
    { columnName: 'OnSellPrice', type: 'avg' },

    { columnName: 'LSDaysValueUSD', type: 'avg' },
    { columnName: 'LSDaysValueUSD', type: 'sum' },
    { columnName: 'LSDaysCount', type: 'sum' },
    { columnName: 'LSDaysCount', type: 'avg' },
    { columnName: 'LSDaysAvg', type: 'sum' },
    { columnName: 'LSDaysAvg', type: 'avg' },
    { columnName: 'LSDaysAvgEth', type: 'sum' },
    { columnName: 'LSDaysAvgEth', type: 'avg' },
    { columnName: 'LSDaysAvgUsdc', type: 'sum' },
    { columnName: 'LSDaysAvgUsdc', type: 'avg' },
    { columnName: 'LSDaysMin', type: 'sum' },
    { columnName: 'LSDaysMin', type: 'avg' },
    { columnName: 'LSDaysMax', type: 'sum' },
    { columnName: 'LSDaysMax', type: 'avg' },
  ],
  columnOrder: ['Count'].concat(getColumnsOrderCard()).concat([
    // 'amount',
    // 'currency',
    // 'value',
    // 'Count',
    'Stage',
    'walletName',
    'login',
    'IsFP',
    'OnSellPrice',
    'FPPrice',
    'FP',
    'LSPrice',
    'LS',

    'LSDaysValueUSD',
    'LSDaysCount',
    'LSDaysAvg',
    'LSDaysAvgEth',
    'LSDaysAvgUsdc',
    'LSDaysMin',
    'LSDaysMax',
  ]),
  columnWidths: getColumnsWidthsCard().concat([
    { columnName: 'amount', width: 100 },
    { columnName: 'currency', width: 100 },
    { columnName: 'value', width: 100 },
    { columnName: 'Count', width: 85 },
    { columnName: 'Stage', width: 100 },
    { columnName: 'CTAPrice', width: 100 },
    { columnName: 'walletName', width: 150 },
    { columnName: 'login', width: 200 },
    { columnName: 'FP', width: 100 },
    { columnName: 'LS', width: 100 },
    { columnName: 'FPPrice', width: 100 },
    { columnName: 'LSPrice', width: 100 },
    { columnName: 'IsFP', width: 50 },
    { columnName: 'OnSellPrice', width: 100 },

    { columnName: 'LSDaysValueUSD', width: 100 },
    { columnName: 'LSDaysCount', width: 85 },
    { columnName: 'LSDaysAvg', width: 100 },
    { columnName: 'LSDaysAvgEth', width: 100 },
    { columnName: 'LSDaysAvgUsdc', width: 100 },
    { columnName: 'LSDaysMin', width: 100 },
    { columnName: 'LSDaysMax', width: 100 },
  ]),
  filters: [],
};
if (exchangesGrid) {
  for (const tokenType in listTokenType) {
    if (!Object.hasOwn(exchangesGrid, tokenType)) {
      exchangesGrid[tokenType] = defaultExchangesGrid;
    }

    for (const e in defaultExchangesGrid) {
      if (!Object.hasOwn(exchangesGrid[tokenType], e)) {
        exchangesGrid[tokenType][e] = defaultExchangesGrid[e];
      }
    }

    for (const c of defaultExchangesGrid.columnOrder) {
      if (exchangesGrid[tokenType].columnOrder.indexOf(c) === -1) {
        exchangesGrid[tokenType].columnOrder.push(c);
      }
    }
    for (const c of defaultExchangesGrid.columnWidths) {
      if (
          exchangesGrid[tokenType].columnWidths.filter((x) => x.columnName === c.columnName)
              .length === 0
      ) {
        exchangesGrid[tokenType].columnWidths.push(c);
      }
    }


  }
}
const defaultExchangesGrids = [];
for (const tokenType in listTokenType) {
    defaultExchangesGrids[tokenType] = defaultExchangesGrid;
}

const defaultCardsGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'CardId', direction: 'asc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  totalSummaryItems: [
    { columnName: 'ValueUSD', type: 'sum' },
    { columnName: 'ValueUSD', type: 'avg' },
    { columnName: 'Count', type: 'sum' },
    { columnName: 'Count', type: 'avg' },

    { columnName: 'Avg', type: 'sum' },
    { columnName: 'Avg', type: 'avg' },
    { columnName: 'Min', type: 'sum' },
    { columnName: 'Min', type: 'avg' },
    { columnName: 'Max', type: 'sum' },
    { columnName: 'Max', type: 'avg' },

    { columnName: 'CountOther', type: 'sum' },
    { columnName: 'CountOther', type: 'avg' },

    // { columnName: 'Supply', type: 'sum' },
    // { columnName: 'Supply', type: 'avg' },
    { columnName: 'SupplyDetailed', type: 'sum' },
    { columnName: 'SupplyDetailed', type: 'avg' },

    { columnName: 'AvgOther', type: 'sum' },
    { columnName: 'AvgOther', type: 'avg' },
    { columnName: 'MinOther', type: 'sum' },
    { columnName: 'MinOther', type: 'avg' },
    { columnName: 'MaxOther', type: 'sum' },
    { columnName: 'MaxOther', type: 'avg' },
    { columnName: 'Ratio', type: 'sum' },
    { columnName: 'Ratio', type: 'avg' },
    { columnName: 'TriselUsed', type: 'sum' },
    { columnName: 'TriselUsed', type: 'avg' },
    { columnName: 'TriselPrice', type: 'sum' },
    { columnName: 'TriselPrice', type: 'avg' },
    { columnName: 'CostByTriselUsed', type: 'sum' },
    { columnName: 'CostByTriselUsed', type: 'avg' },
    { columnName: 'Power', type: 'sum' },
    { columnName: 'Power', type: 'avg' },
  ],
  columnOrder: getColumnsOrderCard().concat([
    'Stage',
    'CTAPrice',
    'ValueUSD',
    // 'History',
    'Amount',
    'Currency',
    'State',
    'CostByTriselUsed',
    'Count',
    'Avg',
    'Min',
    'Max',
    'CountOther',
    'AvgOther',
    'MinOther',
    'MaxOther',
    'Link',
    'Set',
    'NbTriselToAwakening',
    'NbTriselToIncreasePotentialToMax',
    'NbTriselTotal',
  ]),
  columnWidths: getColumnsWidthsCard().concat([
    { columnName: 'State', width: 100 },
    { columnName: 'CostByTriselUsed', width: 90 },
    // { columnName: 'FoilCount', width: 90 },
    { columnName: 'Amount', width: 100 },
    { columnName: 'Currency', width: 70 },
    { columnName: 'ValueUSD', width: 100 },
    { columnName: 'Count', width: 85 },
    { columnName: 'Avg', width: 100 },
    { columnName: 'Min', width: 100 },
    { columnName: 'Max', width: 100 },
    { columnName: 'CountOther', width: 100 },
    { columnName: 'AvgOther', width: 100 },
    { columnName: 'MinOther', width: 100 },
    { columnName: 'MaxOther', width: 100 },
    { columnName: 'Link', width: 100 },
    { columnName: 'Stage', width: 100 },
    { columnName: 'CTAPrice', width: 100 },
    { columnName: 'NbTriselToAwakening', width: 110 },
    { columnName: 'NbTriselToIncreasePotentialToMax', width: 110 },
    { columnName: 'NbTriselTotal', width: 110 },
  ]),
};
if (cardsGrid) {
  for (const e in defaultCardsGrid) {
    if (!Object.hasOwn(cardsGrid, e)) {
      cardsGrid[e] = defaultCardsGrid[e];
    }
    for (const c of defaultCardsGrid.columnOrder) {
      if (cardsGrid.columnOrder.indexOf(c) === -1) {
        cardsGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultCardsGrid.columnWidths) {
      if (
        cardsGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        cardsGrid.columnWidths.push(c);
      }
    }
  }
}

const defaultScholarsGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'LastDate', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  totalSummaryItems: [
    { columnName: 'TriselDay', type: 'sum' },
    { columnName: 'TriselDay', type: 'avg' },
    { columnName: 'TriselWeek', type: 'sum' },
    { columnName: 'TriselWeek', type: 'avg' },
    { columnName: 'CurrentTrisel', type: 'sum' },
    { columnName: 'CurrentTrisel', type: 'avg' },
    { columnName: 'CurrentCTA', type: 'sum' },
    { columnName: 'CurrentCTA', type: 'avg' },
    { columnName: 'CurrentPrana', type: 'sum' },
    { columnName: 'CurrentPrana', type: 'avg' },
  ],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'WalletId',
    'Name',
    'Email',
    'Address',
    'Magic',
    'UserName',
    'Login',
    'Description',

    'Scholar',
    'Owner',

    'QuestDay',
    'QuestDayToClaim',
    'RewardClaimedDaily',

    'QuestWeek',
    'QuestWeekToClaim',
    'RewardClaimedWeekly',

    'TriselDay',
    'TriselWeek',
    'CurrentTrisel',
    'CurrentCTA',
    'CurrentPrana',
    'MintPasses',
    'NbAssetsAddress',
    'NbAssetsMagic',
    'FirstDate',
    'LastDate',
    'Week',
    'CurrentEraMmr',

    'QuestEvent',
    'QuestEventToClaim',
    'RewardClaimedEvent',

    'QuestGeneral',
    'QuestGeneralToClaim',
    'RewardClaimedGeneral',

    'Manager',
    'Admin',
  ],
  columnWidths: [
    { columnName: 'WalletId', width: 100 },
    { columnName: 'Name', width: 275 },
    { columnName: 'Email', width: 200 },
    { columnName: 'Address', width: 200 },
    { columnName: 'Magic', width: 200 },
    { columnName: 'UserName', width: 200 },
    { columnName: 'Login', width: 200 },
    { columnName: 'Description', width: 100 },

    { columnName: 'Scholar', width: 100 },
    { columnName: 'Owner', width: 100 },
    { columnName: 'Manager', width: 100 },
    { columnName: 'Admin', width: 100 },

    { columnName: 'QuestDay', width: 100 },
    { columnName: 'QuestDayToClaim', width: 100 },
    { columnName: 'RewardClaimedDaily', width: 100 },

    { columnName: 'QuestWeek', width: 100 },
    { columnName: 'QuestWeekToClaim', width: 100 },
    { columnName: 'RewardClaimedWeekly', width: 100 },

    { columnName: 'QuestEvent', width: 100 },
    { columnName: 'QuestEventToClaim', width: 100 },
    { columnName: 'RewardClaimedEvent', width: 100 },

    { columnName: 'QuestGeneral', width: 100 },
    { columnName: 'QuestGeneralToClaim', width: 100 },
    { columnName: 'RewardClaimedGeneral', width: 100 },

    { columnName: 'TriselDay', width: 120 },
    { columnName: 'TriselWeek', width: 120 },
    { columnName: 'CurrentTrisel', width: 120 },
    { columnName: 'CurrentCTA', width: 120 },
    { columnName: 'CurrentPrana', width: 120 },
    { columnName: 'MintPasses', width: 120 },
    { columnName: 'NbAssetsAddress', width: 100 },
    { columnName: 'NbAssetsMagic', width: 100 },
    { columnName: 'LastDate', width: 150 },
    { columnName: 'FirstDate', width: 150 },
    { columnName: 'Week', width: 100 },
    { columnName: 'CurrentEraMmr', width: 100 },
  ],
};
if (scholarsGrid) {
  for (const e in defaultScholarsGrid) {
    if (!Object.hasOwn(scholarsGrid, e)) {
      scholarsGrid[e] = defaultScholarsGrid[e];
    }
    for (const c of defaultScholarsGrid.columnOrder) {
      if (scholarsGrid.columnOrder.indexOf(c) === -1) {
        scholarsGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultScholarsGrid.columnWidths) {
      if (
        scholarsGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        scholarsGrid.columnWidths.push(c);
      }
    }
  }
}

const defaultWorkersGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'Name', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  totalSummaryItems: [],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: ['WorkerId', 'Name', 'Description', 'NbWallets'],
  columnWidths: [
    { columnName: 'WorkerId', width: 100 },
    { columnName: 'Name', width: 275 },
    { columnName: 'Description', width: 275 },
    { columnName: 'NbWallets', width: 275 },
  ],
};
if (workersGrid) {
  for (const e in defaultWorkersGrid) {
    if (!Object.hasOwn(workersGrid, e)) {
      workersGrid[e] = defaultWorkersGrid[e];
    }
    for (const c of defaultWorkersGrid.columnOrder) {
      if (workersGrid.columnOrder.indexOf(c) === -1) {
        workersGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultWorkersGrid.columnWidths) {
      if (
        workersGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        workersGrid.columnWidths.push(c);
      }
    }
  }
}

const getQuestColumnWidths = () => {
  return getQuestColumnWidth('DAILY')
    .concat(getQuestColumnWidth('WEEKLY'))
    .concat(getQuestColumnWidth('EVENT'))
    .concat(getQuestColumnWidth('GENERAL'));
};
const getQuestColumnWidth = (type) => [
  { columnName: type + 'NbQuest', width: 80 },
  { columnName: type + 'NbQuestClaimed', width: 80 },
  { columnName: type + 'NbQuestCompleted', width: 80 },
  { columnName: type + 'RewardClaimed', width: 80 },
];
const getQuestColumnOrder = (type) => [
  type + 'NbQuest',
  type + 'NbQuestClaimed',
  type + 'NbQuestCompleted',
  type + 'RewardClaimed',
];
export const getQuestColumnOrders = () => {
  return getQuestColumnOrder('DAILY')
    .concat(getQuestColumnOrder('WEEKLY'))
    .concat(getQuestColumnOrder('EVENT'))
    .concat(getQuestColumnOrder('GENERAL'));
};

const defaultScholarGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'Date', direction: 'desc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  totalSummaryItems: [],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'Date',
    'Prana',
    'Cta',
    'Trisel',
    'Level',
    'NextLevelExpThreshold',
    'NextLevelExpRequired',
    'CurrentLevelPercent',
    'LevelExpTotal',
    'CurrentEraDuelCount',
    'CurrentEraWinCount',
    'CurrentEraRatio',
    'CurrentEraMmr',
    'CardCount',
    'CardPowerSum',
    'FreeMintCount',
    'FreeAnimationLevelCount',
    'Description',
  ].concat(getQuestColumnOrders()),
  columnWidths: [
    { columnName: 'Date', width: 150 },
    { columnName: 'Prana', width: 80 },
    { columnName: 'Cta', width: 80 },
    { columnName: 'Trisel', width: 80 },
    { columnName: 'Level', width: 80 },
    { columnName: 'NextLevelExpThreshold', width: 80 },
    { columnName: 'NextLevelExpRequired', width: 80 },
    { columnName: 'CurrentLevelPercent', width: 80 },
    { columnName: 'LevelExpTotal', width: 80 },
    { columnName: 'CurrentEraDuelCount', width: 80 },
    { columnName: 'CurrentEraWinCount', width: 80 },
    { columnName: 'CurrentEraRatio', width: 80 },
    { columnName: 'CurrentEraMmr', width: 80 },
    { columnName: 'CardCount', width: 80 },
    { columnName: 'CardPowerSum', width: 80 },
    { columnName: 'FreeMintCount', width: 80 },
    { columnName: 'FreeAnimationLevelCount', width: 80 },
    { columnName: 'Description', width: 120 },
  ].concat(getQuestColumnWidths()),
};
if (scholarGrid) {
  for (const e in defaultScholarGrid) {
    if (!Object.hasOwn(scholarGrid, e)) {
      scholarGrid[e] = defaultScholarGrid[e];
    }
    for (const c of defaultScholarGrid.columnOrder) {
      if (scholarGrid.columnOrder.indexOf(c) === -1) {
        scholarGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultScholarGrid.columnWidths) {
      if (
        scholarGrid.columnWidths.filter((x) => x.columnName === c.columnName)
          .length === 0
      ) {
        scholarGrid.columnWidths.push(c);
      }
    }
  }
}

const defaultSuppliesGrid = {
  id: 1,
  rows: [],
  sorting: [{ columnName: 'CardId', direction: 'asc' }],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [1],
  filters: [],
  tableColumnExtensions: [],
  totalSummaryItems: [
    { columnName: 'Total', type: 'sum' },
    { columnName: 'Total', type: 'avg' },
    { columnName: 'TotalFoil', type: 'sum' },
    { columnName: 'TotalFoil', type: 'avg' },
    { columnName: 'TotalNoFoil', type: 'sum' },
    { columnName: 'TotalNoFoil', type: 'avg' },
    { columnName: 'PourcSupplyFoil', type: 'sum' },
    { columnName: 'PourcSupplyFoil', type: 'avg' },
    { columnName: 'PourcSupplyNoFoil', type: 'sum' },
    { columnName: 'PourcSupplyNoFoil', type: 'avg' },
  ],
  currentPage: 0,
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 0],
  columns: [],
  columnOrder: [
    'CardId',
    'Name',
    'Rarity',
    'Total',
    'TotalFoil',
    'TotalNoFoil',
    'PourcSupplyFoil',
    'PourcSupplyNoFoil',
  ],
  columnWidths: [
    { columnName: 'CardId', width: 75 },
    { columnName: 'Name', width: 150 },
    { columnName: 'Rarity', width: 100 },
    { columnName: 'Total', width: 100 },
    { columnName: 'TotalFoil', width: 90 },
    { columnName: 'TotalNoFoil', width: 120 },
    { columnName: 'PourcSupplyFoil', width: 75 },
    { columnName: 'PourcSupplyNoFoil', width: 75 },
  ],
};
if (suppliesGrid) {
  for (const e in defaultSuppliesGrid) {
    if (!Object.hasOwn(suppliesGrid, e)) {
      suppliesGrid[e] = defaultSuppliesGrid[e];
    }
    for (const c of defaultSuppliesGrid.columnOrder) {
      if (suppliesGrid.columnOrder.indexOf(c) === -1) {
        suppliesGrid.columnOrder.push(c);
      }
    }
    for (const c of defaultSuppliesGrid.columnWidths) {
      if (
          suppliesGrid.columnWidths.filter((x) => x.columnName === c.columnName)
              .length === 0
      ) {
        suppliesGrid.columnWidths.push(c);
      }
    }
  }
}

const configInitialState = {
  tabStrategyValue: 0,
  theme: localTheme || 'dark',
  noPopupTrading: noPopupTrading || false,
  collapseMenu: collapseMenu || false,
  exchangesGrid: exchangesGrid || defaultExchangesGrids,
  transfersGrid: exchangesGrid || defaultExchangesGrids,
  scholarsGrid: scholarsGrid || defaultScholarsGrid,
  scholarGrid: scholarGrid || defaultScholarGrid,
  suppliesGrid: suppliesGrid || defaultSuppliesGrid,
  cardsGrid: cardsGrid || defaultCardsGrid,
  workersGrid: workersGrid || defaultWorkersGrid,
  tabOrders: 0,
  notificationSettings: null,
};

export function config(state = configInitialState, action) {
  switch (action.type) {
    case configConstants.TAB_ORDERS:
      return {
        ...state,
        tabOrders: action.payload,
      };
    case configConstants.TAB_STRATEGY_VALUE:
      return {
        ...state,
        tabStrategyValue: action.payload,
      };
    case configConstants.ASSETS_GRID_RESET:
    case configConstants.CARDS_GRID_RESET:
      return {
        ...state,
        cardsGrid: defaultCardsGrid,
      };
    case configConstants.EXCHANGES_GRID_RESET:
      return {
        ...state,
        exchangesGrid: defaultExchangesGrids,
      };
    case configConstants.ASSETS_GRID_STATE_CHANGE_ACTION:
    case configConstants.CARDS_GRID_STATE_CHANGE_ACTION: {
      const cardsGrid = {
        ...state.cardsGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('cardsGrid', JSON.stringify(cardsGrid));
      return {
        ...state,
        cardsGrid,
      };
    }
    case configConstants.TRANSFER_GRID_STATE_CHANGE_ACTION: {
      const tokenType = action.tokenType;
      const transfersGrid = {
        ...state.transfersGrid,
        [tokenType]: {
          ...state.transfersGrid[tokenType],
          [action.partialStateName]: action.partialStateValue
        },
      };
      localStorage.setItem('transfersGrid', JSON.stringify(transfersGrid));
      return {
        ...state,
        transfersGrid,
      };
    }
    case configConstants.EXCHANGES_GRID_STATE_CHANGE_ACTION: {
      const tokenType = action.tokenType;
      const exchangesGrid = {
        ...state.exchangesGrid,
        [tokenType]: {
            ...state.exchangesGrid[tokenType],
          [action.partialStateName]: action.partialStateValue
        },
      };
      localStorage.setItem('exchangesGrid', JSON.stringify(exchangesGrid));
      return {
        ...state,
        exchangesGrid,
      };
    }
    case configConstants.ORDERS_GRID_STATE_CHANGE_ACTION: {
      const ordersGrid = {
        ...state.ordersGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('ordersGrid', JSON.stringify(ordersGrid));
      return {
        ...state,
        ordersGrid,
      };
    }
    case configConstants.SCHOLARS_GRID_STATE_CHANGE_ACTION: {
      const scholarsGrid = {
        ...state.scholarsGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('scholarsGrid', JSON.stringify(scholarsGrid));
      return {
        ...state,
        scholarsGrid,
      };
    }
    case configConstants.SCHOLARS_GRID_RESET:
      return {
        ...state,
        scholarsGrid: defaultScholarsGrid,
      };

    case configConstants.SUPPLIES_GRID_STATE_CHANGE_ACTION: {
      const suppliesGrid = {
        ...state.suppliesGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('suppliesGrid', JSON.stringify(suppliesGrid));
      return {
        ...state,
        suppliesGrid,
      };
    }
    case configConstants.SUPPLIES_GRID_RESET:
      return {
        ...state,
        suppliesGrid: defaultSuppliesGrid,
      };

    case configConstants.WORKERS_GRID_STATE_CHANGE_ACTION: {
      const workersGrid = {
        ...state.workersGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('workersGrid', JSON.stringify(workersGrid));
      return {
        ...state,
        workersGrid,
      };
    }
    case configConstants.WORKERS_GRID_RESET:
      return {
        ...state,
        workersGrid: defaultWorkersGrid,
      };

    case configConstants.SCHOLAR_GRID_RESET:
      return {
        ...state,
        scholarGrid: defaultScholarGrid,
      };
    case configConstants.SCHOLAR_GRID_STATE_CHANGE_ACTION: {
      const scholarGrid = {
        ...state.scholarGrid,
        [action.partialStateName]: action.partialStateValue,
      };
      localStorage.setItem('scholarGrid', JSON.stringify(scholarGrid));
      return {
        ...state,
        scholarGrid,
      };
    }

    case configConstants.ACTION_CHANGE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case configConstants.ACTION_CHANGE_NO_POPUP_TRADING:
      return {
        ...state,
        noPopupTrading: action.payload,
      };
    case configConstants.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: action.payload,
      };
    case configConstants.GET_NOTIFICATION:
      return {
        ...state,
        notificationSettings: action.payload,
      };
    case configConstants.NOTIFICATION_ERROR:
      return {
        ...state,
        notificationError: action.payload,
      };
    default:
      return state;
  }
}
