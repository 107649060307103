import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import {Translate, withLocalize} from "react-localize-redux";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import CharaLauriePng from "../../assets/img/cta/background/Chara-laurie-24b.png";
import {darkColorTransparent, lightColorTransparent} from "../../assets/jss/material-dashboard-react";
import {connect} from "react-redux";

const AlertsPage = props => {
    const theme = props.theme;
    const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;
    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card style={{ backgroundPosition: 'right', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(${color}, ${color}), url(${CharaLauriePng})`}}>
                        <CardBody>
                            <h4><Translate id="central.alertsPage" options={{ renderInnerHtml: true }} /></h4>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}

function mapStateToProps(state) {
    const { config } = state;
    return {
        theme: config.theme,
    };
}

export default connect(mapStateToProps, null)(withLocalize(AlertsPage));
