import LittleButton from '../../components/CustomButtons/LittleButton';

import Create from '@material-ui/icons/Create';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Eject from '@material-ui/icons/Eject';

import React from 'react';
import { bindActionCreators } from 'redux';
import {
  acceptOrder,
  refuseOrder,
  deleteOrder,
  checkOrder,
} from '../../redux/order/actions';
import connect from 'react-redux/es/connect/connect';
import { Translate, getTranslate } from 'react-localize-redux';
import { alertPopup } from '../../redux/alert/alert.actions';
import Autorenew from '@material-ui/icons/Autorenew';
import DialogPanicSell from './DialogPanicSell';

class OrderButtons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  removeOrder = (id) => {
    const noPopupTrading = this.props.noPopupTrading;
    if (noPopupTrading) this.props.deleteOrderActions(id, noPopupTrading);
    else {
      this.props.alertActions({
        type: 'confirm',
        then: (result) => {
          if (result.value) {
            this.props.deleteOrderActions(id);
          }
        },
      });
    }
  };

  acceptOrder = (id) => {
    const noPopupTrading = this.props.noPopupTrading;
    if (noPopupTrading) this.props.acceptOrderActions(id, noPopupTrading);
    else {
      this.props.alertActions({
        type: 'confirm',
        then: (result) => {
          if (result.value) {
            this.props.acceptOrderActions(id);
          }
        },
      });
    }
  };

  refuseOrder = (id) => {
    const noPopupTrading = this.props.noPopupTrading;
    if (noPopupTrading) this.props.refuseOrderActions(id, noPopupTrading);
    else {
      this.props.alertActions({
        type: 'confirm',
        then: (result) => {
          if (result.value) {
            this.props.refuseOrderActions(id);
          }
        },
      });
    }
  };

  refreshOrder = (id) => {
    const noPopupTrading = this.props.noPopupTrading;
    if (noPopupTrading) this.props.checkOrderActions(id, noPopupTrading);
    else {
      this.props.alertActions({
        type: 'confirm',
        then: (result) => {
          if (result.value) {
            this.props.checkOrderActions(id);
          }
        },
      });
    }
  };

  onCloseDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { order, size } = this.props;
    const { open } = this.state;
    // const state = getStateStr(order.State);
    const isWait = [0].includes(order.State);
    const canUpdate = [0, 1, 4, 9].includes(order.State);
    const canCheck = [1].includes(order.State);
    const canPanicSell = [4].includes(order.State);
    const canDelete = [1, 4, 9].includes(order.State);
    const isBuy = order.IsBuy;
    return (
      <div>
        {isWait && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={<Translate id="orders.accept" />}
            color="success"
            icon={<Done />}
            timeout={3000}
            onClick={() => this.acceptOrder(order.OrderId)}
          />
        )}
        {isWait && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={<Translate id="orders.refuse" />}
            color="danger"
            icon={<Close />}
            timeout={3000}
            onClick={() => this.refuseOrder(order.OrderId)}
          />
        )}
        {canDelete && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={
              order.State === 9 ? (
                <Translate id="orders.cancelTemplate" />
              ) : order.State === 1 ? (
                <Translate id="orders.cancelOrderEntry" />
              ) : (
                <Translate id="orders.cancelOrder" />
              )
            }
            color="danger"
            timeout={3000}
            icon={<DeleteForever />}
            onClick={() => this.removeOrder(order.OrderId)}
          />
        )}
        {canUpdate && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={<Translate id="orders.updateOrder" />}
            color="warning"
            icon={<Create />}
            to={'/smartTrade/' + order.OrderId}
          />
        )}
        {canCheck && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={'Refresh the order'}
            icon={<Autorenew />}
            timeout={3000}
            onClick={() => this.refreshOrder(order.OrderId)}
          />
        )}
        {open && <DialogPanicSell onClose={this.onCloseDialog} order={order} />}
        {canPanicSell && (
          <LittleButton
            size={size ? size : 'sm'}
            tooltip={
              <Translate
                id={isBuy ? 'orders.panicSellOrder' : 'orders.panicBuyOrder'}
              />
            }
            color="rose"
            icon={<Eject />}
            timeout={3000}
            onClick={() => {
              this.setState({ open: true });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize, config } = state;
  return {
    translate: getTranslate(localize),
    noPopupTrading: config.noPopupTrading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    acceptOrderActions: bindActionCreators(acceptOrder, dispatch),
    refuseOrderActions: bindActionCreators(refuseOrder, dispatch),
    checkOrderActions: bindActionCreators(checkOrder, dispatch),
    deleteOrderActions: bindActionCreators(deleteOrder, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderButtons);
