import { isEmpty, isNull, parseToFloat } from '../views/SmartTrade/Common';
import {
  capitalize,
  listArkhome,
  listCollection,
  listRarity,
  listRarityImx,
  listTokenType,
  listTokenTypeImage
} from './general';
import {Asset} from "./asset";

const getNbCardToIncreasePotentialToMax = (rarity, isAlternative) => {
  switch (rarity) {
    case 'COMMON':
      return 30;
    case 'UNCOMMON':
      return 10;
    case 'RARE':
      return 5;
    case 'SPECIAL_RARE':
      // case 'EXCLUSIVE':
      // case 'UNIQUE':
      return 4;
    case 'ULTRA_RARE':
      return 3;
    case 'MYTHIC':
      return 2;
  }
  return 0;
};
const getNbTriselToIncreasePotentialByCard = (rarity, isAlternative) => {
  switch (rarity?.toUpperCase()) {
    case 'COMMON':
      return 50;
    case 'UNCOMMON':
      return 150;
    case 'RARE':
      return 300;
    case 'SPECIALRARE':
      // case 'EXCLUSIVE':
      // case 'UNIQUE':
      return 400;
    case 'ULTRARARE':
      return 600;
    case 'MYTHIC':
      return 1000;
  }
  return 0;
};
const getNbTriselToAwakening = (rarity) => {
  switch (rarity?.toUpperCase()) {
    case 'COMMON':
      return 500;
    case 'UNCOMMON':
      return 1000;
    case 'RARE':
      return 2000;
    case 'SPECIALRARE':
      // case 'EXCLUSIVE':
      // case 'UNIQUE':
      return 3000;
    case 'ULTRARARE':
      return 3500;
    case 'MYTHIC':
      return 5000;
  }
  return 0;
};
const getTriselUsed = (rarity, isAlternative, history) => {
  let triselUsed =
    history.length *
    getNbTriselToIncreasePotentialByCard(rarity, isAlternative);
  if (isAlternative)
    triselUsed += getNbTriselToAwakening(rarity, isAlternative);

  return triselUsed;
};

export const fromImxToCard = (card) => ({
  "Status": card.status,
  "State": card.metadata?.state,
  "CardId": card.metadata?.id,
  "Foil": card.metadata?.foil,
  "CardType": card.metadata?.cardType,
  "Power": card.metadata?.power,
  "Rarity": card.metadata?.rarity,
  "Arkhome": capitalize((card.metadata?.element ?? card.metadata?.trait ?? '').toLowerCase()),
  "Grade": card.metadata?.grade,
  "AnimationLevel": card.metadata?.animationLevel,
  "Rank": card.metadata?.rank,
  "Numbering": card.metadata?.numbering,
  "Stage": card.metadata?.stage,
  "Set": listCollection.indexOf(capitalize(card.metadata?.set?.toLowerCase())),
  "Advancement": card.metadata?.advancement,
  "Potential": card.metadata?.potential,
  "History": card.metadata?.history,
  "TokenId": card.token_id,
  "AddressUser": card.user,
  "CreatedAt": card.created_at,
  "UpdatedAt": card.updated_at,
  "Name": card.name,
  "IdIMX": card.metadata?.uuid,
  "Image": card.image_url?.split('/').pop(),
  "TokenType": listTokenType.indexOf(card.metadata?.tokenType),
  "Count": 1
});

export const getAsset = (asset) => {
  let tokenType = listTokenTypeImage[asset.TT];
  const FP = asset.FP;
  const LS = asset.LS;
  const LSDays = asset.Da;
  const OnSell = asset.OS;
  if (tokenType === 'chest' && asset.N.indexOf('Shard') !== -1)
    tokenType = 'shard';
  return {
    Key: asset.K,
    TokenId: asset.T,
    Name: asset.N,
    UpdatedAt: asset.U,
    Image: `https://imx.cta-tcg-live.com/image/${tokenType}/${asset.I}`,

    Count: asset.C,
    SupplyDetailed: asset.SD,
    MaxSupply: asset.MS,

    FP: FP,
    LS: LS,
    LSDays: LSDays,
    OnSell: OnSell,
  };
};
export const getCard = (asset) => {
  const ca = new Asset(asset.K);

  const rarity = listRarity[ca.getRarity()];
  const grade = ca.getGrade();
  const rank = ca.getRank();
  const isAlternative = grade !== '';
  const history = isNull(asset.History) ? [] : JSON.parse(asset.History);
  const triselUsed = getTriselUsed(rarity, isAlternative, history);
  // let cardId = asset.CardId;
  // if (asset.CardId > 250) {
  //   const cardIdStr = asset.CardId.toString().substring(6, 10);
  //   cardId = Number(cardIdStr);
  // }
  return Object.assign(getAsset(asset), {
    CardId: ca.getCardId(),
    Grade: grade === '' ? ' ' : grade,
    Rank: rank,
    AnimationLevel: ca.getAnimationLevel(),
    Rarity: rarity,
    Arkhome: listArkhome[ca.getElement()],
    Foil: ca.getFoil(),//asset.Foil,

    Type: asset.CardType,
    Power: asset.Power,
    Numbering: asset.Numbering,
    Set: ca.getSet(),//asset.Set,

    Advancement: asset.Advancement,

    History: history,

    Supply: asset.S,
    RatioSupply: (asset.S / asset.MS) * 100,

    TriselUsed: triselUsed,

    // PriceByTrised: triselUsed === undefined ? undefined : valueUSD / triselUsed,
    // TriselNeed: order.trisel_used,
  });
};
export const getMintPass = (asset) => {
  return Object.assign(getAsset(asset), {
    Numbering: asset.Numbering,
    Stage: asset.Stage,
  });
};
export const getSouvenirCard = (asset) => {
  return Object.assign(getAsset(asset), {
    Numbering: asset.Numbering,
    CardId: asset.CardId,
    Foil: asset.Foil,
    Set: asset.Set,
    Grade: asset.Grade,
  });
};
export const getObjectFromAsset = (asset) => {
  const ca = new Asset(asset.K);
  const tokenType = asset.TT ?? ca.getTokenType();
  if (tokenType === 0) return getCard(asset);
  if (tokenType === 1) return getMintPass(asset);
  if (tokenType === 2) return getAsset(asset);
  if (tokenType === 3) return getSouvenirCard(asset);
  return getAsset(asset);
};
export const getColumnsWidthsAsset = () => {
  return [
    { columnName: 'Image', width: 100 },
    { columnName: 'Name', width: 150 },
    { columnName: 'UpdatedAt', width: 150 },
  ];
};
export const getColumnsWidthsCard = () => {
  return getColumnsWidthsAsset().concat([
    { columnName: 'CardId', width: 75 },
    { columnName: 'Foil', width: 70 },
    { columnName: 'Type', width: 100 },
    { columnName: 'Power', width: 90 },
    { columnName: 'Rarity', width: 100 },
    { columnName: 'Arkhome', width: 100 },
    { columnName: 'Grade', width: 80 },
    { columnName: 'Numbering', width: 100 },
    { columnName: 'Advancement', width: 150 },
    { columnName: 'AnimationLevel', width: 50 },
    { columnName: 'Rank', width: 80 },
    { columnName: 'Set', width: 110 },
    // { columnName: 'History', width: 150 },

    { columnName: 'SupplyDetailed', width: 100 },
    { columnName: 'Supply', width: 100 },
    { columnName: 'MaxSupply', width: 100 },
    { columnName: 'RatioSupply', width: 100 },

    { columnName: 'Ratio', width: 90 },
    { columnName: 'TriselUsed', width: 90 },
    { columnName: 'TriselPrice', width: 90 },
  ]);
};
export const getColumnsOrderCard = () => {
  return [
    'Image',
    'CardId',
    'Name',
    'Foil',
    'Rarity',
    'Arkhome',
    'Power',
    'Type',
    'Rank',
    'Grade',
    'Numbering',
    'AnimationLevel',
    'Advancement',
    'Set',
    'History',
    'SupplyDetailed',
    'Supply',
    'MaxSupply',
    'RatioSupply',
    'Ratio',
    'TriselUsed',
    'TriselPrice',
    'UpdatedAt',
  ];
};
export const getColumnsAssets = (getRow, codeLang, withInfoPrice = true) => {
  return [
    { name: 'Name', title: 'Name', getCellValue: (row) => getRow(row).Name },
    {
      name: 'Image',
      title: 'Image',
      getCellValue: (row) => getRow(row).TokenId,
    },
    {
      name: 'UpdatedAt',
      title: 'UpdatedAt',
      codeLang: codeLang,
      getCellValue: (row) => getRow(row).UpdatedAt,
    },
  ].concat(withInfoPrice ? [{
    name: 'SupplyDetailed',
    title: 'SupplyDetailed',
    getCellValue: (row) => getRow(row).SupplyDetailed,
  }] : []);
};
export const getColumnsMintPass = (getRow, codeLang, withInfoPrice = true) => {
  return getColumnsAssets(getRow, codeLang, withInfoPrice).concat([
    {
      name: 'Numbering',
      title: 'Numbering',
      getCellValue: (row) => getRow(row).Numbering,
    },
    {
      name: 'Stage',
      title: 'Stage',
      getCellValue: (row) => getRow(row).Stage,
    },
  ]);
};

export const getColumnsSouvenirCard = (getRow, codeLang, withInfoPrice = true) => {
  return getColumnsAssets(getRow, codeLang, withInfoPrice).concat([
    {
      name: 'Numbering',
      title: 'Numbering',
      getCellValue: (row) => getRow(row).Numbering,
    },
    {
      name: 'CardId',
      title: 'CardId',
      getCellValue: (row) => getRow(row).CardId,
    },
    {
      name: 'Foil',
      title: 'Foil',
      getCellValue: (row) => (getRow(row).Foil ? 'Yes' : 'No'),
    },
    // { name: 'Set', title: 'Collection',
    //   // getCellValue: (row) => listCollection[getRow(row).Set],
    // },
    { name: 'Grade', title: 'Grade', getCellValue: (row) => getRow(row).Grade },
  ]);
};

export const pourcentageGrade = (grade, isCombo) => {
  if (isCombo) {
    switch (grade) {
      case 'C':
        return 0.42;
      case 'B':
        return 0.333;
      case 'A':
        return 0.198;
      case 'S':
        return 0.048;
    }
  }
  switch (grade) {
      case 'C':
        return 0.6;
      case 'B':
        return 0.275;
      case 'A':
        return 0.105;
      case 'S':
        return 0.02;
  }
  return 0;
};

export const getTriselPrice = (getRow, row, triselPrice) => {
  return isEmpty(getRow(row).Grade) || getRow(row).Grade === ' '  ? '' :
      getRow(row).TriselUsed * triselPrice / 1000 / pourcentageGrade(getRow(row).Grade, getRow(row).Advancement === 'COMBO') * 0.25
};

export const getColumnsCard = (getRow, codeLang, withInfoPrice = true, triselPrice = 3) => {
  return getColumnsAssets(getRow, codeLang, withInfoPrice).concat([
    {
      name: 'CardId',
      title: 'CardId',
      getCellValue: (row) => getRow(row).CardId,
    },
    {
      name: 'Foil',
      title: 'Foil',
      getCellValue: (row) => (getRow(row).Foil ? 'Yes' : 'No'),
    },
    // { name: 'Type', title: 'Type',
    //   // getCellValue: (row) => listTypeCard[getRow(row).Type],
    // },
    { name: 'Power', title: 'Power', getCellValue: (row) => getRow(row).Power },
    {
      name: 'Rarity',
      title: 'Rarity',
      getCellValue: (row) => getRow(row).Rarity,
    },
    {
      name: 'Arkhome',
      title: 'Arkhome',
      getCellValue: (row) => getRow(row).Arkhome,
    },
    { name: 'Grade', title: 'Grade', getCellValue: (row) => getRow(row).Grade },
    {
      name: 'Numbering',
      title: 'Numbering',
      getCellValue: (row) => getRow(row).Numbering,
    },
    {
      name: 'AnimationLevel',
      title: 'AnimationLevel',
      getCellValue: (row) => getRow(row).AnimationLevel,
    },
    { name: 'Rank', title: 'Rank', getCellValue: (row) => getRow(row).Rank },
    { name: 'Advancement', title: 'Advancement', getCellValue: (row) => getRow(row).Advancement },
    { name: 'Set', title: 'Collection',
      getCellValue: (row) => listCollection[getRow(row).Set],
    },
    // { name: 'History', title: 'History', getCellValue: (row) => getRow(row).History },
  ])
  .concat(withInfoPrice ? [{
      name: 'Supply',
      title: 'Supply',
      getCellValue: (row) => getRow(row).Supply,
    }, {
      name: 'MaxSupply',
      title: 'MaxSupply',
      getCellValue: (row) => getRow(row).MaxSupply,
    }, {
      name: 'RatioSupply',
      title: 'RatioSupply',
      getCellValue: (row) => getRow(row).RatioSupply,
    }, {
      name: 'Ratio',
      title: 'Ratio',
      getCellValue: (row) => {
        const FP = (row.ValueUSD ?? row.FP.Mi);
        return FP / getTriselPrice(getRow, row, triselPrice) * 100;
      },
    },{
      name: 'TriselUsed',
      title: 'TriselUsed',
      getCellValue: (row) => getRow(row).TriselUsed,
    }, {
      name: 'TriselPrice',
      title: 'TriselPrice',
      getCellValue: (row) => getTriselPrice(getRow, row, triselPrice),
    }] : []);
};

export const getOrder = (order) => {
  // console.log(order);
  const card = order.Asset;
  const currency = order.currency;
  const amount = parseFloat(order.amount);
  const createdAt = order.updated_timestamp;
  let valueUSD = order.ValueUSD;
  const cardObj = getCard(card);
  return Object.assign(cardObj, {
    CreatedAt: createdAt,
    Amount: amount,
    Currency: currency,
    Value: parseToFloat(valueUSD, 0.01),
    CostByTriselUsed: isEmpty(cardObj.TriselUsed)
      ? 0
      : parseToFloat((valueUSD / cardObj.TriselUsed) * 1000, 0.001),
  });
};

//
// export const GetKeyBack = (elem) =>
// {
//   return ((elem.TokenType << 42) +
//   (Number(elem.Foil) << 41) +
//   (elem.Element << 40) +
//   (elem.Rarity << 32) +
//   (elem.AnimationLevel << 24) +
//   ((elem.Grade === null || elem.Grade === undefined || elem.Grade === "") ? elem.Rank.charCodeAt(0) : elem.Grade.charCodeAt(0)) << 16 +
//   (elem.CardId !== null ? elem.CardId : 0))
// };
// export const getKey = (elem) => {
//   return (
//       elem.Name +
//       (isNull(elem.Grade) ? '' : elem.Grade) +
//       (isNull(elem.Foil) ? '' : elem.Foil) +
//       (isNull(elem.Rank) ? '' : elem.Rank) +
//       (isNull(elem.AnimationLevel) ? '' : elem.AnimationLevel) +
//       (isNull(elem.Arkhome) ? '' : elem.Arkhome) +
//       // elem.Type +
//       (isNull(elem.Rarity) ? '' : elem.Rarity)
//   );
// };

export const getKey = (elem) => {
  return elem.Key;
};

const smallestValue = (acc, loc) => (acc.Value < loc.Value ? acc : loc);

export const getOrderDetailFromJson = (orders) => {
  let ret = [];
  for (const order of orders) {
    const elem = getOrder(order);
    // console.log(elem);
    ret.push(elem);
  }
  return ret;
};
export const getTabFromJson = (orders) => {
  let tab = {};
  for (const order of orders) {
    const elem = getOrder(order);
    const key = getKey(elem);
    if (isNull(tab[key])) tab[key] = [];
    tab[key].push(elem);
  }
  return tab;
};

export const insertDetailInOrder = (order, cardsOthers) => {
  const key = getKey(order);
  const cardsOther = cardsOthers[key];
  if (!isNull(cardsOther)) {
    const valuesOther = cardsOther.map((card) => card.Value);
    const sumOther = valuesOther.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
    const countOther = cardsOther.length;
    order.AvgOther = parseToFloat(sumOther / countOther, 0.01);
    order.MinOther = Math.min(...valuesOther);
    order.MaxOther = Math.max(...valuesOther);
    order.CountOther = countOther;
  } else {
    order.AvgOther = 0;
    order.MinOther = 0;
    order.MaxOther = 0;
    order.CountOther = 0;
  }
};

export const getOrderFromTab = (tab) => {
  let ret = [];
  for (const key in tab) {
    const cards = tab[key];
    const order = cards.reduce(smallestValue);

    const values = cards.map((card) => card.Value);
    const sum = values.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
    order.Count = cards.length;
    order.Avg = parseToFloat(sum / order.Count, 0.01);
    order.Min = Math.min(...values);
    order.Max = Math.max(...values);
    ret.push(order);
  }

  return ret;
};

export const arrFilterTypes = [
  {name: 'TokenType'  , values: listTokenType },
  {name: 'Name'},
  {name: 'Id'},
  {name: 'TokenId'},
  {name: 'Grade'      , values: ['C', 'B', 'A', 'S']},
  {name: 'Rank'       , values: ['1', '2', '3', '4', '5']},
  {name: 'Rarity'     , values: listRarityImx },
  {name: 'Foil'       , values: ['true', 'false'] },
  {name: 'Set'        , values: listCollection },
  {name: 'Element'    , values: listArkhome },
  {name: 'Advancement', values: ['STANDARD', 'ALTERNATIVE', 'COMBO'] },
  //animationLevel
  //numbering
  //potential
  //cardType BATTLE
];
