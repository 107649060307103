import { push } from 'connected-react-router';
import { orderConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';
import { getStart } from '../../helpers/order';
import { isEmpty, isNull } from '../../views/SmartTrade/Common';
import { Translate } from '../../helpers/general';
import { getErrorMessage } from '../../views/Orders/ActionOrderLine';
import { adminConstants } from '../admin/constants';

export const getOrders =
  (tabOrders = 0, userId = null) =>
  async (dispatch) => {
    const isAdmin = userId !== null;
    if (!isAdmin) {
      const euLocal = JSON.parse(localStorage.getItem(`orders${tabOrders}`));
      if (euLocal) {
        dispatch({
          type: orderConstants.ACTION_LOCAL_ORDERS,
          payload: euLocal,
        });
      }
    }

    const userStr = userId === null ? '' : `&userId=${userId}`;
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/Orders?tabOrders=${tabOrders}${userStr}`,
      {},
    );
    if (eu) {
      dispatch({
        type: isAdmin
          ? adminConstants.ACTION_GET_USER_ORDERS
          : orderConstants.ACTION_GET_ORDERS,
        payload: eu,
      });
      if (!isAdmin)
        localStorage.setItem(`orders${tabOrders}`, JSON.stringify(eu));
    }
  };

export const getOrder = (orderId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Order/${orderId}`, {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_ORDER,
      payload: eu,
    });
    // localStorage.setItem('orders', JSON.stringify(eu));
  }
};

export const getMyGroups = () => async (dispatch) => {
  const euLocal = JSON.parse(localStorage.getItem('myGroups'));
  if (euLocal) {
    dispatch({
      type: orderConstants.ACTION_LOCAL_MY_GROUPS,
      payload: euLocal,
    });
  }
  const eu = await fetcher(dispatch, 'api/v1/Group/MyGroup', {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_MY_GROUPS,
      payload: eu,
    });
    localStorage.setItem('myGroups', JSON.stringify(eu));
  }
};

export const getAvailableAmount = () => async (dispatch) => {
  const euLocal = JSON.parse(localStorage.getItem('availableAmounts'));
  if (euLocal) {
    dispatch({
      type: orderConstants.ACTION_LOCAL_AVAILABLE_AMOUNT,
      payload: euLocal,
    });
  }
  const eu = await fetcher(dispatch, 'api/v1/Order/AvailableAmounts', {});
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_GET_AVAILABLE_AMOUNT,
      payload: eu,
    });
    localStorage.setItem('availableAmounts', JSON.stringify(eu));
  }
};

export const clearConvertSmartTrade = () => async (dispatch) => {
  dispatch({
    type: orderConstants.ACTION_CONVERT_ORDER,
    payload: null,
  });
};

export const convertSmartTrade = (order) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Order/Convert', {
    method: 'POST',
    body: order,
  });
  if (eu) {
    dispatch({
      type: orderConstants.ACTION_CONVERT_ORDER,
      payload: eu,
    });
  }
};

export const postSmartTrade =
  (order, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, 'api/v1/Order', {
      method: 'POST',
      body: order,
    });
    if (eu) {
      const text = Translate(getState, 'orders.orderCreated');
      // let html = null;
      // if (!isNull(order.startTradingViewGuidJson))
      //   html = `<div>${order.startTradingViewGuidJson}</div>`;
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_POST_ORDER,
        payload: eu,
      });
      dispatch(push('/orders'));
    }
  };

export const putOrder =
  (id, order, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, `api/v1/Order/${id}`, {
      method: 'PUT',
      body: order,
    });
    if (eu) {
      const text = Translate(getState, 'orders.orderUpdated');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      // dispatch({
      //   type: orderConstants.ACTION_DELETE_ORDER,
      //   payload: id
      // });
      dispatch(push('/orders'));
    }
  };

export const acceptOrder =
  (id, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, `api/v1/Order/Accept?orderId=${id}`, {
      method: 'POST',
    });
    if (eu) {
      const start = getStart(eu.Triggers);
      if (!isEmpty(start.ErrorMsg)) {
        dispatch(alertActions.alertError(getErrorMessage(start)));
      } else {
        const text = Translate(getState, 'orders.orderUpdated');
        if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      }
      dispatch({
        type: orderConstants.ACTION_GET_ORDER,
        payload: eu,
      });
    }
  };

export const refuseOrder =
  (id, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, `api/v1/Order/Refuse?orderId=${id}`, {
      method: 'POST',
    });
    if (eu) {
      const text = Translate(getState, 'orders.orderRefused');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
    }
  };

export const checkOrder =
  (id, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/CheckOrder?orderId=${id}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      dispatch({
        type: orderConstants.ACTION_GET_ORDER,
        payload: eu,
      });
      const text = Translate(getState, 'orders.orderUpdated');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
    }
  };

export const panicSellOrder =
  (id, pourcentage, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/PanicSell?orderId=${id}&pourcentage=${pourcentage}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      const text = Translate(getState, 'orders.orderPanicSell');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_GET_ORDER,
        payload: eu,
      });
    }
  };

export const deleteOrder =
  (id, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, `api/v1/Order/${id}`, {
      method: 'DELETE',
    });
    if (eu) {
      const text = Translate(getState, 'orders.orderDeleted');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_DELETE_ORDER,
        payload: id,
      });
    }
  };

export const refuseAllWaitValid =
  (userId = null) =>
  async (dispatch, getState) => {
    const userStr = userId === null ? '' : `?userId=${userId}`;
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/RefuseAllWaitValid${userStr}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      const text = Translate(getState, 'orders.orderDeleted');
      dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_GET_ORDERS,
        payload: eu,
      });
      // localStorage.setItem('orders', JSON.stringify(eu));
    }
  };

export const deleteEntryErrors =
  (userId = null) =>
  async (dispatch, getState) => {
    const userStr = userId === null ? '' : `?userId=${userId}`;
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/DeleteEntryErrors${userStr}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      const text = Translate(getState, 'orders.orderDeleted');
      dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_GET_ORDERS,
        payload: eu,
      });
      // localStorage.setItem('orders', JSON.stringify(eu));
    }
  };

export const panicSellAll =
  (tabOrders, userId = null) =>
  async (dispatch, getState) => {
    const userStr = userId === null ? '' : `&userId=${userId}`;
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/PanicSellAll?tabOrders=${tabOrders}${userStr}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      const text = Translate(getState, 'orders.orderDeleted');
      dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_GET_ORDERS,
        payload: eu,
      });
      // localStorage.setItem('orders', JSON.stringify(eu));
    }
  };

export const cancelAll =
  (tabOrders, userId = null) =>
  async (dispatch, getState) => {
    const userStr = userId === null ? '' : `&userId=${userId}`;
    const eu = await fetcher(
      dispatch,
      `api/v1/Order/CancelAll?tabOrders=${tabOrders}${userStr}`,
      {
        method: 'POST',
      },
    );
    if (eu) {
      const text = Translate(getState, 'orders.orderDeleted');
      dispatch(alertActions.alertSuccess(text));
      dispatch({
        type: orderConstants.ACTION_GET_ORDERS,
        payload: eu,
      });
      // localStorage.setItem('orders', JSON.stringify(eu));
    }
  };

export const createTradingViewJson = (guid, type = 0, triggerId = null) => {
  const result = {
    Guid: guid,
    Type: type,
  };
  if (!isNull(triggerId)) result.TriggerId = triggerId;
  return result;
};

export const createTradingViewString = (guid, type = 0, triggerId = null) =>
  JSON.stringify(createTradingViewJson(guid, type, triggerId));
