import { workerConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { isNull } from '../../views/SmartTrade/Common';
import { push } from 'connected-react-router';
import { alertActions } from '../alert/alert.actions';

export const resetWorker = () => async (dispatch) => {
  dispatch({
    type: workerConstants.ACTION_GET_WORKER,
    payload: null,
  });
};
export const getWorker = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Worker?id=${id}`, {});
  if (eu) {
    dispatch({
      type: workerConstants.ACTION_GET_WORKER,
      payload: eu,
    });
  }
};
export const getWorkers =
  (workerType = null) =>
  async (dispatch) => {
    const params = isNull(workerType) ? '' : `?workerType=${workerType}`;
    const eu = await fetcher(dispatch, `Worker/Workers${params}`, {});
    if (eu) {
      dispatch({
        type: workerConstants.ACTION_GET_WORKERS,
        payload: eu,
      });
    }
  };
export const postWorker = (worker) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, 'Worker', {
    method: 'POST',
    body: worker,
  });
  if (eu) {
    // const text = Translate(getState, 'exchanges.accountCreated');
    dispatch(alertActions.alertSuccess('Worker created'));
    dispatch({
      type: workerConstants.ACTION_POST_WORKER,
      payload: eu,
    });
    dispatch(push('/manageWorkers'));
  }
};

export const putWorker = (worker) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `Worker/${worker.WorkerId}`, {
    body: worker,
    method: 'PUT',
  });
  if (eu) {
    // const text = Translate(getState, 'exchanges.accountUpdated');
    dispatch(alertActions.alertSuccess('Worker updated'));
    dispatch({
      type: workerConstants.ACTION_PUT_WORKER,
      payload: eu,
    });
    dispatch(push('/manageWorkers'));
  }
};

export const deleteWorker = (workerId) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `Worker/${workerId}`, {
    method: 'DELETE',
  });
  if (eu) {
    // const text = Translate(getState, 'exchanges.accountDeleted');
    dispatch(alertActions.alertSuccess('Worker deleted'));
    dispatch({
      type: workerConstants.ACTION_DELETE_WORKER,
      payload: eu,
    });
  }
};
