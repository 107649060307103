import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';

import LittleButton from '../../../components/CustomButtons/LittleButton';
import Visibility from '@material-ui/icons/Visibility';
import connect from 'react-redux/es/connect/connect';
import { connectAs } from '../../../helpers/general';
import { getActiveCode } from '../../../helpers/localize';
import {
  getColumnTabStats,
  getDefaultColumnWidthsTabStats,
} from '../../../helpers/order';
import { ColorPourcentageProvider } from '../../../helpers/table';
import { isNull, parseToFloat } from '../../SmartTrade/Common';
import { adminUrl } from '../../../routes/dashboard';
import { AccountBox } from '@material-ui/icons';

class TableUser extends React.PureComponent {
  // promote = id => {
  //   if (
  //     !window.confirm('Êtes-vous sûr de donner les droits à cet Utilisateur ?')
  //   )
  //     return;
  //
  //   this.props.roleToUserActions(id);
  // };
  // demote = id => {
  //   if (
  //     !window.confirm('Êtes-vous sûr de retirer les droits à cet Utilisateur ?')
  //   )
  //     return;
  //
  //   this.props.deleteRoleToUserActions(id);
  // };

  constructor(props) {
    super(props);
    // const { codeLang } = this.props;

    let columns = [
      {
        name: 'B',
        title: 'B',
        getCellValue: (row) => {
          return (
            <div>
              <LittleButton
                tooltip="Show Order"
                color="warning"
                icon={<AccountBox />}
                onClick={() => {
                  connectAs(row['Id'], row['Email']);
                }}
                // to={adminUrl + '/user/' + row['Id'] + '/orders'}
              />
              <LittleButton
                tooltip="Show Order"
                color="info"
                icon={<Visibility />}
                to={adminUrl + '/user/' + row['Id'] + '/orders'}
              />
            </div>
          );
        },
      },
      {
        name: 'Email',
        title: 'Email',
      },
      {
        name: 'TelegramConfirm',
        title: 'TelegramConfirm',
        getCellValue: (row) => {
          return row['TelegramConfirm'] ? row['Telegram'] : 'false';
        },
      },
      {
        name: 'EmailConfirm',
        title: 'EmailConfirm',
        getCellValue: (row) => {
          return row['EmailConfirm'] ? 'true' : 'false';
        },
      },
      {
        name: 'DateWot',
        title: 'DateWot',
        // getCellValue: row => {
        //   return getDateStr(row['DateWot'], codeLang);
        // }
      },
      {
        name: 'Abos',
        title: 'Abos',
      },
      {
        name: 'DateCreated',
        title: 'DateCreated',
        // getCellValue: row => {
        //   return getDateHourStr(row['DateCreated'], codeLang);
        // }
      },
      {
        name: 'BinanceRef',
        title: 'BinanceRef',
        getCellValue: (row) => {
          return row['Rebate'];
        },
      },
      {
        name: 'CapitalBinance',
        title: 'Binance Capital',
        getCellValue: (row) => {
          return parseToFloat(row['CapitalBinance'], 0.1);
        },
      },
      {
        name: 'CapitalBinanceFutures',
        title: 'Futures Binance Capital',
        getCellValue: (row) => {
          return parseToFloat(row['CapitalBinanceFutures'], 0.1);
        },
      },
      {
        name: 'TotalFeesBinance',
        title: 'Fees Binance Total',
        getCellValue: (row) => {
          return parseToFloat(row['TotalFeesBinance'], 0.01);
        },
      },
      {
        name: 'TotalFeesBinanceFutures',
        title: 'Fees Futures Binance Total',
        getCellValue: (row) => {
          return parseToFloat(row['TotalFeesBinanceFutures'], 0.01);
        },
      },
      {
        name: 'TotalPaiement',
        title: 'Paiement WoT Total',
        getCellValue: (row) => {
          return parseToFloat(row['TotalPaiement'], 0.1);
        },
      },
      {
        name: 'Total',
        title: 'Total $',
      },
      {
        name: 'NbAccount',
        title: 'Accounts',
      },
      {
        name: 'NbOrder',
        title: 'Orders',
      },
      {
        name: 'LastDateOrder',
        title: 'LastDateOrder',
        // getCellValue: row => {
        //   return getDateHourStr(row['DateCreated'], codeLang);
        // }
      },
      {
        name: 'Lang',
        title: 'Lang',
      },
      {
        name: 'Newsletter',
        title: 'Newsletter',
        getCellValue: (row) => {
          return row['Newsletter'] ? 'true' : 'false';
        },
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'B', width: 100 },
      { columnName: 'Email', width: 250 },
      { columnName: 'NbOrder', width: 75 },
      { columnName: 'NbAccount', width: 75 },
      { columnName: 'BinanceRef', width: 75 },
      { columnName: 'CapitalBinance', width: 75 },
      { columnName: 'CapitalBinanceFutures', width: 75 },
      { columnName: 'TotalPaiement', width: 75 },
      { columnName: 'TotalFeesBinance', width: 75 },
      { columnName: 'TotalFeesBinanceFutures', width: 75 },
      { columnName: 'Total', width: 75 },
      { columnName: 'TelegramConfirm', width: 80 },
      { columnName: 'EmailConfirm', width: 80 },
      { columnName: 'DateWot', width: 100 },
      { columnName: 'Abos', width: 80 },
      { columnName: 'DateCreated', width: 100 },
      { columnName: 'LastDateOrder', width: 100 },
      { columnName: 'Lang', width: 50 },
      { columnName: 'Newsletter', width: 95 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.users,
      pageSizes: [5, 10, 20, 50, 0],
      colorColumns: [
        'ProfitTotal',
        'ProfitCapital',
        'PourcentageSuccess',
        'ProfitPerTrade',
        'ProfitPerTradeCapital',
      ],
      defaultColumnWidths: defaultColumnWidths,
      columnWidths: defaultColumnWidths,
      filters: [],
    };

    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate = () => {
    const users = this.props.users;
    this.setState({ rows: users });
  };

  onColumnWidthsChange = (widths) => {
    this.setState({ columnWidths: widths });
  };

  render() {
    const {
      rows,
      columns,
      defaultColumnWidths,
      columnWidths,
      filters,
      colorColumns,
    } = this.state;

    let newColumns = columns;
    let newDefaultColumnWidths = columnWidths;

    const withStats = rows.length > 0 && !isNull(rows[0].Stats);
    const containsStatsAlready =
      columns.filter((x) => x.name === 'NbSignalsWeek').length > 0;
    if (withStats && !containsStatsAlready) {
      newColumns = columns.concat(getColumnTabStats(true));
      newDefaultColumnWidths = defaultColumnWidths.concat(
        getDefaultColumnWidthsTabStats(),
      );
    }

    return (
      <Card>
        <Grid rows={rows} columns={newColumns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[{ columnName: 'DateCreated', direction: 'desc' }]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <SummaryState
            totalItems={[
              { columnName: 'CapitalBinance', type: 'sum' },
              { columnName: 'CapitalBinanceFutures', type: 'sum' },
              { columnName: 'TotalPaiement', type: 'sum' },
              { columnName: 'TotalFeesBinance', type: 'sum' },
              { columnName: 'TotalFeesBinanceFutures', type: 'sum' },
              { columnName: 'Total', type: 'sum' },
            ]}
          />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />

          <ColorPourcentageProvider for={colorColumns} />

          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            columnWidths={newDefaultColumnWidths}
            onColumnWidthsChange={this.onColumnWidthsChange}
          />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <TableSummaryRow />
          <PagingPanel pageSizes={[5, 10, 20, 50, 0]} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // roleToUserActions: bindActionCreators(roleToUser, dispatch),
    // deleteRoleToUserActions: bindActionCreators(deleteRoleToUser, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableUser);
