import { createSelector } from 'reselect';
import { exchangesSelector } from '../user/selector';

export const workersFormSelector = (state) => {
  const { form } = state;
  return form?.formWorker?.values;
};

export const workerSelector = (state) => {
  const { worker } = state;
  return worker?.worker;
};

export const workersSelector = (state) => {
  const { worker } = state;
  return worker?.workers;
};
