import { isEmpty, isNull, parseToFloat } from '../views/SmartTrade/Common';
import { getExchangeExEnum } from './order';
import { isDev } from './fetcher';
import { getObjectFromAsset } from './card';

export const KeyExchangeTable = {
  TOKEN: {
    K: 0,
    T: 'token',
  },
  AMOUNT: {
    K: 1,
    T: 'amount',
  },
  PRICE_USD: {
    K: 2,
    T: 'priceUSD',
  },
  TOTAL_USD: {
    K: 3,
    T: 'totalUSD',
  },
  POURCENTAGE: {
    K: 4,
    T: '%',
  },
  FREE: {
    K: 5,
    T: 'Free',
  },
  URL_CHARTS: {
    K: 6,
    T: 'charts1D',
  },
  URL_CHARTS7: {
    K: 7,
    T: 'charts7D',
  },
  POURC_24H: {
    K: 9,
    T: '% 24h',
  },
  POURC_7D: {
    K: 8,
    T: '% 7d',
  },
  VOL_24H: {
    K: 10,
    T: 'Vol 24h',
  },
  LOCKED: {
    K: 11,
    T: 'locked',
  },
  LOCKEDONWOT: {
    K: 12,
    T: 'lockedOnWot',
  },
  AVAILABLE: {
    K: 13,
    T: 'available',
  },
  DETAIL: {
    K: 14,
    T: 'detail',
  },
};

const concatExchangeTable = (r, t) => {
  for (const v of t) {
    // console.log(r, t, v);

    let m = null;
    // r.find(
    //   (x) => x[KeyExchangeTable.TOKEN.K] === v[KeyExchangeTable.TOKEN.K],
    // );
    // if (v[KeyExchangeTable.TOKEN.K] === 'XBT') {
    //   m = r.find((x) => x[KeyExchangeTable.TOKEN.K] === 'BTC');
    // }
    // if (v[KeyExchangeTable.TOKEN.K].indexOf('.S')) {
    //   m = r.find(
    //     (x) =>
    //       x[KeyExchangeTable.TOKEN.K] ===
    //       v[KeyExchangeTable.TOKEN.K].replace('.S', ''),
    //   );
    // }
    // console.log(m);
    if (m) {
      // meme token trouver
      // m[KeyExchangeTable.TOTAL_USD.K] = parseFloat(
      //   m[KeyExchangeTable.TOTAL_USD.K].toFixed(2),
      // );
      // m[KeyExchangeTable.DETAIL.K].push(v[KeyExchangeTable.DETAIL.K]);
    } else {
      r = [...r, v];
    }
  }
  return r;
};
export const getTableExchanges = (exchanges, tokenType) => {
  let table = [];
  for (const exchange of exchanges) {
    const t = getTableEx(exchange, tokenType);
    // table = concatExchangeTable(table, t);
    for (const v of t) {
      table.push(v);
    }
  }
  return table;
};

export const getPourcentage = (part, sum) => {
  return parseToFloat((part / sum) * 100, 0.01);
};

const addPourcentageAndSort = (table) => {
  let sumUSD = 0;
  for (const t of table) {
    sumUSD += t[KeyExchangeTable.TOTAL_USD.K];
  }
  for (const t of table) {
    t[KeyExchangeTable.POURCENTAGE.K] = getPourcentage(
      t[KeyExchangeTable.TOTAL_USD.K],
      sumUSD,
    );
  }
  return table.sort(
    (a, b) => b[KeyExchangeTable.TOTAL_USD.K] - a[KeyExchangeTable.TOTAL_USD.K],
  );
};

export const parsePriceUSD = (priceUSD) => {
  if (priceUSD < 1) return priceUSD.toFixed(4);
  if (priceUSD < 10) return priceUSD.toFixed(3);
  if (priceUSD < 100) return priceUSD.toFixed(2);
  if (priceUSD < 1000) return priceUSD.toFixed(1);
  return priceUSD.toFixed(0);
  // return priceUSD;
};

export const getApiLength = (exchangeName) => {
  let apiKeyLength = null;
  let apiSecretLength = null;
  if (!isNull(exchangeName)) {
    if (exchangeName.indexOf('Binance') !== -1) {
      apiKeyLength = 64;
      apiSecretLength = 64;
    } else if (exchangeName === 'Bitfinex') {
      apiKeyLength = 43;
      apiSecretLength = 43;
    } else if (exchangeName === 'Bittrex') {
      apiKeyLength = 32;
      apiSecretLength = 32;
    } else if (exchangeName === 'Bitmex') {
      apiKeyLength = 24;
      apiSecretLength = 48;
    } else if (exchangeName === 'Kraken') {
      apiKeyLength = 56;
      apiSecretLength = 88;
    } else if (exchangeName.indexOf('FTX') !== -1) {
      apiKeyLength = 40;
      apiSecretLength = 40;
    } else if (exchangeName === 'Kucoin') {
      apiKeyLength = 24;
      apiSecretLength = 36;
    } else if (exchangeName === 'Okx') {
      apiKeyLength = 36;
      apiSecretLength = 32;
    } else if (exchangeName === 'Ascendex') {
      apiKeyLength = 32;
      apiSecretLength = 64;
    } else if (exchangeName === 'CryptoCom') {
      apiKeyLength = 22;
      apiSecretLength = 22;
    }
  }
  return {
    apiKey: apiKeyLength,
    apiSecret: apiSecretLength,
  };
};

export const parseQty = (qty) => {
  if (!qty) return 0;
  if (qty < 1) return parseToFloat(qty, 0.000001);
  if (qty < 10) return parseToFloat(qty, 0.00001);
  if (qty < 100) return parseToFloat(qty, 0.0001);
  if (qty < 1000) return parseToFloat(qty, 0.001);
  return parseToFloat(qty, 0.01);
};

export const getAssetsTokenType = (exchange, tokenType) => {
  const assets = isNull(tokenType) ?
      JSON.parse(exchange?.AssetsDto) :
      JSON.parse(exchange?.AssetsDto)?.filter((x) => x.TokenType === tokenType);
  return assets ?? [];
};

export const getAssets = (exchange, tokenType) => {
  const assetsDto = Array.isArray(exchange?.AssetsDto) ? exchange?.AssetsDto : JSON.parse(exchange?.AssetsDto);
  const assets = isNull(tokenType) ?
      assetsDto :
      assetsDto?.filter((x) => x.TT === tokenType);
  return assets ?? [];
};

const getTableEx = (exchange, tokenType) => {
  const table = [];
  let assets = getAssets(exchange, tokenType);
  if (isNull(exchange) || isNull(assets)) {
    return table;
  }
  try {
    let i = 0;
    for (const asset of assets) {
      if (isNull(asset)) continue;
      const walletName = exchange.Name;
      const login = exchange.Login;
      const walletId = exchange.WalletId;

      table[i] = Object.assign(getObjectFromAsset(asset), {
        walletName: walletName,
        login: login,
        walletId: walletId,
      });
      ++i;
    }
  } catch (e) {
    console.error(e);
  }
  return table;
};

export const getAllExchanges = (exchanges) => {
  let allExchanges = {
    Name: 'All',
    AssetsDto: [],
    InGameInfos: [
      {
        Prana: 0,
        Cta: 0,
        Trisel: 0,
      },
    ],
  };
  if (!isNull(exchanges)) {
    for (const exchange of exchanges) {
      if (!isNull(exchange.InGameInfos) && !isNull(exchange.InGameInfos[0])) {
        allExchanges.InGameInfos[0].Prana += exchange.InGameInfos[0].Prana;
        allExchanges.InGameInfos[0].Cta += exchange.InGameInfos[0].Cta;
        allExchanges.InGameInfos[0].Trisel += exchange.InGameInfos[0].Trisel;
      }
      if (!isNull(exchange.AssetsDto)) {
        const assetsDto = Array.isArray(exchange?.AssetsDto) ? exchange?.AssetsDto : JSON.parse(exchange?.AssetsDto);
        for (const asset of assetsDto) allExchanges.AssetsDto.push(asset);
      }
    }
  }
  return allExchanges;
};

export const isBTC = (symbol) => {
  return symbol.toLowerCase() === 'btc' || symbol.toLowerCase() === 'xbt';
};

export const isALT = (symbol) => {
  return !isBTC(symbol) && !isStableCoin(symbol);
};

export const isStableCoin = (symbol) => {
  if (isEmpty(symbol)) return false;
  const symbolStr = symbol.toLowerCase();
  if (
    symbolStr.includes('usd') ||
    symbolStr.includes('eur') ||
    [
      'UST',
      'DAI',
      'PAX',
      'GBP',
      'CAD',
      'CHF',
      'JPY',
      'TRY',
      'AUD',
      'BIDR',
      'BRL',
      'RUB',
      'IDRT',
      'NGN',
      'UAH',
      'VAI',
      'BVND',
      'ZAR',
    ].includes(symbol)
  ) {
    return true;
  }
  return false;
};

// Ordre table: Currency, Total, Price $, Total $, %
export const getTableExchange = (exchange, tokenType) => {
  let table = [];
  try {
    if (exchange) {
      table = Array.isArray(exchange)
        ? getTableExchanges(exchange, tokenType)
        : getTableEx(exchange, tokenType);
    }
  } catch (e) {
    console.error(e);
  }
  return table;
};

const getTotalUSDExchange = (exchange, filter = null) => {
  let sumUSD = 0;
  const table = getTableExchange(exchange);
  if (table) {
    for (const t of table) {
      if (filter === null || filter(t[KeyExchangeTable.TOKEN.K])) {
        sumUSD += t[KeyExchangeTable.TOTAL_USD.K];
      }
    }
  }
  return sumUSD;
};

export const getTotalUSD = (exchanges, filter = null) => {
  if (!exchanges) return 0;

  let sumUSD = 0;
  if (Array.isArray(exchanges)) {
    for (const exchange of exchanges) {
      sumUSD += getTotalUSDExchange(exchange, filter);
    }
  } else {
    sumUSD += getTotalUSDExchange(exchanges, filter);
  }
  return sumUSD.toFixed(0);
};

export const getTotalCapital = (exchanges) => {
  if (!exchanges) return '';
  let sum = 0;
  if (Array.isArray(exchanges)) {
    for (const exchange of exchanges) {
      sum += exchange.Capital;
    }
  } else {
    sum += exchanges.Capital;
  }
  return sum.toFixed(0);
};

export const getPricePair = (pair, availableAmounts) => {
  if (!availableAmounts || availableAmounts.length === 0) return '';
  const binance = availableAmounts.find(
    (x) => x.ExchangeName === 'Binance_Spot',
  );
  const firstExchange = binance || availableAmounts[0];
  if (!firstExchange.PairsPrice || firstExchange.PairsPrice.length === 0)
    return '';
  const price = firstExchange.PairsPrice.find((x) => x.Pair === pair);
  if (!price) return '';
  return price.Last;
};

export const getExchangeSelect = (exchange) => {
  return { value: exchange, label: exchange, exchange };
};

export const isReferral = (exchange) => {
  const isReferral = exchange && exchange.IsReferral;
  const isPaperWallet = exchange && exchange.IsPaperTrading;

  return !isPaperWallet && isReferral;
};

const optionsExchanges = [
  // { value: 'Binance_Test', label: 'Binance_Test', exchange: 'Binance_Test' },
  { value: 'Binance_Spot', label: 'Binance_Spot', exchange: 'Binance_Spot' },
  {
    value: 'Binance_Futures',
    label: 'Binance_Futures',
    exchange: 'Binance_Futures',
  },
  { value: 'FTX', label: 'FTX', exchange: 'FTX' },
  { value: 'FTX_US', label: 'FTX_US', exchange: 'FTX_US' },
  { value: 'Kucoin', label: 'Kucoin', exchange: 'Kucoin' },
  { value: 'Kraken', label: 'Kraken', exchange: 'Kraken' },
  // { value: 'Bitmex', label: 'Bitmex', exchange: 'Bitmex' },
  { value: 'Bittrex', label: 'Bittrex', exchange: 'Bittrex' },
  { value: 'Huobi', label: 'Huobi', exchange: 'Huobi' },
  { value: 'Bitfinex', label: 'Bitfinex', exchange: 'Bitfinex' },
  { value: 'Binance_US', label: 'Binance_US', exchange: 'Binance_US' },
  {
    value: 'Binance_Margin',
    label: 'Binance_Margin',
    exchange: 'Binance_Margin',
  },
  { value: 'Okx', label: 'Okx', exchange: 'Okx' },
  { value: 'Ascendex', label: 'Ascendex', exchange: 'Ascendex' },
  { value: 'CryptoCom', label: 'CryptoCom', exchange: 'CryptoCom' },
];

if (isDev()) {
  optionsExchanges.unshift({
    value: 'Binance_Test',
    label: 'Binance_Test',
    exchange: 'Binance_Test',
  });
  optionsExchanges.unshift({
    value: 'Binance_Futures_Test',
    label: 'Binance_Futures_Test',
    exchange: 'Binance_Futures_Test',
  });
}

// const nameNewExchanges = [
//   { value: 'Ascendex', label: 'Ascendex', exchange: 'Ascendex' },
//   { value: 'BitFlyer', label: 'BitFlyer', exchange: 'BitFlyer' },
//   { value: 'Bitget', label: 'Bitget', exchange: 'Bitget' },
//   { value: 'Bitmart', label: 'Bitmart', exchange: 'Bitmart' },
//   { value: 'Bitmex', label: 'Bitmex', exchange: 'Bitmex' },
//   { value: 'Bitso', label: 'Bitso', exchange: 'Bitso' },
//   { value: 'Bitstamp', label: 'Bitstamp', exchange: 'Bitstamp' },
//   { value: 'Bitvavo', label: 'Bitvavo', exchange: 'Bitvavo' },
//   { value: 'Blockchain', label: 'Blockchain', exchange: 'Blockchain' },
//   { value: 'Btcmarkets', label: 'Btcmarkets', exchange: 'Btcmarkets' },
//   { value: 'Cdax', label: 'Cdax', exchange: 'Cdax' },
//   { value: 'Cex', label: 'Cex', exchange: 'Cex' },
//   { value: 'Coinbase', label: 'Coinbase', exchange: 'Coinbase' },
//   { value: 'Coincheck', label: 'Coincheck', exchange: 'Coincheck' },
//   { value: 'Coinex', label: 'Coinex', exchange: 'Coinex' },
//   { value: 'Coinone', label: 'Coinone', exchange: 'Coinone' },
//   { value: 'Cryptocom', label: 'Cryptocom', exchange: 'Cryptocom' },
//   { value: 'Currency', label: 'Currency', exchange: 'Currency' },
//   { value: 'Delta', label: 'Delta', exchange: 'Delta' },
//   { value: 'Deribit', label: 'Deribit', exchange: 'Deribit' },
//   { value: 'Exmo', label: 'Exmo', exchange: 'Exmo' },
//   { value: 'Gate', label: 'Gate', exchange: 'Gate' },
//   { value: 'Gemini', label: 'Gemini', exchange: 'Gemini' },
//   { value: 'Hitbtc', label: 'Hitbtc', exchange: 'Hitbtc' },
//   { value: 'Idex', label: 'Idex', exchange: 'Idex' },
//   { value: 'Lbank', label: 'Lbank', exchange: 'Lbank' },
//   { value: 'Liquid', label: 'Liquid', exchange: 'Liquid' },
//   {
//     value: 'Mercadobitcoin',
//     label: 'Mercadobitcoin',
//     exchange: 'Mercadobitcoin',
//   },
//   { value: 'Mexc', label: 'Mexc', exchange: 'Mexc' },
//   { value: 'Okcoin', label: 'Okcoin', exchange: 'Okcoin' },
//   { value: 'Okx', label: 'Okx', exchange: 'Okx' },
//   { value: 'Paymium', label: 'Paymium', exchange: 'Paymium' },
//   { value: 'Phemex', label: 'Phemex', exchange: 'Phemex' },
//   { value: 'Poloniex', label: 'Poloniex', exchange: 'Poloniex' },
//   { value: 'Procoinbase', label: 'Procoinbase', exchange: 'Procoinbase' },
//   { value: 'Upbit', label: 'Upbit', exchange: 'Upbit' },
//   { value: 'Whitebit', label: 'Whitebit', exchange: 'Whitebit' },
//   { value: 'Woo', label: 'Woo', exchange: 'Woo' },
//   { value: 'Zbex', label: 'Zbex', exchange: 'Zbex' },
// ];

for (const ex of optionsExchanges) {
  ex.value = getExchangeExEnum(ex.value);
}
export { optionsExchanges };

export const formatDataForPost = (exchangeUser) => {
  if (exchangeUser.capitalRadio === 'Auto' && !exchangeUser.isPaperTrading)
    exchangeUser.capital = null;
  if (exchangeUser.isPaperTrading) {
    exchangeUser.apiKey = null;
    exchangeUser.apiSecret = null;
  }
  const exchange =
    exchangeUser &&
    exchangeUser.exchangeName &&
    exchangeUser.exchangeName.exchange
      ? exchangeUser.exchangeName.exchange
      : '';
  if (exchange === 'Kucoin' || exchange === 'Okx') {
    exchangeUser.option = `{ PassPhrase: "${exchangeUser.passPhrase}" }`;
  }
  if (exchange.indexOf('FTX') !== -1 && !isNull(exchangeUser.subAccount)) {
    exchangeUser.option = `{ SubAccount: "${exchangeUser.subAccount}" }`;
  }
  return exchangeUser;
};
