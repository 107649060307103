import React from 'react';
import air from '../../assets/img/cta/elements/small/air.png';
import darkness from '../../assets/img/cta/elements/small/darkness.png';
import light from '../../assets/img/cta/elements/small/light.png';
import fire from '../../assets/img/cta/elements/small/fire.png';
import water from '../../assets/img/cta/elements/small/water.png';
import earth from '../../assets/img/cta/elements/small/earth.png';
import nature from '../../assets/img/cta/elements/small/nature.png';

import meditech from '../../assets/img/cta/elements/small/meditech.png';
import robotic from '../../assets/img/cta/elements/small/robotic.png';
import chaos from '../../assets/img/cta/elements/small/chaos.png';
import mantrix from '../../assets/img/cta/elements/small/mantrix.png';
import cybernetic from '../../assets/img/cta/elements/small/cybernetic.png';
import order from '../../assets/img/cta/elements/small/order.png';
import genetic from '../../assets/img/cta/elements/small/genetic.png';

import { isEmpty, isNull } from '../../views/SmartTrade/Common';
import {capitalize, listArkhome} from '../../helpers/general';

export default function Arkhome(props) {
  let { withText, value } = props;
  let name = value;
  if (isEmpty(name)) return <></>;
  name = name.toLowerCase();
  withText = isNull(withText) ? false : withText;
  // let elements = {};
  // listArkhome.forEach((element) => {
  //   elements[element] = '../../assets/img/cta/elements/small/'+element.toLowerCase()+'.png';
  // });
  const elements = {
    air: air,
    darkness: darkness,
    light: light,
    fire: fire,
    water: water,
    earth: earth,
    nature: nature,

    meditech: meditech,
    robotic: robotic,
    chaos: chaos,
    mantrix: mantrix,
    cybernetic: cybernetic,
    order: order,
    genetic: genetic,
  };

  return (
    <>
      <img width={14} height={14} src={elements[name]} alt={capitalize(name)} />
      &nbsp;
      {withText && <b>{capitalize(name)}</b>}
    </>
  );
}
