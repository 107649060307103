import React from "react";
import {connect} from "react-redux";
import {getKey} from "../../helpers/card";
import {Cached} from "@material-ui/icons";
import Button from "../CustomButtons/Button";
import {getAssets} from "../../redux/user/actions";
import {bindActionCreators} from "redux";
import {isNull} from "../../views/SmartTrade/Common";
import {transferPopupSelector} from "../../redux/user/selector";
import DialogTransferPopup from "../../views/Accounts/Exchange/DialogTransferPopup";
import {getWalletAddress} from "../../redux/imx/actions";

class SelectedAsset extends React.Component {
    render() {
        const { selected, assets, transferPopup } = this.props;
        const selectFiltred = selected.filter((item) => !isNull(item));
        const count = selectFiltred.map((item) => item?.Count ?? 0).reduce((a, b) => a + b, 0);
        const address = getWalletAddress();
        return (
            <>
                {/*<div>Selected: {selectFiltred.length} {count} {assets.length}</div>*/}
                {/*<div>{selected.map((item) =>*/}
                {/*    <div>*/}
                {/*        {getKey(item)} &nbsp; {item.Key} &nbsp;*/}
                {/*        {getKey(item) !== item.Key ? 'false': 'true'}*/}
                {/*    </div>)}*/}
                {/*</div>*/}
                <Button
                    onClick={() => {
                        this.props.getAssetsAction(selectFiltred, address);
                    }}
                    size="sm"
                >
                    Transfer {count}
                </Button>
                {transferPopup && <DialogTransferPopup tokenType={this.props.tokenType} assets={assets} />}
            </>
        );
    }
}
const mapStateToProps = (state, props) => {
    const { tokenType } = props;
    const { config, imx } = state;
    const { exchangesGrid } = config;
    const { selected } = exchangesGrid[tokenType];
    const { assets } = imx;
    return {
        transferPopup: transferPopupSelector(state),
        selected,
        assets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAssetsAction: bindActionCreators(getAssets, dispatch),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectedAsset);
