import React, {useEffect, useState} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import GridContainer from "../../components/Grid/GridContainer";
import {darkColorTransparent, lightColorTransparent} from "../../assets/jss/material-dashboard-react";
import {connect, useDispatch} from "react-redux";
import {getSupplies} from "../../redux/card/actions";
import TableSupplies from "./TableSupplies";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import {Cached, Clear} from "@material-ui/icons";
import {
    createSuppliesGridAction,
    resetSuppliesColumns
} from "../../redux/config/actions";

const SuppliesPage = props => {
    const theme = props.theme;
    const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;
    const dispatch = useDispatch();
    const [set, setSet] = useState(0)
    const [supplyType, setSupplyType] = useState(0)
    const [groupAnimationLevel, setGroupAnimationLevel] = useState(true)
    const [groupElement, setGroupElement] = useState(true)
    useEffect(() => {
        dispatch(getSupplies(supplyType, set, groupAnimationLevel, groupElement));
    }, [set, supplyType, groupAnimationLevel, groupElement]);
    return (
        <GridContainer>
            <GridItem
                container
                xs={12}
                sm={8}
                md={8}
                alignItems="center"
                justifyContent="flex-start"
            >
                <div>
                    <span>
                      <Button
                          onClick={() => {
                              localStorage.removeItem('suppliesGrid');
                              props.resetSuppliesColumnsActions();
                          }}
                          size="sm"
                      >
                        <Cached />
                          &nbsp; Reset Table
                      </Button>
                      <Button
                          onClick={() => {
                              props.clearFilterSuppliesActions();
                          }}
                          size="sm"
                      >
                        <Clear />
                          &nbsp; Reset filter
                      </Button>
                    </span>
                </div>
            </GridItem>

            <GridItem
                container
                xs={12}
                sm={8}
                md={8}
                alignItems="center"
                justifyContent="flex-start"
            >
                <div>
                    <span>
                      <Button
                          onClick={() => {
                              setSet(0)
                          }}
                          color={set === 0 ? 'primary' : 'default'}
                          size="sm"
                      >
                        Arkantes
                      </Button>
                      <Button
                          onClick={() => {
                              setSet(1)
                          }}
                          color={set === 1 ? 'primary' : 'default'}
                          size="sm"
                      >
                        Mantris
                      </Button>
                      <Button
                          onClick={() => {
                              setSet(null)
                          }}
                          color={set === null ? 'primary' : 'default'}
                          size="sm"
                      >
                        All
                      </Button>
                    </span>
                </div>
            </GridItem>
            <GridItem
                container
                xs={12}
                sm={8}
                md={8}
                alignItems="center"
                justifyContent="flex-start"
            >
                <div>
                    <span>
                      <Button
                          onClick={() => {
                              setSupplyType(0)
                          }}
                          color={supplyType === 0 ? 'primary' : 'default'}
                          size="sm"
                      >
                        Standard
                      </Button>
                      <Button
                          onClick={() => {
                              setSupplyType(1)
                          }}
                          color={supplyType === 1 ? 'primary' : 'default'}
                          size="sm"
                      >
                        Alternative
                      </Button>
                      <Button
                          onClick={() => {
                              setSupplyType(2)
                          }}
                          color={supplyType === 2 ? 'primary' : 'default'}
                          size="sm"
                      >
                        Combo
                      </Button>
                      <Button
                          onClick={() => {
                              setSupplyType(3)
                          }}
                          color={supplyType === 3 ? 'primary' : 'default'}
                          size="sm"
                      >
                        All
                      </Button>
                    </span>
                </div>
            </GridItem>
            <GridItem
                container
                xs={12}
                sm={8}
                md={8}
                alignItems="center"
                justifyContent="flex-start"
            >
                <div>
                    <span>
                      <Button
                          onClick={() => {
                              setGroupAnimationLevel(!groupAnimationLevel)
                          }}
                          color={groupAnimationLevel === true ? 'primary' : 'default'}
                          size="sm"
                      >
                        Group Animation Level
                      </Button>
                      <Button
                          onClick={() => {
                              setGroupElement(!groupElement)
                          }}
                          color={groupElement === true ? 'primary' : 'default'}
                          size="sm"
                      >
                        Group Element
                      </Button>
                    </span>
                </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <TableSupplies supplies={props.supplies} supplyType={supplyType} set={set} groupAnimationLevel={groupAnimationLevel} groupElement={groupElement} />
            </GridItem>
        </GridContainer>
    );
}

function mapStateToProps(state) {
    const { config, card } = state;
    return {
        theme: config.theme,
        supplies: card.supplies,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetSuppliesColumnsActions: () => dispatch(resetSuppliesColumns()),
        clearFilterSuppliesActions: () =>
            dispatch(createSuppliesGridAction('filters', [])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(SuppliesPage));
