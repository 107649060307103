import React from 'react';
import { Translate } from 'react-localize-redux';

export default (values) => {
  const errors = {};
  const requiredFields = ['Name', 'Address'];
  requiredFields.forEach((field) => {
    if (
      (values[field] &&
        Array.isArray(values[field]) &&
        values[field].length === 0) ||
      !values[field]
    ) {
      errors[field] = <Translate id="central.required" />;
    }
  });

  return errors;
};
