import { imxConstants } from './constants';

const imxInitialState = {
  assets: [],
};

export function imx(state = imxInitialState, action) {
  switch (action.type) {
    case imxConstants.ACTION_SET_ASSETS:
        return {
            ...state,
            assets: action.payload,
        }
    default:
      return state;
  }
}
