import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../components/Grid/GridItem';

import { connect } from 'react-redux';
import { getTotalUSD, getPricePair } from '../../helpers/exchange';
import { ShowPair } from '../Fields/LogoContainer';
import Hidden from '@material-ui/core/Hidden';

const styles = {
  textCenter: {
    textAlign: 'center',
  },
};

class HeaderDetails extends React.Component {
  render() {
    const { exchanges, availableAmounts, classes } = this.props;
    const totalUSD = getTotalUSD(exchanges);
    const priceBTC = getPricePair('BTC-USDT', availableAmounts);
    const priceETH = getPricePair('ETH-USDT', availableAmounts);
    const totalBTC = (totalUSD / priceBTC).toFixed(4);
    const totalETH = (totalUSD / priceETH).toFixed(2);
    return (
      <Grid container>
        {priceBTC && (
          <Hidden xsDown implementation="css">
            <GridItem>
              <div>
                <small>{ShowPair('BTC-USDT')}</small>
              </div>
              <div className={classes.textCenter}>
                <b>{priceBTC.toFixed(0)}$</b>
              </div>
            </GridItem>
          </Hidden>
        )}
        {priceETH && (
          <Hidden xsDown implementation="css">
            <GridItem>
              <div>
                <small>{ShowPair('ETH-USDT')}</small>
              </div>
              <div className={classes.textCenter}>
                <b>{priceETH.toFixed(0)}$</b>
              </div>
            </GridItem>
          </Hidden>
        )}
        {totalUSD > 0 && (
          <GridItem style={{ marginTop: '-12px' }}>
            <div>
              <div className={'s-s-USD'} />
              <small>
                <b>{totalUSD}</b>
              </small>
            </div>
            <div>
              <div className={'s-s-BTC'} />
              <small>
                <b>{totalBTC}</b>
              </small>
            </div>
            <div>
              <div className={'s-s-ETH'} />
              <small>
                <b>{totalETH}</b>
              </small>
            </div>
          </GridItem>
        )}
      </Grid>
    );
  }
}

HeaderDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user, order } = state;

  return {
    availableAmounts: order.availableAmounts,
    exchanges: user.exchanges,
  };
};

const connectedHeaderDetails = connect(mapStateToProps, null)(HeaderDetails);

export default withStyles(styles)(connectedHeaderDetails);
