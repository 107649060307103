export const themeSelector = (state) => state?.config?.theme;
export const notificationSelector = (state) => {
  const { config } = state;
  return config.notificationSettings;
};

export const notificationErrorSelector = (state) => {
  const { config } = state;
  return config.notificationError;
};
