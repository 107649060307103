import { adminConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';

const fetchUser = async (dispatch, withOrders = false, withStats = false) => {
  const eu = await fetcher(
    dispatch,
    `Admin/Users?withOrders=${withOrders}&withStats=${withStats}`,
    {},
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USERS,
      payload: eu,
    });
  }
  return eu;
};

export const IsAdmin = () => async (dispatch) => {
  await fetcher(dispatch, 'Admin/IsAdmin', {});
  // await fetcher(dispatch, 'Admin/chargeMany?groupId=32&nbrPerApi=10', {});
  // if (eu) {
  //   return;
  // }
  // dispatch(push('/dashboard'));
};

export const getUsers = (withOrders, withStats) => async (dispatch) => {
  await fetchUser(dispatch, withOrders, withStats);
};

export const getCompanyDatas = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/CompanyDatas', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_COMPANY_DATA,
      payload: eu,
    });
  }
};

export const getOrderDetail = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Admin/GetOrderDetail?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_DETAIL,
      payload: eu,
    });
  }
};

export const getOrderInfo = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Admin/GetOrderInfo?id=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDER_INFO,
      payload: eu,
    });
  }
};

export const getScholarsUser = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Admin/GetScholarsUser?userId=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_SCHOLARS,
      payload: eu,
    });
  }
};

export const getExchangesUser = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Admin/GetExchangesUser?userId=${id}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_EXCHANGES,
      payload: eu,
    });
  }
};

export const getOrders = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/Orders', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getOrdersGroup =
  (
    groupId,
    dateFrom = null,
    dateTo = null,
    withOrderOnStats = true,
    orderFollowers = false,
  ) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `Admin/OrdersGroup?groupId=${groupId}${date}&withOrderOnStats=${withOrderOnStats}&orderFollowers=${orderFollowers}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_USER_ORDERS,
        payload: eu,
      });
    }
  };

export const getOrdersLimit = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/OrdersLimit', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_USER_ORDERS,
      payload: eu,
    });
  }
};

export const getTradingSummaries =
  (dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(dispatch, `Admin/GetTradingSummary?1${date}`, {
      method: 'GET',
    });
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_TRADING_SUMMARIES,
        payload: eu,
      });
    }
  };

export const getUsersVolume =
  (dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(dispatch, `Admin/GetUsersVolume?1${date}`, {
      method: 'GET',
    });
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_USERS_VOLUME,
        payload: eu,
      });
    }
  };

export const getPositions = (userId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Admin/getPositions?userId=${userId}`, {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getOpenOrdersGroup = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `Admin/GetPositionsByGroupId?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ORDERS_GROUP,
      payload: eu,
    });
  }
};

export const getAccountsGroup = (groupId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `Trader/GetAccountsGroup?groupId=${groupId}`,
    {
      method: 'GET',
    },
  );
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_ACCOUNTS_GROUP,
      payload: eu,
    });
  }
};

export const getStatGroup =
  (groupId, dateFrom = null, dateTo = null) =>
  async (dispatch) => {
    const date =
      (dateFrom != null ? `&from=${dateFrom}` : '') +
      (dateTo != null ? `&to=${dateTo}` : '');
    const eu = await fetcher(
      dispatch,
      `Admin/GetStatGroup?groupId=${groupId}${date}`,
      {
        method: 'GET',
      },
    );
    if (eu) {
      dispatch({
        type: adminConstants.ACTION_GET_STATS_GROUP,
        payload: eu,
      });
    }
  };

export const getStatsGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/GetStatsGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_STATS_GROUP,
      payload: eu,
    });
  }
};

export const getPaiements = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/Paiements', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_PAIEMENTS,
      payload: eu,
    });
  }
};

export const setSaveDateDebug = (val, exchange) => async (dispatch) => {
  await fetcher(
    dispatch,
    `Admin/SetSaveDateDebug?exchange=${exchange}&val=${val}`,
    {
      method: 'GET',
    },
  );
};

export const getCurrentPrices = () => async (dispatch) => {
  const start = new Date();
  const eu = await fetcher(dispatch, 'Admin/GetCurrentPrices', {
    method: 'GET',
  });
  if (eu) {
    const end = new Date();
    const repTime = end - start;
    dispatch({
      type: adminConstants.ACTION_GET_CURRENT_PRICES,
      payload: eu,
      repTime: repTime,
      reqDate: end,
    });
  }
};

export const getHistoricEmail = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/HistoricEmail', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_HISTORIC_EMAIL,
      payload: eu,
    });
  }
};
export const getSubscriptionGroup = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/GetSubscriptionGroup', {
    method: 'GET',
  });
  if (eu) {
    dispatch({
      type: adminConstants.ACTION_GET_SUBSCRIPTION_GROUP,
      payload: eu,
    });
  }
};

export const postInscriptionGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'Admin/UpdateInscriptionGroup', {
    method: 'POST',
    body,
  });
};

export const postSubGroups = (body) => async (dispatch) => {
  await fetcher(dispatch, 'Admin/UpdateSubGroup', {
    method: 'POST',
    body,
  });
};

export const postEmails = (dataEmail) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Admin/SendEmails', {
    method: 'POST',
    body: dataEmail,
  });
  if (eu) {
    dispatch(alertActions.alertSuccess('Email envoyé'));
  }
};

// export const roleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'Admin/RoleToUser?id=' + id, {
//     method: 'POST',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
//
// export const deleteRoleToUser = id => async (dispatch, getState) => {
//   const eu = await fetcher(dispatch, 'Admin/RoleToUser?id=' + id, {
//     method: 'DELETE',
//     body: id
//   });
//   await fetchUser(dispatch);
// };
