import DashboardPage from '../views/Dashboard/DashboardPage.jsx';
import ExchangePage from '../views/Accounts/Exchange/ExchangePage.jsx';
import ExternalPage from '../views/Accounts/Externals/ExternalPage';
import ExtraTokensPage from '../views/Accounts/ExtraTokens/ExtraTokensPage';
import IdentificationPage from '../views/Authentication/IdentificationPage.jsx';
import LogoutPage from '../views/Authentication/LogoutPage.jsx';
import ConfirmationEmailPage from '../views/Authentication/ConfirmationEmailPage.jsx';
import UnsubscribeEmailPage from '../views/Authentication/UnsubscribeEmailPage.jsx';
import ForgotPasswordPage from '../views/Authentication/ForgotPasswordPage.jsx';
import ResetPasswordPage from '../views/Authentication/ResetPasswordPage.jsx';
import ExchangesPage from '../views/Accounts/Exchanges/ExchangesPage.jsx';
import FAQPage from '../views/Faq/FaqPage.jsx';
import AccountsPage from '../views/Accounts/AccountsPage.jsx';

import PolitiqueConfidentialitePage from '../views/Laws/PolitiqueConfidentialitePage.jsx';
import PrivacyPolicyPage from '../views/Laws/PrivacyPolicyPage.jsx';
import PrivacyPolicyRUPage from '../views/Laws/PrivacyPolicyRUPage.jsx';
import CGVUPage from '../views/Laws/CGVUPage.jsx';
import TermsAndConditionsPage from '../views/Laws/TermsAndConditionsPage.jsx';
import TermsAndConditionsRUPage from '../views/Laws/TermsAndConditionsRUPage.jsx';

import HistoricEmailPage from '../views/Admin/HistoricEmail/HistoricEmailPage';
import UsersPage from '../views/Admin/Users/UsersPage.jsx';
import UserOrdersPage from '../views/Admin/Users/UserOrdersPage.jsx';
import DashboardAdminPage from '../views/Admin/DashboardAdminPage.jsx';
import PaiementsPage from '../views/Admin/Paiements/PaiementsPage.jsx';

import { Translate } from 'react-localize-redux';
import React from 'react';
import { isLogged, showInitSteppers } from '../helpers/general';
import EmailsPage from '../views/Admin/Emails/EmailsPage';

import AccountsGroupPage from '../views/Admin/StatsGroup/AccountsGroupPage';
import SettingsPage from '../views/Settings/SettingsPage';
import AssetsPage from '../views/Assets/AssetsPage';
import ScholarsPage from '../views/Scholars/ScholarsPage';
import ScholarPage from '../views/Scholars/ScholarPage';
import ManageWorkerPage from '../views/ManageWorkers/ManageWorkerPage';
import WorkerPage from '../views/ManageWorkers/WorkerPage';
import TransfersPage from "../views/Tranfers/TransfersPage";
import TeamPage from "../views/Team/TeamPage";
import CollectionsPage from "../views/Collections/CollectionsPage";
import GuildWhiteIconUrl from '../assets/img/icons-menu/guild-white.svg';
import GuildBlackIconUrl from '../assets/img/icons-menu/guild-black.svg';
import DeckWhiteIconUrl from '../assets/img/icons-menu/deck-white.svg';
import DeckBlackIconUrl from '../assets/img/icons-menu/deck-black.svg';
import CollectionWhiteIconUrl from '../assets/img/icons-menu/cards-white.svg';
import CollectionBlackIconUrl from '../assets/img/icons-menu/cards-black.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import HomePage from "../views/Dashboard/HomePage";
import GuildsPage from "../views/Guild/GuildsPage";
import MarketPage from "../views/Market/MarketPage";
import SwapNFTPage from "../views/SwapNFT/SwapNFTPage";
import MiniGamePage from "../views/MiniGame/MiniGamePage";
import TournamentPage from "../views/Tournament/TournamentPage";
import AlertsPage from "../views/Alerts/AlertsPage";
import DeckBuildingPage from "../views/DeckBuilding/DeckBuildingPage";
import StatsPage from "../views/Stats/StatsPage";
import SuppliesPage from "../views/Supplies/SuppliesPage";

export const adminUrl = '/admincta2022';
export const traderUrl = '/admin';

export const getDashboardRoutes = ({auth, lang = 'en', isDark}) => {
  let dashboardRoutes = [];
  const logged = isLogged(auth);

  dashboardRoutes.push(
    {
      path: '/home',
      sidebarName: <Translate id="menu.home" />,
      navbarName: <Translate id="menu.home" />,
      icon: 'home',
      component: HomePage,
    },
    {
      path: '/dashboard',
      sidebarName: <Translate id="menu.myDashboard" />,
      navbarName: <Translate id="menu.myDashboard" />,
      icon: 'dashboard',
      component: DashboardPage,
      disabled: !logged,
    },
    {
      path: '/supplies',
      sidebarName: 'Supplies',
      navbarName: 'Supplies',
      icon: 'inventory',
      component: SuppliesPage
    },
    {
      path: '/assets',
      // sidebarName: 'Assets',
      navbarName: 'Assets',
      icon: 'list',
      component: AssetsPage,
      disabled: !logged,
    },
    {
      path: '/extraTokens/:id',
      navbarName: 'My Extra Tokens',
      component: ExtraTokensPage,
    },
    {
      path: '/extraTokens',
      navbarName: 'My Extra Tokens',
      component: ExtraTokensPage,
    },
    {
      path: adminUrl + '/user/:id/orders',
      navbarName: 'Admin users',
      component: UserOrdersPage,
    },
    {
      path: adminUrl + '/users',
      navbarName: 'Admin users',
      component: UsersPage,
    },
    {
      path: traderUrl + '/accounts/:id',
      navbarName: 'Accounts',
      component: AccountsGroupPage,
    },
    {
      path: adminUrl + '/historicEmail',
      navbarName: 'Historic Email',
      component: HistoricEmailPage,
    },
    {
      path: adminUrl + '/paiements',
      navbarName: 'Paiements',
      component: PaiementsPage,
    },
    {
      path: adminUrl + '/emails',
      navbarName: 'Emails',
      component: EmailsPage,
    },
    {
      path: adminUrl,
      navbarName: 'Admin',
      component: DashboardAdminPage,
    },
    // {
    //   path: '/orders',
    //   sidebarName: <Translate id="menu.myOrders" />,
    //   navbarName: <Translate id="menu.myOrders" />,
    //   icon: 'reorder',
    //   component: OrdersPage,
    //   disabled: !logged,
    // },
    {
      path: '/manageWorkers',
      navbarName: 'Manage Workers',
      component: ManageWorkerPage,
    },
    {
      path: '/worker/:id',
      navbarName: 'Worker',
      component: WorkerPage,
    },
    {
      path: '/worker',
      navbarName: 'Worker',
      component: WorkerPage,
    },
    {
      path: '/scholars',
      // sidebarName: 'Scholars',
      navbarName: 'Scholars',
      icon: 'account_box',
      component: ScholarsPage,
      disabled: !logged,
    },
    {
      path: '/scholar/:id',
      navbarName: 'Scholar',
      component: ScholarPage,
    },
    {
      path: '/accounts',
      sidebarName: <Translate id="menu.myExchanges" />,
      navbarName: <Translate id="menu.myExchanges" />,
      icon: 'account_balance_wallet',
      component: AccountsPage,
      disabled: !logged,
    },
    {
      path: '/team',
      sidebarName:  <Translate id="menu.team" />,
      navbarName:  <Translate id="menu.team" />,
      icon: 'groups',
      // secondary: <Translate id="menu.soon" />,
      component: TeamPage,
    },
    {
      // disabled: true,
      path: '/collections',
      sidebarName: <Translate id="menu.collections" />,
      navbarName: <Translate id="menu.collections" />,
      secondary: <Translate id="menu.soon" />,
      icon: <img width={24} height={24} src={isDark ? CollectionWhiteIconUrl : CollectionBlackIconUrl} alt={'Collections Icon'} />,
      component: CollectionsPage,
    },
    {
      path: '/guilds',
      sidebarName: <Translate id="menu.guilds" />,
      navbarName: <Translate id="menu.guilds" />,
      secondary: <Translate id="menu.soon" />,
      icon: <img width={24} height={24} src={isDark ? GuildWhiteIconUrl : GuildBlackIconUrl} alt={'Guild Icon'} />,
      component: GuildsPage,
    },
    // {
    //   disabled: true,
    //   path: '/profile',
    //   sidebarName: <Translate id="menu.profile" />,
    //   navbarName: <Translate id="menu.profile" />,
    //   secondary: <Translate id="menu.soon" />,
    //   icon: "person",
    //   component: CollectionsPage,
    // },
    {
      path: '/market',
      sidebarName: <Translate id="menu.market" />,
      navbarName: <Translate id="menu.market" />,
      secondary: <Translate id="menu.soon" />,
      icon: "store",
      component: MarketPage,
    },
    // {
    //   path: '/giveaway',
    //   sidebarName: <Translate id="menu.giveaway" />,
    //   disabled: true,
    //   navbarName: <Translate id="menu.giveaway" />,
    //   secondary: <Translate id="menu.soon" />,
    //   icon: "redeem",
    //   component: CollectionsPage,
    // },
    {
      path: '/swapNFT',
      sidebarName: <Translate id="menu.swapNFT" />,
      navbarName: <Translate id="menu.swapNFT" />,
      secondary: <Translate id="menu.soon" />,
      icon: "swap_horiz",
      component: SwapNFTPage,
    },
    {
      path: '/deckbuilder',
      sidebarName: <Translate id="menu.deckbuilder" />,
      navbarName: <Translate id="menu.deckbuilder" />,
      secondary: <Translate id="menu.soon" />,
      icon: <img width={24} height={24} src={isDark ? DeckWhiteIconUrl : DeckBlackIconUrl} alt={'Deck Icon'} />,
      component: DeckBuildingPage,
    },
    {
      path: '/stats',
      sidebarName: <Translate id="menu.stats" />,
      navbarName: <Translate id="menu.stats" />,
      secondary: <Translate id="menu.soon" />,
      icon: 'equalizer',
      component: StatsPage,
    },
    {
      path: '/alerts',
      sidebarName: <Translate id="menu.alerts" />,
      navbarName: <Translate id="menu.alerts" />,
      secondary: <Translate id="menu.soon" />,
      icon: 'notifications',
      component: AlertsPage,
    },
    {
      path: '/tournament',
      sidebarName: <Translate id="menu.tournament" />,
      navbarName: <Translate id="menu.tournament" />,
      secondary: <Translate id="menu.soon" />,
      icon: <FontAwesomeIcon style={{width: '24px'}} icon={faTrophy} />,
      component: TournamentPage,
    },
    {
      path: '/mini-game',
      sidebarName: <Translate id="menu.mini-game" />,
      navbarName: <Translate id="menu.mini-game" />,
      secondary: <Translate id="menu.soon" />,
      icon: 'videogame_asset',
      component: MiniGamePage,
    },
    {
      path: '/manageWorkers',
      navbarName: 'Manage Workers',
      component: ManageWorkerPage,
    },
    {
      path: '/transfers',
      // sidebarName: 'Transfers',
      navbarName: 'Transfers',
      icon: 'arrow_outward',
      component: TransfersPage,
      disabled: !logged,
    },
    // {
    //   path: '/subscriptions',
    //   sidebarName: <Translate id="menu.subscriptions" />,
    //   navbarName: <Translate id="menu.subscriptions" />,
    //   icon: 'add_shopping_cart',
    //   component: PaiementPage,
    //   disabled: !logged,
    // },
    {
      path: '/exchanges/:id',
      navbarName: <Translate id="menu.myExchanges" />,
      component: ExchangesPage,
    },
    {
      path: '/exchanges',
      navbarName: <Translate id="menu.myExchanges" />,
      component: ExchangesPage,
    },
    {
      path: '/externals/:id',
      navbarName: <Translate id="menu.myExternals" />,
      component: ExternalPage,
    },
    {
      path: '/externals',
      navbarName: <Translate id="menu.myExternals" />,
      component: ExternalPage,
    },
    {
      path: '/exchange/:id',
      navbarName: 'ExchangeID',
      component: ExchangePage,
    },
    {
      path: '/settings',
      sidebarName: <Translate id="menu.settings" />,
      navbarName: <Translate id="menu.settings" />,
      icon: 'settings',
      component: SettingsPage,
      disabled: !logged,
    },
    {
      link: 'https://twitter.com/CTAGameCom',
      sidebarName: <Translate id="menu.joinUs" />,
      navbarName: <Translate id="menu.joinUs" />,
      icon: <FontAwesomeIcon style={{width: '24px'}} icon={faTwitter} />
    },
    // {
    //   path: '/strategy',
    //   sidebarName: 'Strategy',
    //   navbarName: 'Strategy',
    //   icon: 'strategy',
    //   component: StrategyPage
    // },
    // {
    //   link: blogLinks[lang],
    //   navbarName: <Translate id="menu.blog" />,
    //   icon: <FontAwesomeIcon icon={faBlog} />,
    //   sidebarName: <Translate id="menu.blog" />,
    // },
    {
      path: '/faq/:id',
      navbarName: <Translate id="menu.FAQ" />,
      component: FAQPage,
    },
    // {
    //   path: '/faq',
    //   navbarName: <Translate id="menu.FAQ" />,
    //   icon: 'help',
    //   sidebarName: <Translate id="menu.FAQ" />,
    //   component: FAQPage,
    // },
    {
      path: '/privacy-policy',
      navbarName: 'Legal notice and privacy policy',
      component: PrivacyPolicyPage,
    },
    {
      path: '/condifent',
      navbarName: 'Legal notice and privacy policy',
      component: PrivacyPolicyRUPage,
    },
    {
      path: '/politique-de-confidentialite',
      navbarName: 'Politique de confidentialité',
      component: PolitiqueConfidentialitePage,
    },
    {
      path: '/cgvu',
      navbarName: 'Conditions Générales de Vente et d’Utilisation',
      component: CGVUPage,
    },
    {
      path: '/terms-and-conditions',
      navbarName: 'Terms and conditions',
      component: TermsAndConditionsPage,
    },
    {
      path: '/politika',
      navbarName: 'Terms and conditions',
      component: TermsAndConditionsRUPage,
    },
  );

  if (logged) {
    dashboardRoutes.push({
      path: '/disconnect',
      sidebarName: <Translate id="menu.disconnect" />,
      navbarName: <Translate id="menu.disconnect" />,
      icon: 'power_settings_new',
      component: LogoutPage,
    });
  } else {
    dashboardRoutes.push(
      {
        path: '/identification',
        sidebarName: <Translate id="menu.identification" />,
        navbarName: <Translate id="menu.identification" />,
        icon: 'account_circle',
        component: IdentificationPage,
      },
      {
        path: '/confirmationEmail',
        navbarName: <Translate id="menu.confirmationEmail" />,
        component: ConfirmationEmailPage,
      },
      {
        path: '/unsubscribe',
        navbarName: <Translate id="menu.unsubscribeEmail" />,
        component: UnsubscribeEmailPage,
      },
      {
        path: '/forgotPassword',
        navbarName: <Translate id="menu.forgotPassword" />,
        component: ForgotPasswordPage,
      },
      {
        path: '/resetPassword',
        navbarName: <Translate id="menu.resetPassword" />,
        component: ResetPasswordPage,
      },
    );
  }

  dashboardRoutes.push({
    redirect: true,
    path: '/',
    to: isLogged(auth) ? '/dashboard' : '/home',
    navbarName: 'Redirect',
  });
  return dashboardRoutes;
};
