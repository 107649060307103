import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
// import Select from 'react-virtualized-select';
import { List } from 'react-virtualized';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import SelectWrapper from './SelectWrapper';
import { dangerColor } from '../../assets/jss/material-dashboard-react';
import OptionsContainer from '../Fields/OptionsContainer';
import { isNull } from '../../views/SmartTrade/Common';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    // padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    // alignItems: 'center'
  },
  chip: {
    margin: `0px ${theme.spacing(0.25)}px`,
    marginTop: '0.25em',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    fontWeight: 400,
    color: 'inherit',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    opacity: 0.7,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  danger: {
    color: dangerColor,
  },
  indicatorSeparator: {
    alignSelf: 'stretch',
    backgroundColor: 'currentColor',
    // marginBottom: '8px',
    // marginTop: '8px',
    width: '1px',
    boxSizing: 'border-box',
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const IndicatorSeparator = ({ ...props }) => {
  return <span className={props.selectProps.classes.indicatorSeparator} />;
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <OptionsContainer {...props} />
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      <OptionsContainer {...props} />
    </div>
  );
}

function SingleValue(props) {
  return (
    <div
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      <OptionsContainer {...props} />
    </div>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      size={'small'}
      disabled={!!props.isDisabled}
      tabIndex={-1}
      label={<OptionsContainer {...props} />}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
const MenuList = (props) => {
  let rowCount = 0;
  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );
  const noRowRenderer = () => <div>No Options !!</div>;
  if (Array.isArray(rows)) rowCount = rows.length;

  return (
    <List
      style={{ width: '100%' }}
      width={1000}
      height={rowCount > 5 ? 220 : rowCount * 44}
      rowHeight={44}
      rowCount={rowCount}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowRenderer}
    />
  );
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// const filterOptions = (candidate, input) => {
//   if (input) {
//     const cs = candidate.value.toLowerCase().trim().split('-');
//     const i = input.toLowerCase().trim();
//     const strs = i.split(' ');
//     let ret = false;
//     for (const c of cs) {
//     }
//     return true;
//     // return candidate.value === input;
//   }
//   return true;
// };

/**
 * @deprecated we are now at version 2 of react select. This component must migrate.
 * Use @see SelectMaterialUI instead.
 */
class ReactSelectUI extends React.Component {
  state = {
    single: null,
    multi: null,
  };

  handleChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { classes, theme, meta, required } = this.props;
    const multiline = !!this.props.multi;
    const selectStyles = {
      dropdownIndicator: (base) => ({
        ...base,
        padding: multiline ? '0 8px' : '8px',
      }),
      clearIndicator: (base) => ({
          ...base,
        padding: multiline ? '0 8px' : '8px',
      }),
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const label = this.props.label || capitalize(this.props.input.name);
    const isError = meta && meta.error && !meta.disabled; //meta.touched &&
    const isCreatable = this.props.isCreatable;
    return (
      <div>
        {/*{isCreatable && (*/}
        {/*<Creatable*/}
        {/*classes={classes}*/}
        {/*styles={selectStyles}*/}
        {/*textFieldProps={{*/}
        {/*required: true,*/}
        {/*label: label,*/}
        {/*error: isError,*/}
        {/*InputLabelProps: {*/}
        {/*shrink: true*/}
        {/*},*/}
        {/*disabled: this.props.disabled*/}
        {/*}}*/}
        {/*options={this.props.options}*/}
        {/*components={components}*/}
        {/*value={this.props.value}*/}
        {/*onChange={this.props.onChange}*/}
        {/*// onBlur={this.props.onBlur}*/}
        {/*placeholder={*/}
        {/*this.props.placeholder ? this.props.placeholder : label*/}
        {/*}*/}
        {/*isMulti={!!this.props.multi}*/}
        {/*isDisabled={this.props.disabled}*/}
        {/*// filterOption={filterOptions}*/}
        {/*isClearable={true}*/}
        {/*/>*/}
        {/*)}*/}
        {!isCreatable && (
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              required: isNull(required) ? false : required,
              label: label,
              error: isError,
              multiline: multiline,
              InputLabelProps: {
                shrink: true,
              },
              disabled: this.props.disabled,
            }}
            options={this.props.options}
            components={components}
            value={this.props.value}
            onChange={this.props.onChange}
            // onBlur={this.props.onBlur}
            placeholder={
              this.props.placeholder ? this.props.placeholder : label
            }
            isMulti={!!this.props.multi}
            isDisabled={this.props.disabled}
            // filterOption={filterOptions}
            isClearable={true}
          />
        )}
        {isError && <span className={classes.danger}>{meta.error}</span>}
      </div>
    );
  }
}

ReactSelectUI.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  SelectWrapper(ReactSelectUI),
);
