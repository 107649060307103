import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  DataTypeProvider,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { createScholarsGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import {
  ButtonScholarsProvider,
  DateProvider,
  NumberEditorBase,
  numberFilterOperations,
} from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';

import withWidth from '@material-ui/core/withWidth';

class TableScholars extends React.PureComponent {
  render() {
    const {
      columnWidths,
      colorColumns,
      columnOrder,
      rows,
      columns,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      totalSummaryItems,
      onFiltersChange,
      onPageSizeChange,
      onColumnOrderChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          <SummaryState totalItems={totalSummaryItems} />
          {/*<SelectionState />*/}

          {/*<GroupingState />*/}
          {/*<IntegratedGrouping />*/}

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          {/*<IntegratedSelection />*/}

          <DragDropProvider />
          <DateProvider for={['LastDate', 'FirstDate']} />
          <ButtonScholarsProvider for={['WalletId']} />
          <DataTypeProvider
            for={['TriselDay', 'TriselWeek', 'CurrentTrisel', 'CurrentCTA', 'CurrentPrana']}
            editorComponent={NumberEditorBase}
            availableFilterOperations={numberFilterOperations}
          />

          <Table />
          {/*<TableSelection showSelectAll={true} />*/}
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableSummaryRow />
          <TableFilterRow showFilterSelector={true} />
          <PagingPanel pageSizes={pageSizes} />
          {/*<TableGroupRow />*/}
          {/*<Toolbar />*/}
          {/*<GroupingPanel showSortingControls={true} />*/}
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { config } = state;
  const { scholarsGrid } = config;
  const { scholars } = props;
  const {
    filters,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
  } = scholarsGrid;
  const codeLang = getActiveCode(state);

  let ret = {
    codeLang: codeLang,
    rows: scholars,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    filters: filters,
    pageSizes: pageSizes,
    sorting: sorting,
    totalSummaryItems: totalSummaryItems,
    columns: [
      { name: 'WalletId', title: 'WalletId' },
      { name: 'Week', title: 'Week' },
      { name: 'LastDate', title: 'LastDate' },
      { name: 'FirstDate', title: 'FirstDate' },
      { name: 'Name', title: 'Name' },
      { name: 'Email', title: 'Email' },
      { name: 'Address', title: 'Address' },
      { name: 'Magic', title: 'Magic' },
      { name: 'UserName', title: 'UserName' },
      { name: 'Login', title: 'Login' },
      { name: 'Description', title: 'Description' },
      { name: 'CurrentEraMmr', title: 'Mmr' },

      {
        name: 'Scholar',
        title: 'Scholar',
        getCellValue: (row) => row.DicWorkers?.Scholar?.map(objet => objet.Name).join(', ') ?? ' ',
      },
      {
        name: 'Owner',
        title: 'Owner',
        getCellValue: (row) => row.DicWorkers?.Owner?.map(objet => objet.Name).join(', ') ?? ' ',
      },
      {
        name: 'Manager',
        title: 'Manager',
        getCellValue: (row) => row.DicWorkers?.Manager?.map(objet => objet.Name).join(', ') ?? ' ',
      },
      {
        name: 'Admin',
        title: 'Admin',
        getCellValue: (row) => row.DicWorkers?.Admin?.map(objet => objet.Name).join(', ') ?? ' ',
      },

      {
        name: 'QuestDay',
        title: 'D. Completed',
        getCellValue: (row) => row.QuestDay?.NbQuestCompleted,
      },
      {
        name: 'QuestDayToClaim',
        title: 'D. ToClaim',
        getCellValue: (row) =>
          row.QuestDay?.NbQuestCompleted - row.QuestDay?.NbQuestClaimed,
      },
      {
        name: 'RewardClaimedDaily',
        title: 'D. Claimed',
        getCellValue: (row) => (row.QuestDay?.RewardClaimed ? 'Yes' : 'No'),
      },
      {
        name: 'QuestWeek',
        title: 'W. Completed',
        getCellValue: (row) => row.QuestWeek?.NbQuestCompleted,
      },
      {
        name: 'QuestWeekToClaim',
        title: 'W. ToClaim',
        getCellValue: (row) =>
          row.QuestWeek?.NbQuestCompleted - row.QuestWeek?.NbQuestClaimed,
      },
      {
        name: 'RewardClaimedWeekly',
        title: 'W. Claimed',
        getCellValue: (row) => (row.QuestWeek?.RewardClaimed ? 'Yes' : 'No'),
      },
      { name: 'TriselDay', title: 'D. Trisel' },
      { name: 'TriselWeek', title: 'W. Trisel' },
      { name: 'CurrentTrisel', title: 'Current Trisel' },
      { name: 'CurrentCTA', title: 'CTA' },
      { name: 'CurrentPrana', title: 'Prana' },
      { name: 'MintPasses', title: 'MintPasses' },
      { name: 'NbAssetsAddress', title: 'NbAssetsAddress' },
      { name: 'NbAssetsMagic', title: 'NbAssetsMagic' },

      {
        name: 'QuestEvent',
        title: 'E. Completed',
        getCellValue: (row) => row.QuestEvent?.NbQuestCompleted,
      },
      {
        name: 'QuestEventToClaim',
        title: 'E. ToClaim',
        getCellValue: (row) =>
          row.QuestEvent?.NbQuestCompleted - row.QuestEvent?.NbQuestClaimed,
      },
      {
        name: 'RewardClaimedEvent',
        title: 'E. Claimed',
        getCellValue: (row) => (row.QuestEvent?.RewardClaimed ? 'Yes' : 'No'),
      },
      {
        name: 'QuestGeneral',
        title: 'G. Completed',
        getCellValue: (row) => row.QuestGeneral?.NbQuestCompleted,
      },
      {
        name: 'QuestGeneralToClaim',
        title: 'G. ToClaim',
        getCellValue: (row) =>
          row.QuestGeneral?.NbQuestCompleted - row.QuestGeneral?.NbQuestClaimed,
      },
      {
        name: 'RewardClaimedGeneral',
        title: 'G. Claimed',
        getCellValue: (row) => (row.QuestGeneral?.RewardClaimed ? 'Yes' : 'No'),
      },
    ],
  };
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(createScholarsGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createScholarsGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createScholarsGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createScholarsGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createScholarsGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createScholarsGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createScholarsGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createScholarsGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createScholarsGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createScholarsGridAction('columnWidths', widths)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableScholars));
