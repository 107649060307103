import React from 'react';
import { push } from 'connected-react-router';
import { Grid } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import { getGroupId } from '../../../redux/onboarding/selector';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { getTranslate, Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeShowDialog,
  sendWhiteLabelText,
} from '../../../redux/onboarding/actions';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

const formName = 'formWhiteLabel';

const WhiteLabelText = ({ translate, groupN }) => {
  const dispatch = useDispatch();
  const error = useSelector(getFormSyncErrors(formName));
  const hasErrors = Object.keys(error).length !== 0;
  const groupId = useSelector(getGroupId);

  return (
    <>
      <Typography variant="h6" align="center">
        <Translate id="onboarding.whiteLabel" />
      </Typography>
      <Card>
        <Grid container>
          <DialogTitle></DialogTitle>

          <GridItem xs={12} sm={12} md={12}>
            <TextField
              defaultValue={translate('onboarding.messageWhiteLabel')}
              disabled={false}
              name="shortDescription"
              label={<Translate id="traders.description" />}
              fullWidth={true}
              minRows={10}
              multiline
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container justifyContent="center">
              <Button
                type="submit"
                onClick={() => {
                  dispatch(sendWhiteLabelText());
                  dispatch(push('/manageFollowers/' + groupId));
                  dispatch(changeShowDialog(false));
                }}
                color="primary"
                round
                disabled={hasErrors}
              >
                <Translate id="confirmation.submitButton" />
              </Button>
            </Grid>
          </GridItem>
        </Grid>
      </Card>
    </>
  );
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.localize),
    groupN: state.onboarding.groupName,
  };
}

export default connect(
  mapStateToProps,
  null,
)(reduxForm({ form: formName })(WhiteLabelText));
