import React from 'react';
import { connect } from 'react-redux';

import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { IsAdmin, postEmails } from '../../../redux/admin/actions';
import Input from '@material-ui/core/Input';
import Button from '../../../components/CustomButtons/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';

class EmailsPage extends React.Component {
  state = {
    emailType: 'newsletter',
    listType: 'test',
    radioChange: 'test',
    showCustom: false,
  };
  componentDidMount() {
    this.props.IsAdminActions();
  }
  listTypeChange = (event) => {
    this.setState({ listType: event.target.value });
  };
  emailTypeChange = (event) => {
    this.setState({ emailType: event.target.value });
  };
  radioChange = (event) => {
    const value = event.target.value;
    if (value !== 'custom') {
      this.setState({ radioChange: value });
      this.setState({ listType: value });
      this.setState({ showCustom: false });
    } else {
      this.setState({ radioChange: value });
      this.setState({
        listType:
          'adrien.bozzano@gmail.com;gaetan8francois@gmail.com;gaetan8francois@hotmail.com',
      });
      this.setState({ showCustom: true });
    }
  };
  postEmails = () => {
    this.props.postEmailsActions({
      listType: this.state.listType,
      emailType: this.state.emailType,
    });
  };
  render() {
    const { showCustom, radioChange } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            label={'Email Type'}
            value={this.state.emailType}
            fullWidth={true}
            onChange={(e) => this.emailTypeChange(e)}
          />
          <br />
          <br />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Liste emails</FormLabel>
            <RadioGroup
              value={radioChange}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              onChange={this.radioChange}
            >
              <FormControlLabel value="test" control={<Radio />} label="Test" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
              <FormControlLabel
                value="newsletterOnly"
                control={<Radio />}
                label="Newsletter"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="Tout le monde"
              />
            </RadioGroup>
          </FormControl>
          {showCustom && (
            <Input
              value={this.state.listType}
              multiline={true}
              fullWidth={true}
              minRows={10}
              onChange={(e) => this.listTypeChange(e)}
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Button onClick={() => this.postEmails()}>Envoyer</Button>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postEmailsActions: bindActionCreators(postEmails, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = connect(null, mapDispatchToProps)(EmailsPage);
export default connectedUsers;
