import React from 'react';
import { getTableExchange } from '../../../helpers/exchange';
import TableUIWallet from '../../../components/Table/TableUIWallet';
import GridItem from '../../../components/Grid/GridItem.jsx';
import { isNull } from '../../SmartTrade/Common';
import Button from '../../../components/CustomButtons/Button';
import { Cached, Clear } from '@material-ui/icons';
import {
  createExchangesGridAction,
  resetExchangesColumns,
} from '../../../redux/config/actions';
import { withLocalize } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import TableUIAssets from '../../Assets/TableUIAssets';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import {
  capitalize,
  listTokenType,
  listTokenTypeImage,
} from '../../../helpers/general';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import { getExchange, getExchanges } from '../../../redux/user/actions';
import SelectedAsset from "../../../components/Table/SelectedAsset";

class TableWallet extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    const search =
      location && location.search ? location.search.toLowerCase() : '';
    let tokenType = 0;
    listTokenTypeImage.forEach((item, index) => {
      if (search.indexOf(item) !== -1) tokenType = index;
    });
    this.state = {
      tokenType: tokenType,
      nbDays: 30,
    };
  }
  handleChange = (event, newValue) => {
    this.setState({ ...this.state, tokenType: newValue });
  };
  handleChangeNbDays = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { exchange, exchangesGrid } = this.props;
    const { tokenType } = this.state;
    if (isNull(exchange)) return <div></div>;
    // const tokenTypeStr = capitalize(
    //     listTokenType[tokenType].toLowerCase().replaceAll('_', ' '),
    // );
    const table = getTableExchange(exchange, tokenType);
    const title = exchange.Name;
    const grid = exchangesGrid[tokenType];
    const selection = grid ? grid.selection : [];
    const filters = grid ? grid.filters : [];
    // const selectFiltred = exchangesGrid ? exchangesGrid[tokenType].filter((item) => !isNull(item)) : null;
    // console.log(selectFiltred);
    // const selectFiltred = selected.filter((item) => !isNull(item));
    // const count = selectFiltred.map((item) => item?.Count ?? 0).reduce((a, b) => a + b, 0);
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3 style={{ textAlign: 'center' }}>
          <b>{title}</b>
        </h3>
        <GridItem
          container
          xs={12}
          sm={8}
          md={8}
          alignItems="center"
          justifyContent="flex-start"
        >
          <div>
            <span>
              <Button
                onClick={() => {
                  localStorage.removeItem('exchangesGrid');
                  this.props.resetExchangesColumnsActions();
                }}
                size="sm"
              >
                <Cached />
                &nbsp; Reset Table
              </Button>
              <Button
                onClick={() => {
                  this.props.clearFilterExchangesActions(this.state.tokenType);
                }}
                size="sm"
              >
                <Clear />
                &nbsp; Reset filter ( {filters.length} )
              </Button>
              {/*<Button*/}
              {/*      onClick={() => {*/}
              {/*        this.props.clearSelectionExchangesActions(this.state.tokenType);*/}
              {/*      }}*/}
              {/*      size="sm"*/}
              {/*  >*/}
              {/*  <Clear />*/}
              {/*  &nbsp; Reset Selection ( {selection.length} )*/}
              {/*</Button>*/}
              {/*<SelectedAsset tokenType={tokenType} />*/}
              <TextField
                id="nbDays"
                name="nbDays"
                label="Number of Days"
                value={this.state.nbDays}
                type="number"
                onChange={(e) => this.handleChangeNbDays(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                onClick={() => {
                  if (Array.isArray(exchange))
                    this.props.getExchangesActions(
                      true,
                      false,
                      this.state.nbDays,
                    );
                  else
                    this.props.getExchangeActions(
                      exchange.WalletId,
                      true,
                      false,
                      this.state.nbDays,
                    );
                }}
              >
                OK
              </Button>
            </span>
          </div>
        </GridItem>
        <CustomTabs
          value={this.state.value}
          title=""
          onChange={this.handleChange}
          headerColor="primary"
          tabs={listTokenType.map((name, index) => {
            // const grid = exchangesGrid[index];
            // const selected = exchangesGrid ? grid.selected : [];
            // const selectFiltred = selected.filter((item) => !isNull(item));
            // const nbAssets = selectFiltred.map((item) => item?.Count ?? 0).reduce((a, b) => a + b, 0);
            const tabName = name.replaceAll('_', ' ');
            return {
            tabName: tabName,// + ' ( ' + nbAssets + ' )',
            tabContent: <TableUIWallet tokenType={index} exchange={table} />,
          }})}
        />
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;
  const { exchangesGrid } = config;
  return {
    exchangesGrid
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
    getExchangeActions: bindActionCreators(getExchange, dispatch),
    resetExchangesColumnsActions: () => dispatch(resetExchangesColumns()),
    clearFilterExchangesActions: (tokenType) =>
      dispatch(createExchangesGridAction(tokenType,'filters', [])),
    clearSelectionExchangesActions: (tokenType) => {
        dispatch(createExchangesGridAction(tokenType,'selection', []));
        dispatch(createExchangesGridAction(tokenType,'selected', []));
    }
  };
};

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(TableWallet));
