import React from 'react';
import common from '../../assets/img/icon-rarity-card/common.png';
import uncommon from '../../assets/img/icon-rarity-card/uncommon.png';
import rare from '../../assets/img/icon-rarity-card/rare.png';
import ultrarare from '../../assets/img/icon-rarity-card/ultrarare.png';
import specialrare from '../../assets/img/icon-rarity-card/specialrare.png';
import mythic from '../../assets/img/icon-rarity-card/mythic.png';
import exclusive from '../../assets/img/icon-rarity-card/exclusive.png';
import unique from '../../assets/img/icon-rarity-card/unique.png';
import foil from '../../assets/img/icon-rarity-card/foil.png';
import nonfoil from '../../assets/img/icon-rarity-card/non-foil.png';
import { isEmpty, isNull } from '../../views/SmartTrade/Common';
import { capitalize } from '../../helpers/general';

export default function CardRarity(props) {
  let { withText, value } = props;
  let name = value;
  if (isEmpty(name)) return <></>;
  name = name.toLowerCase();
  withText = isNull(withText) ? false : withText;
  const rarity = {
    common: common,
    uncommon: uncommon,
    rare: rare,
    ultrarare: ultrarare,
    specialrare: specialrare,
    mythic: mythic,
    exclusive: exclusive,
    unique: unique,
    foil: foil,
    nonfoil: nonfoil,
  };
  return (
    <>
      <img width={30} height={30} src={rarity[name]} alt={capitalize(name)} />
      &nbsp;
      {withText && <b>{capitalize(name)}</b>}
    </>
  );
}
