import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getAccountsGroup } from '../../../redux/admin/actions';

import { withLocalize } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button';
import ExchangeContainer from '../../Accounts/ExchangeContainer';

class ShowAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccounts: false,
    };
  }

  render() {
    const { match, accounts, showButton } = this.props;
    const { showAccounts } = this.state;
    const groupId = match.params.id;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Button
          color={'primary'}
          onClick={() => {
            if (!showAccounts) {
              this.props.getAccountsGroupActions(groupId);
            }
            this.setState({ showAccounts: !showAccounts });
          }}
        >
          ShowAccount
        </Button>
        {showAccounts && (
          <GridContainer>
            {accounts &&
              accounts.map((account) => {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    key={account.Id}
                  >
                    <ExchangeContainer
                      exchange={account}
                      showButton={showButton}
                      isAdmin={true}
                    />
                  </GridItem>
                );
              })}
          </GridContainer>
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    accounts: admin.accountsGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountsGroupActions: bindActionCreators(getAccountsGroup, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ShowAccounts),
);
