import React from 'react';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';

import { bindActionCreators } from 'redux';
import { getOpenOrdersGroup, getPositions } from '../../../redux/admin/actions';

import { withLocalize } from 'react-localize-redux';
import Button from '../../../components/CustomButtons/Button';
import { pathNameSelector } from '../../SmartTrade/Common';

class ShowPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPositions: false,
    };
  }

  render() {
    const { match, ordersGroup, pathName } = this.props;
    const { showPositions } = this.state;
    const param = match.params.id;
    console.log(ordersGroup[0]);
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Button
          color={'primary'}
          onClick={() => {
            if (!showPositions) {
              if (pathName.indexOf('statGroup') !== -1)
                this.props.getOpenOrdersGroupActions(param);
              else this.props.getPositionsActions(param);
            }
            this.setState({ showPositions: !showPositions });
          }}
        >
          ShowPosition
        </Button>
        {showPositions && (
          <GridContainer>
            {ordersGroup && (
              <table>
                <tr>
                  <th>Id</th>
                  <th>WalletId</th>
                  <th>EntryPrice</th>
                  <th>Leverage</th>
                  <th>Pair</th>
                  <th>Quantity</th>
                </tr>
                {ordersGroup.map((order) => {
                  return (
                    <div key={order.Id}>
                      {order.Positions &&
                        order.Positions.map((position) => {
                          return (
                            <tr key={position.PositionId}>
                              <td>{position.PositionId}</td>
                              <td>{position.WalletId}</td>
                              <td>{position.EntryPrice}</td>
                              <td>{position.Leverage}</td>
                              <td>{position.Pair}</td>
                              <td>{position.Quantity}</td>
                            </tr>
                          );
                        })}
                    </div>
                  );
                })}
              </table>
            )}
          </GridContainer>
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    ordersGroup: admin.ordersGroup,
    pathName: pathNameSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOpenOrdersGroupActions: bindActionCreators(getOpenOrdersGroup, dispatch),
    getPositionsActions: bindActionCreators(getPositions, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ShowPosition),
);
