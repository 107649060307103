import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import FormExtraTokens from './FormExtra';
import transExternals from '../../../locales/externals';
import { Translate, withLocalize } from 'react-localize-redux';
import { isNull } from '../../SmartTrade/Common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getExchange } from '../../../redux/user/actions';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class ExtraTokensPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExternals);
  }

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    if (!isNull(id)) this.props.getExchangeActions(id);
  }

  render() {
    const { classes, match, exchanges } = this.props;

    let { exchange } = this.props;
    const paramId = parseInt(match.params.id);
    if (isNull(exchange) || exchange.Id !== paramId)
      exchange = !isNull(exchanges)
        ? exchanges.find((x) => x.Id === paramId)
        : null;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  <Translate id="externals.addExternal" />
                </h4>
              </CardHeader>
              <CardBody>
                <FormExtraTokens exchange={exchange} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ExtraTokensPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    exchanges: user.exchanges,
    exchange: user.exchange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExchangeActions: bindActionCreators(getExchange, dispatch),
  };
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(ExtraTokensPage)),
);
