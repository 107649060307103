import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  DataTypeProvider,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  PagingPanel,
  TableSummaryRow,

} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import {
  createCardsGridAction,
} from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import {
  ArkhomeProvider,
  ImageAndLinksProvider,
  ColorPourcentageProvider,
  DollarProvider,
  NumberEditorBase,
  numberFilterOperations,
  PourcentageProvider,
  RarityProvider,
  DateProvider,
} from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';
import withWidth from '@material-ui/core/withWidth';

import { detailSelector } from '../../redux/card/selector';
import {
  getColumnsAssets,
  getColumnsCard,
  getColumnsMintPass,
  getColumnsSouvenirCard,
} from '../../helpers/card';
import {parseToFloat} from "../SmartTrade/Common";

class TableUIAssets extends React.PureComponent {
  render() {
    const {
      columnWidths,
      colorColumns,
      columnOrder,
      rows,
      columns,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      totalSummaryItems,
      onFiltersChange,
      onPageSizeChange,
      onColumnOrderChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          <SummaryState totalItems={totalSummaryItems} />
          {/*<SelectionState />*/}

          {/*<GroupingState />*/}
          {/*<IntegratedGrouping />*/}

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          {/*<IntegratedSelection />*/}

          <DragDropProvider />
          <ImageAndLinksProvider for={['Image']} />
          <ArkhomeProvider for={['Arkhome']} />
          <RarityProvider for={['Rarity']} />
          <PourcentageProvider for={['RatioSupply', 'Ratio']} />
          <DateProvider for={['UpdatedAt']} />
          <DollarProvider
            for={[
              'ValueUSD',
              'Avg',
              'Min',
              'Max',
              'AvgOther',
              'MinOther',
              'MaxOther',
              'TriselPrice',
            ]}
          />
          <DataTypeProvider
            for={[
              'CardId',
              'ValueUSD',
              'Amount',
              'Rank',
              'AnimationLevel',
              'Power',
            ]}
            editorComponent={NumberEditorBase}
            availableFilterOperations={numberFilterOperations}
          />

          <Table />
          {/*<TableSelection showSelectAll={true} />*/}
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableSummaryRow />
          <TableFilterRow showFilterSelector={true} />
          <PagingPanel pageSizes={pageSizes} />
          {/*<TableGroupRow />*/}
          {/*<Toolbar />*/}
          {/*<GroupingPanel showSortingControls={true} />*/}
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { tokenType, triselPrice } = props;
  const { config } = state;
  const { cardsGrid } = config;
  const detail = detailSelector(state);
  const {
    filters,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
  } = cardsGrid;
  const codeLang = getActiveCode(state);
  const assets = props.assets ? props.assets : [];
  let columns = [];
  if (tokenType === 0) columns = getColumnsCard((row) => row.Asset, 'fr', true, triselPrice);
  else if (tokenType === 1) columns = getColumnsMintPass((row) => row.Asset);
  else if (tokenType === 2) columns = getColumnsAssets((row) => row.Asset);
  else if (tokenType === 3)
    columns = getColumnsSouvenirCard((row) => row.Asset);
  else columns = getColumnsAssets((row) => row.Asset);
  if (!detail) {
    columns = columns.concat([
      { name: 'Avg', title: 'Avg' },
      { name: 'Min', title: 'Min' },
      { name: 'Max', title: 'Max' },
      { name: 'Count', title: 'Count' },
    ]);
  }
  if (tokenType === 1) {
    columns = columns.concat([
      {
        name: 'CTAPrice',
        title: 'CTAPrice',
        getCellValue: (row) => {
          const stage = row.Asset?.Stage ?? 0;
          const price = row.Min ?? 0;
          const name = row.Asset?.Name?.substring(("Charged ".length));
          const assetMPNoCharged = assets.filter(x => x.Asset?.Name === name && x.Asset?.Stage === 0);
          const priceAsset = assetMPNoCharged[0]?.Min ?? 0;
          const priceCta = price- priceAsset;
          return parseToFloat((priceCta / 1500 * stage), 0.01);
        },
      }
    ]);
  }

  let ret = {
    codeLang: codeLang,
    rows: assets,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    filters: filters,
    pageSizes: pageSizes,
    sorting: sorting,
    totalSummaryItems: totalSummaryItems,
    columns: columns.concat([
      // {
      //   name: 'State',
      //   title: 'State',
      //   getCellValue: (row) => {
      //     if (onSell) {
      //       if (row.Min < row.MinOther) return '< Min';
      //       if (row.Min < row.AvgOther) return '< Avg';
      //     } else {
      //       if (row.CountOther > 0) {
      //         if (row.Min > row.MinOther) return '< Min';
      //         if (row.Min > row.AvgOther) return '< Avg';
      //       }
      //     }
      //     return '';
      //   },
      // },

      { name: 'Amount', title: 'Amount' },
      { name: 'Currency', title: 'Currency' },
      // { name: 'CostByTriselUsed', title: 'CostByTriselUsed for 1000' },
      { name: 'ValueUSD', title: 'ValueUSD' },

      //
      // { name: 'AvgOther', title: 'Avg ' + (!onSell ? 'On Sell' : 'Sold') },
      // { name: 'MinOther', title: 'Min ' + (!onSell ? 'On Sell' : 'Sold') },
      // { name: 'MaxOther', title: 'Max ' + (!onSell ? 'On Sell' : 'Sold') },
      // { name: 'CountOther', title: 'Count ' + (!onSell ? 'On Sell' : 'Sold') },
      //
      // { name: 'FoilCount', title: 'FoilCount' },
      // {
      //   name: 'Link',
      //   title: 'Link',
      //   getCellValue: (row) => (
      //     <a target="_blank" href={row.Link}>
      //       view on imx
      //     </a>
      //   ),
      // },
      // { name: 'BasePower', title: 'BasePower' },
      // { name: 'MaxPower', title: 'MaxPower' },
      // { name: 'NbTriselToAwakening', title: 'ToAwakening' },
      // {
      //   name: 'NbTriselToIncreasePotentialToMax',
      //   title: 'ToIncreasePotentialToMax',
      // },
      // { name: 'NbTriselTotal', title: 'Total' },
    ]),
  };
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(createCardsGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createCardsGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createCardsGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createCardsGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createCardsGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createCardsGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createCardsGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createCardsGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createCardsGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createCardsGridAction('columnWidths', widths)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableUIAssets));
