import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import { Translate, withLocalize, getTranslate } from 'react-localize-redux';
import transFAQ from '../../locales/faq.json';
import MetaTags from 'react-meta-tags';
import GridItem from '../../components/Grid/GridItem';
import { getActiveCode, getTabLanguages } from '../../helpers/localize';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { linkStyle } from '../../assets/jss/material-dashboard-react/components/linkStyle';

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transFAQ);
  }

  render() {
    const {
      translate,
      translations,
      match,
      setActiveLanguage,
      getActiveLanguage,
      classes,
      theme,
    } = this.props;
    let i = 0;
    let array = [];
    let response = -1;
    const idResp = match.params.id;
    const tabLanguages = getTabLanguages();
    while (Object.hasOwn(translations, 'faq.q' + i + '.question')) {
      let idLang = 0;
      for (const language of tabLanguages) {
        const codeLang = language.code;
        if (idResp && idResp === translations['faq.q' + i + '.url'][idLang]) {
          if (getActiveLanguage !== codeLang) setActiveLanguage(codeLang);
          response = i;
        }
        ++idLang;
      }
      array.push(i);
      ++i;
    }
    let responseFaq = 'faq.q' + response + '.';
    return (
      <GridContainer>
        <GridItem md={12}>
          {response !== -1 && (
            <MetaTags>
              <title>{translate(responseFaq + 'title')}</title>
              <meta
                name="description"
                content={translate(responseFaq + 'description')}
              />
              <meta
                property="og:title"
                content={translate(responseFaq + 'title')}
              />
              <meta
                property="og:url"
                content={translate(responseFaq + 'url')}
              />
              {/*<meta property="og:image" content={logo} />*/}
            </MetaTags>
          )}
          {response !== -1 && (
            <GridItem>
              <h1>
                <Translate id={responseFaq + 'question'} />
              </h1>
              <h4>
                <Translate
                  id={responseFaq + 'answer'}
                  options={{ renderInnerHtml: true }}
                  data={{
                    classLink:
                      'link-text ' + (theme === 'dark' ? 'dark' : 'light'),
                  }}
                />
              </h4>
            </GridItem>
          )}
          <GridContainer>
            {array &&
              array.map((id) => {
                const b = 'faq.q' + id + '.';
                return (
                  <GridItem
                    style={{ marginBottom: '15px' }}
                    xs={12}
                    sm={12}
                    md={6}
                    key={id}
                  >
                    <Link
                      className={classes.link}
                      to={'/faq/' + translate(b + 'url')}
                    >
                      <ArrowRight fontSize={'inherit'} />
                      <Translate id={b + 'question'} />
                    </Link>
                  </GridItem>
                );
              })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

FAQPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { localize, config } = state;
  return {
    theme: config.theme,
    translate: getTranslate(localize),
    getActiveLanguage: getActiveCode(state),
    translations: localize.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const connectedExchanges = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FAQPage);
// export { connectedApp as App };
export default withLocalize(withStyles(linkStyle)(connectedExchanges));

// export default withStyles(styles)(UserProfile);
