import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import { withLocalize } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { eWorkerType } from '../../helpers/general';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import { bindActionCreators } from 'redux';
import { getWorkers, resetWorker } from '../../redux/worker/actions';
import { workersSelector } from '../../redux/worker/selector';
import { isNull } from '../SmartTrade/Common';
import TableWorkers from './TableWorkers';
import Button from '../../components/CustomButtons/Button';
import { Cached } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import transConfirm from '../../locales/confirmation.json';
import { isAdminSelector } from '../../redux/authentication/selector';

class ManageWorkerPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    const search = location.search ? location.search.toLowerCase() : '';
    let value = 0;
    eWorkerType.slice(1).forEach((item, index) => {
      if (search.indexOf(item.toLowerCase()) !== -1) value = index;
    });
    this.state = {
      workerType: value,
    };
    this.props.addTranslation(transConfirm);
  }

  componentDidMount() {
    this.props.getWorkersActions();
    this.props.resetWorkerActions();
  }
  handleChange = (event, newValue) => {
    this.setState({ workerType: newValue });
    // this.props.getWorkersActions(newValue);
  };
  render() {
    const { workers, isAdmin } = this.props;
    if (isNull(workers)) return <div>Loading...</div>;
    const workerType = this.state.workerType + 1;
    const filteredWorkers = workers.filter(
      (item) => item.WorkerType === workerType,
    );
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Button
          to={'/worker?workerType=' + workerType}
          color="primary"
          component={Link}
        >
          Add Worker
        </Button>
        <CustomTabs
          value={this.state.workerType}
          title=""
          onChange={this.handleChange}
          headerColor="primary"
          tabs={eWorkerType
            .slice(1, eWorkerType.length - (isAdmin ? 0 : 1))
            .map((name, index) => ({
              tabName: name.replaceAll('_', ' '),
              tabContent: (
                <TableWorkers
                  workerType={index + 1}
                  workers={filteredWorkers}
                />
              ),
            }))}
        />
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    workers: workersSelector(state),
    isAdmin: isAdminSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkersActions: bindActionCreators(getWorkers, dispatch),
    resetWorkerActions: bindActionCreators(resetWorker, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ManageWorkerPage),
);
