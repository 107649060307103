import { exchangeConstants } from './constants';
import { authenticationConstants } from '../authentication/constants';
import { isNull } from '../../views/SmartTrade/Common';
import { getExchangeStr } from '../../helpers/order';

const exchanges = JSON.parse(localStorage.getItem('exchanges'));
const initialState = { exchanges, exchange: null, scholars: [], scholar: null, transferPopup: false };

export function user(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGOUT:
      return {};
    case exchangeConstants.ACTION_GET_SCHOLARS:
      return {
        ...state,
        scholars: action.payload,
      };
    case exchangeConstants.ACTION_GET_SCHOLAR:
      return {
        ...state,
        scholar: action.payload,
      };
    case exchangeConstants.ACTION_GET_WALLETS:
    case exchangeConstants.ACTION_LOCAL_WALLETS:
      return {
        ...state,
        exchanges: action.payload,
      };
    case exchangeConstants.TRANSFER_POPUP:
      return {
        ...state,
        transferPopup: action.payload,
      };
    case exchangeConstants.ACTION_RESET_WALLET:
      return {
        ...state,
        exchange: null,
      };
    case exchangeConstants.ACTION_GET_WALLET:
    case exchangeConstants.ACTION_PUT_WALLET: {
      const exchange = action.payload;
      for (const key in exchange.DicWorkers) {
        const val = exchange.DicWorkers[key];
        exchange[key] = val;
        const workersSelect = [];
        for (const worker of val) {
          workersSelect.push({ value: worker.WorkerId, label: worker.Name });
        }
        exchange[key.toLowerCase()] = workersSelect;
      }
      if (isNull(state.exchanges)) {
        return {
          ...state,
          exchange: exchange,
          exchanges: [exchange],
        };
      }
      const index = state.exchanges.findIndex(
        (x) => x.WalletId === exchange.WalletId,
      );
      if (index !== -1) state.exchanges[index] = exchange;
      else state.exchanges.push(exchange);
      return {
        ...state,
        exchange: exchange,
        exchanges: [...state.exchanges],
      };
    }
    case exchangeConstants.ACTION_POST_WALLET:
      return {
        ...state,
        exchanges: state.exchanges.concat(action.payload),
      };
    case exchangeConstants.ACTION_DELETE_WALLET:
      return {
        ...state,
        exchanges: state.exchanges.filter(
          (x) => x.WalletId !== action.payload.WalletId,
        ),
      };
    default:
      return state;
  }
}
