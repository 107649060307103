import React from 'react';
import {isEmpty, parseToFloat} from "../SmartTrade/Common";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {listRarity} from "../../helpers/general";
import {TableContainer} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Hover from "../../components/Hover/Hover";
import {useSelector} from "react-redux";
import {themeSelector} from "../../redux/config/selector";

export default function TableAssets({assets, elem}) {
    const theme = useSelector(themeSelector);

    let valeursMinimalesParGrade = {};
    assets.forEach(function(objet) {
        const grade = isEmpty(objet.Asset.Grade) || objet.Asset.Grade === ' ' ?
            objet.Asset.Rank : objet.Asset.Grade;
        const valueUSD = parseToFloat(objet[elem], 0.01);
        const foil = objet.Asset.Foil;
        const rarity = objet.Asset.Rarity;
        const key = grade + "-" + rarity + "-" + foil;

        if (!valeursMinimalesParGrade[key])
            valeursMinimalesParGrade[key] = [];
        valeursMinimalesParGrade[key].push(objet);
    });
    const grades = ['1', '2', '3', '4', '5', 'C', 'B', 'A', 'S'];
    const showLine = (foil, theme) => {
        return listRarity.map((rarity, index) => {
            return (<TableRow key={index}>
                <TableCell>{rarity} {foil ? ' Foil' : ''}</TableCell>
                {grades.map((grade, i) => {
                    const vals = valeursMinimalesParGrade[grade+'-'+rarity+'-'+(foil ? 1 : 0)];
                    if (!vals) return <TableCell key={i}></TableCell>;
                    const nbElem = vals.map((item) => item?.Count ?? 0).reduce((a, b) => a + b, 0);
                    const lowestValue = vals.reduce((min, obj) => obj.ValueUSD < min.ValueUSD ? obj : min, vals[0]);
                    const asset = lowestValue.Asset;
                    const sumAvg = vals.reduce((total, objet) => total + (objet.Avg * objet.Count), 0);
                    const sumCount = vals.reduce((total, objet) => total + objet.Count, 0)
                    const avg = sumAvg / sumCount;

                    const val = elem === 'Avg' ?
                        parseToFloat(avg, 0.01) :
                        parseToFloat(lowestValue.ValueUSD, 0.01);

                    return <TableCell key={i}>
                        <Hover
                            keepShowingIfClicked={false}
                            trigger={
                                <Link
                                    href={`https://crosstheages.com/portal/asset-details/${asset.TokenId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={'link-text ' + (theme === 'dark' ? 'dark' : 'light')}>
                                        {val} {!isEmpty(val) ? '$' : ''} ( {nbElem} )
                                    </div>
                                </Link>
                            }
                            hover={
                                <div>
                                    <img src={asset.Image} width={200} alt={asset.Name} />
                                </div>
                            }
                            minWidthHover="33%"
                        />

                    </TableCell>;
                })}
            </TableRow>);
        })
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Rarity {elem}</TableCell>
                            {grades.map((grade, index) => {
                                return <TableCell key={index}>{grade}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showLine(false, theme)}
                        {showLine(true, theme)}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
  )
};