import {tradingConstants} from './constants';

const initialState = {
  buys: [],
};

export function trading(state = initialState, action) {
  switch (action.type) {

    case tradingConstants.ACTION_GET_BUYS:
      return {
        ...state,
        cards: action.payload,
      };

    default:
      return state;
  }
}
