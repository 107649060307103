import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withLocalize } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import {exchangesSelector, transferPopupSelector} from "../../../redux/user/selector";
import {getExchanges, hideTransferPopup} from "../../../redux/user/actions";
import {getArrayFromStr, isEmpty, isNull} from "../../SmartTrade/Common";
import TableUIWallet from "../../../components/Table/TableUIWallet";
import {getTableExchange} from "../../../helpers/exchange";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {listTokenType, showAddress} from "../../../helpers/general";
import ReactSelect from "../../../components/Form/ReactSelectUI";
import TextField from "@material-ui/core/TextField";
import {createExchangesGridAction} from "../../../redux/config/actions";
import {configConstants} from "../../../redux/config/constants";
import {walletConnectionSelector} from "../../../redux/imx/selector";
import {
    CTA_ADDRESS,
    getIMXClient,
    getLinkClient,
    getWalletConnection, getWalletEmail,
    getWalletType,
    WALLET_TYPE_MAGIC
} from "../../../redux/imx/actions";
import CopyButton from "../../../components/CustomButtons/CopyButton";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class DialogTransferPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        const options = this.getOptions(props.exchanges);
        const nbSelect = props.assets.length;
        // this.props.addTranslation(transConfirm);
        this.selectionChange(nbSelect);
        this.state = {
            sendIsLock: false,
            options: options,
            exchangeSelected: [options[0]],
            nbAssetByAccount: 1,
            nbSelect: nbSelect,
            address: null
        };
    }

    componentDidMount() {
        const { getExchangesActions, exchanges } = this.props;

        if (exchanges.length > 1) return;
        getExchangesActions(false);
    }

    handleClose = () => {
        const { hideTransferPopupActions } = this.props;

        hideTransferPopupActions();
        const { onClose } = this.props;

        if (!isNull(onClose)) onClose();
    };

    getOptions = (exchanges) => {
        let options = exchanges.map((item) => ({label: item.Name + ' MagicLink ' + showAddress(item.MagicLink), value: item.MagicLink}))
            .concat(exchanges.map((item) => ({label: item.Name + ' Address ' + showAddress(item.Address), value: item.Address})));
        options = options.sort((a, b) => a.label.localeCompare(b.label));
        options = options.filter(x => !isEmpty(x.value));
        return options;
    };

    getTo = () => {
        const { exchangeSelected, address } = this.state;

        return isEmpty(address) ? exchangeSelected?.map(x => x.value) : getArrayFromStr(address);
    }

    getBatchTransfer = (assets) => {
        const { nbAssetByAccount } = this.state;
        const toAddresses = this.getTo();
        if (isNull(toAddresses) || toAddresses.length === 0)
            return [];
        const batchTransfer = assets.map((asset, index) => {
            const toAddressesIndex = toAddresses.length === 1 ? 0 : ((index / nbAssetByAccount) | 0);
            const address = toAddresses[toAddressesIndex];
            if (isEmpty(address)) {
                console.log('address is empty index: ' + toAddressesIndex);
                return null;
            }
            if (isNull(asset) && asset.hasOwn('TokenId')) {
                console.log('TokenId is null: ', asset);
                return null;
            }
            return {
                type: 'ERC721',
                toAddress: address,
                receiver: address,
                tokenId: asset.TokenId.toString(),
                tokenAddress: CTA_ADDRESS,
                nbAssetByAccount: 1
            };
        }).filter(asset => asset != null);

        console.log(batchTransfer);
        return batchTransfer;
    }

    transfer = async (assets) => {
        const { tokenType } = this.state;
        const walletType = getWalletType();
        const batchTransfer = this.getBatchTransfer(assets);

        if (batchTransfer.length > 0) {
            try{
                // Call the method
                let result;
                if (walletType === WALLET_TYPE_MAGIC) {
                    result = await getIMXClient().batchNftTransfer(await getWalletConnection(getWalletEmail()), batchTransfer);
                    console.log(result.transfer_ids.length + ' assets transferred');
                }
                else {
                    result = await getLinkClient().batchNftTransfer(batchTransfer);
                    console.log(result.result.length + ' assets transferred');
                }
                //TODO: baisse count of assets if <= 0 remove element
                this.props.clearSelectionExchangesActions(tokenType);

                //Confirmation
                //foreach toAddresses + nbAsset dans batchTransfer de ses address

                this.handleClose();
                // Print the result
            }catch(error){
                // Catch and print out the error
                console.error(error)
                // transfer insufficient vault balance error: [0,1]
            }
        }
    }

    selectionChange = (nbSelect) => {
        this.props.onSelectionChangeActions(Array.from({length: nbSelect}, (_, i) => i));
    }
    handleChangeNbSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    between = (min, max, value) => {
      if (min !== '' && parseFloat(value) < parseFloat(min)) return min;
      if (max !== '' && parseFloat(value) > parseFloat(max)) return max;
      return value;
    };

    render() {
        const { selected, transferPopup, assets, tokenType, exchanges } = this.props;
        const { exchangeSelected, nbSelect, address, nbAssetByAccount } = this.state;
        const table = getTableExchange({ AssetsDto: assets }, tokenType);
        const options = this.getOptions(exchanges);
        const nbTo = this.getTo()?.length ?? 0;
        const batchTransfer = this.getBatchTransfer(selected);
        return (
            <Dialog
                open={transferPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'lg'}
                // fullScreen={true}
            >
                <DialogTitle>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={this.handleClose}
                        aria-label="close"
                        style={{ position: 'absolute',
                            right: 12,
                            top: 0}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {listTokenType[tokenType]}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <ReactSelect
                                                multi={true}
                                                label={'Destinations Addresses'}
                                                input={{
                                                    value: exchangeSelected,
                                                    onChange: (v) => this.setState({exchangeSelected: v})
                                                }}
                                                options={options}
                                                disabled={!isEmpty(address)}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <TextField
                                                id="address"
                                                name="address"
                                                size={'small'}
                                                label="Custom address"
                                                fullWidth={true}
                                                value={address}
                                                multiline={true}
                                                minRows={2}
                                                // normalizeOnBlur={value => this.between(0, assets.length, value)}
                                                onChange={(e) => this.setState({address: e.target.value})}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={2}>
                                            <Button size="sm" onClick={() => this.transfer(selected)}>Transfer ({batchTransfer.length}) to ({nbTo})</Button>
                                            <CopyButton style={{float: 'right', marginTop: '10px'}} copyContent={() => batchTransfer.map((item) => item.tokenId).join(',')} />
                                            {nbTo > 1 && (
                                                <TextField
                                                    id="nbAssetByAccount"
                                                    name="nbAssetByAccount"
                                                    label="Nb asset by account"
                                                    value={nbAssetByAccount}
                                                    type="number"
                                                    // normalizeOnBlur={value => this.between(0, assets.length, value)}
                                                    onChange={(e) => this.setState({nbAssetByAccount: e.target.value})}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />)}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <TextField
                                                id="nbSelect"
                                                name="nbSelect"
                                                label="Number to Select"
                                                value={nbSelect}
                                                type="number"
                                                // normalizeOnBlur={value => this.between(0, assets.length, value)}
                                                onChange={(e) => this.handleChangeNbSelect(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <Button onClick={() => {
                                                this.selectionChange(nbSelect);
                                            }}>OK</Button>
                                            <CopyButton copyContent={() => selected.map((item) => item.TokenId).join(',')} />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <TableUIWallet tokenType={tokenType} exchange={table} withInfoPrice={false} type={'transfer'} />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { tokenType } = props;
    const { config } = state;
    const { transfersGrid } = config;
    const { selected } = transfersGrid[tokenType];
    return {
        transferPopup: transferPopupSelector(state),
        exchanges: exchangesSelector(state),
        walletConnection: walletConnectionSelector(state),
        selected
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearSelectionExchangesActions: (tokenType) => {
            dispatch(createExchangesGridAction(tokenType,'selection', []));
            dispatch(createExchangesGridAction(tokenType,'selected', []));
        },
        onSelectionChangeActions: (selection) => {
            const tokenType = ownProps.tokenType;
            const assets = ownProps.assets;
            const table = getTableExchange({ Assets: assets }, tokenType);
            const selected = selection.map((s) => table[s]);
            dispatch(createExchangesGridAction(tokenType, 'selected', selected, configConstants.TRANSFER_GRID_STATE_CHANGE_ACTION));
            dispatch(createExchangesGridAction(tokenType, 'selection', selection, configConstants.TRANSFER_GRID_STATE_CHANGE_ACTION));
        },
        getExchangesActions: bindActionCreators(getExchanges, dispatch),
        hideTransferPopupActions: bindActionCreators(
            hideTransferPopup,
            dispatch,
        ),
    };
};

export default withLocalize(
    connect(mapStateToProps, mapDispatchToProps)(DialogTransferPopup),
);
