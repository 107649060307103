import React, {useEffect, useState} from 'react'
import { hooks, metaMask } from './connectors/metaMask'
import Grid from "@material-ui/core/Grid";
import Button from "../CustomButtons/Button";
import {ImmutableXClient, Link} from "@imtbl/imx-sdk";
import {useSelector} from "react-redux";
import {isNull, isEmpty} from "../../views/SmartTrade/Common";
import {exchangesSelector} from "../../redux/user/selector";
import {showAddress} from "../../helpers/general";
import {
    getWalletAddress,
    getWalletConnection, getWalletEmail,
    getWalletType,
    logoutMagic, removeWallet, setWallet,
    WALLET_ADDRESS, WALLET_EMAIL,
    WALLET_TYPE_LINK, WALLET_TYPE_MAGIC
} from "../../redux/imx/actions";
import TextField from "@material-ui/core/TextField";

const { useAccounts /*, useChainId,  useIsActivating, useIsActive, useProvider, useENSNames */ } = hooks

const ENDPOINTS = {
  mainnet: {
    LINK: 'https://link.x.immutable.com',
    PUBLIC_API: 'https://api.x.immutable.com/v1',
  },
  ropsten: {
    LINK: 'https://link.ropsten.x.immutable.com',
    PUBLIC_API: 'https://api.ropsten.x.immutable.com/v1',
  },
};
const environment = 'mainnet';

export default function MetaMaskCard() {

    let [address, setLocalStorageAddress] = useState(getWalletAddress());
    const [magicEmail, setMagicEmail] = useState('');

    // let [selectedWallet, setSelectedWallet] = useState(getSelectedWallet());
    // const [exchangeSelected, setExchangeSelected] = useState(null);

    const walletType = getWalletType();
    const walletEmail = getWalletEmail();
    const accounts = useAccounts()
    if (walletType === WALLET_TYPE_LINK && accounts && accounts.length > 0) {
        const newAddress = accounts[0].toLowerCase();
        if (newAddress !== address) localStorage.setItem(WALLET_ADDRESS, newAddress.toLowerCase());
        address = newAddress;
    }

    // attempt to connect eagerly on mount
    useEffect(() => {
        void metaMask.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to metamask')
        })
    }, [])
    useEffect(() => {
        const handleLocalStorageChange = (e) => {
            if (e.type === WALLET_ADDRESS) {
                setLocalStorageAddress(getWalletAddress());
            }
        };

        window.addEventListener(WALLET_ADDRESS, handleLocalStorageChange);

        return () => {
            window.removeEventListener(WALLET_ADDRESS, handleLocalStorageChange);
        };
    }, []);

    const connectIMX = async () => {
        // const {link} = this.state;
        const link = new Link(ENDPOINTS[environment].LINK, null, 'v3');
        const { address } = await link.setup({
         providerPreference: 'none',
        });
        const client = await ImmutableXClient.build({
         publicApiUrl: ENDPOINTS[environment].PUBLIC_API,
        });

        setWallet(address.toLowerCase(), WALLET_TYPE_LINK);
    }

  const logout = async () => {
    if (getWalletType() === WALLET_TYPE_MAGIC)
        await logoutMagic();
      removeWallet();
  }

  // const exchange = this.props;
  const exchanges = useSelector(exchangesSelector);
  let exchange = null;
  if (!isNull(exchanges) && !isNull(address) && exchanges.length > 0) {
    const ex = exchanges.filter(x => x.Address === address || x.MagicLink === address);
    exchange = ex.length > 0 ? ex[0] : null;
  }
  const typeWallet = isNull(exchanges) ? '' :
      exchanges.Address === address ? 'Address' :
          exchanges.MagicLink === address ? 'MagicLink' : '';


  return (
      <Grid container style={{marginLeft: '10px'}}>
        <Grid item xs>
          {/*<MetaMask />*/}
          {/*<MetaMaskCard />*/}

          <div>
            {/*{(address === null) && (<SelectAccount onlyMagic={true} onChange={setExchangeSelected} />)}*/}
            {(address === null) && (<>
                <TextField
                    disabled
                    value={magicEmail}
                    label={'Magic Email'}
                    style={{width: '240px'}}
                    // fullWidth={true}
                    onChange={(e) => setMagicEmail(e.target.value)}
            /></>)}
            {(address === null) && (<Button color={'primary'} disabled size="sm" onClick={connectIMX}>Connect</Button>)}
              {/*disabled={isEmpty(magicEmail)}*/}
            {(address === null) && (<Button color={'primary'} size="sm" disabled onClick={() => getWalletConnection(magicEmail)}>Connect Magic</Button>)}
            {(address !== null) && (
                <span>
                    <div>walletType: {walletType} {walletType === WALLET_TYPE_MAGIC ? <div>{walletEmail}</div> : ''}</div>
                    {isNull(exchange) ? 'Wallet unknown' : exchange.Name }<br />
                    {typeWallet} {showAddress(address)}<br/>
                </span>)}
            {/*<Button size="sm" onClick={() => transfer(assets)}>Transfer ({assets.length})</Button>*/}
            {/*{(address !== null) && (<Button size="sm" onClick={() => transfer(assets)}>Transfer ({assets.length})</Button>)}*/}
            {(address !== null) && (<Button color={'danger'} size="sm" onClick={logout}>Log out</Button>)}
          </div>
        </Grid>
      </Grid>
  );
}