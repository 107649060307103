import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import {darkColorTransparent, lightColorTransparent} from "../../assets/jss/material-dashboard-react";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import WeaverPng from "../../assets/img/cta/background/Chara-weaver-24b.png";
import CardBody from "../../components/Card/CardBody";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";

const CollectionsPage = props => {
	const theme = props.theme;
	const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;
	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={12} md={3}></GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<Card style={{ backgroundPosition: 'right', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(${color}, ${color}), url(${WeaverPng})`}}>
						<CardBody>
							<h4><Translate id="central.collectionPage" options={{ renderInnerHtml: true }} /></h4>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</>
	);
}

function mapStateToProps(state) {
	const { config } = state;
	return {
		theme: config.theme,
	};
}

export default connect(mapStateToProps, null)(withLocalize(CollectionsPage));
