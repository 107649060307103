import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import ExchangeContainer from './ExchangeContainer.jsx';
import { Translate, withLocalize } from 'react-localize-redux';
import transExchanges from '../../locales/exchanges.json';
import transExternal from '../../locales/externals.json';
import transConfirm from '../../locales/confirmation.json';
import GridItem from '../../components/Grid/GridItem';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '../../components/CustomButtons/Button';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getExchanges } from '../../redux/user/actions';
import { isNull } from '../SmartTrade/Common';
import LabelCheckbox from "../../components/Form/LabelCheckbox";
import {SmallCheckBox} from "../../components/Fields/SmallCheckBox";

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class AccountsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transExternal);
    this.props.addTranslation(transConfirm);
    this.state = {
      withAssets: true
    }
  }
  componentDidMount() {
    this.props.getExchangesActions(this.state.withAssets, false, 30);
    // this.props.getExchangesActions();
  }
  render() {
    const { exchanges } = this.props;
    if (isNull(exchanges)) return <div>Loading...</div>;
    return (
      <div>
        <GridContainer>
          <GridItem md={1} container />
          <GridItem md={3} container justifyContent="center">
            <Tooltip
              title={<Translate id="externals.addExternal" />}
              enterDelay={200}
              leaveDelay={200}
            >
              <Button
                color="primary"
                fullWidth
                to={'/externals'}
                component={Link}
              >
                <Translate id="externals.addExternal" />
              </Button>
            </Tooltip>
          </GridItem>
          {/*<GridItem md container justifyContent="flex-end">*/}
          {/*  <LabelCheckbox htmlFor={'withAssets'} style={{marginTop: '20px'}}>*/}
          {/*    With Wallets Infos*/}
          {/*  </LabelCheckbox>*/}
          {/*  &nbsp;*/}
          {/*  <SmallCheckBox*/}
          {/*      id={'withAssets'}*/}
          {/*      onChange={() => this.setState({withAssets: !this.state.withAssets})}*/}
          {/*      input={{value:this.state.withAssets}}*/}
          {/*  />*/}
          {/*  <Button*/}
          {/*      onClick={() => {*/}
          {/*        this.props.getExchangesActions(this.state.withAssets, false, 30);*/}
          {/*      }}*/}
          {/*  >*/}
          {/*    OK*/}
          {/*  </Button>*/}
          {/*</GridItem>*/}
          {/*<GridItem md={3} container justifyContent="center">*/}
          {/*  <Tooltip*/}
          {/*    title={<Translate id="exchanges.offchain" />}*/}
          {/*    enterDelay={200}*/}
          {/*    leaveDelay={200}*/}
          {/*  >*/}
          {/*    <Button*/}
          {/*      color="primary"*/}
          {/*      fullWidth*/}
          {/*      to={'/extraTokens'}*/}
          {/*      component={Link}*/}
          {/*    >*/}
          {/*      <Translate id="exchanges.offchain" />*/}
          {/*    </Button>*/}
          {/*  </Tooltip>*/}
          {/*</GridItem>*/}
        </GridContainer>
        {/*<GridContainer>*/}
        {/*{exchanges &&*/}
        {/*exchanges.length > 0 && (*/}
        {/*<GridItem xs={12} sm={12} md={12}>*/}
        {/*<LineChartWallet exchanges={exchanges} />*/}
        {/*</GridItem>*/}
        {/*)}*/}
        {/*</GridContainer>*/}
        {/*style={{ margin: '-32px -15px 0 -15px', width: 'unset' }}*/}
        <GridContainer>
          {exchanges &&
            exchanges
              // .sort((a, b) => b.Assets != null && a.Assets ? b.Assets.length - a.Assets.length : a.WalletId)
              .map((exchange) => {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    key={exchange.Id}
                  >
                    <ExchangeContainer exchange={exchange} showButton={true} />
                  </GridItem>
                );
              })}
        </GridContainer>
      </div>
    );
  }
}

AccountsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user, form } = state;
  return {
    exchanges: user.exchanges,
    formExchange: form.formExchange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // postExchangesActions: bindActionCreators(postExchanges, dispatch),
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
  };
};

const connectedExchanges = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsPage);

export default withLocalize(withStyles(styles)(connectedExchanges));
