import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from "../../components/Card/Card";
import {LocationOn} from "@material-ui/icons";
import {Translate, withLocalize} from "react-localize-redux";
import {getCodeLang, getDateStr} from "../../helpers/general";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import MySwal from "sweetalert2";
import {successColor} from "../../assets/jss/material-dashboard-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEgg} from "@fortawesome/free-solid-svg-icons";
import TwitterContactUsButton from "../../components/CustomButtons/TwitterContactUsButton";

const TeamPage = props => {
    const codeLang = getCodeLang(props);
    return (
    <GridContainer>
        <GridItem
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            // key={}
        >
            <Card>
                <img src={require('../../assets/img/team/adrien.gif')} alt="Adrien" style={{
                    marginTop: '-20px'
                }}/>
                <h3 style={{textAlign: 'center'}}>Adrien</h3>
                <div>
                    <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                        <LocationOn style={{ marginTop: '-3px', marginRight: '5px' }} />
                        <span>&nbsp;Marseille</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <p>
                            <h5 style={{marginTop: 0}}><Translate id="central.event" />&nbsp;:</h5>
                        </p>
                        <ul>
                            <li>Artellium Event {getDateStr(new Date(2022,7-1,24), codeLang)}</li>
                            <li>Foire de Marseille 2023</li>
                            <li>La Briqueterie Marseille {getDateStr(new Date(2023,7-1,18), codeLang)} par web3conferences</li>
                            <li>Across The Community {getDateStr(new Date(2023,7-1,26))}</li>
                            <li>{'Heroes\' Legacy'}</li>
                        </ul>
                    </GridItem>
                </div>
                <br />
            </Card>
        </GridItem>
        <GridItem
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            // key={}
        >
            <Card>
                <img src={require('../../assets/img/team/point-mark-anim.gif')} alt="Point-Mark" style={{
                    marginTop: '-20px'
                }}/>
                <h3 style={{textAlign: 'center'}}><Translate id="central.recruiting" /></h3>
                <div>
                    <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                        <LocationOn style={{ marginTop: '-3px', marginRight: '5px' }} />
                        <span>&nbsp;<Translate id="central.remote" /></span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>

                        <ul>
                            <li>Twitter manager</li>
                            <li>Discord manager</li>
                            <li>Youtuber</li>
                            <li>Designer, Graphiste</li>
                            <li>FrontEnd ReactJs</li>
                            <li>BackEnd C#</li>
                        </ul>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <TwitterContactUsButton />
                        </div>
                        {/*<div style={{float: 'right'}}>*/}
                        {/*    <Button size={'sm'} color="success" round onClick={async () => {*/}
                        {/*        await MySwal.fire({*/}
                        {/*            title: 'Claim',*/}
                        {/*            html: 'Send this message "I found the egg : 03d909ae5c8d" to reply of last tweet',*/}
                        {/*            confirmButtonText: 'Ok',*/}
                        {/*            minWidth: '800px',*/}
                        {/*            confirmButtonColor: successColor,*/}
                        {/*        });*/}
                        {/*    }}><FontAwesomeIcon icon={faEgg} />&nbsp;Claim</Button>*/}
                        {/*</div>*/}
                        <br/>
                    </GridItem>
                </div>
            </Card>

            {/*point-mark-anim.gif*/}
        </GridItem>
    </GridContainer>
  );
};
export default withLocalize(TeamPage);


