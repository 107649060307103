import {isNull} from "../views/SmartTrade/Common";

export class Asset {
    constructor(key) {
        this.key = key;
    }
    masks = {
        Foil:           0x0000000000010000,
        Set:            0x000000000000F000,
        TokenType:      0x0000000000000F00,
        Element:        0x00000000000000FF,
        Rarity:         0x00000000F0000000,
        AnimationLevel: 0x000000000F000000,
        Rank:           0x0000000000FF0000,
        Grade:          0x0000000000FF0000,
        CardId:         0x000000000000FFFF
    };
    getBigInt = () => isNull(this.key) ? 0 : Number(BigInt(this.key) >> 32n);
    getFoil = () => Number((this.getBigInt() & this.masks.Foil) >>> (48-32));
    getSet = () => Number((this.getBigInt() & this.masks.Set) >>> (44-32));
    getTokenType = () => Number((this.getBigInt() & this.masks.TokenType) >>> (40-32));
    getElement = () => Number(this.getBigInt() & this.masks.Element);
    getRarity = () => Number((this.key & this.masks.Rarity) >>> 28);//this.getBigInt() & this.masks.Rarity;
    getAnimationLevel = () => Number((this.key & this.masks.AnimationLevel) >>> 24);
    getRank = () => {
        const val = Number(this.key & this.masks.Rank) >>> 16;
        return val > 64 ? 5 : val;
    }
    getGrade = () => {
        const val = Number(this.key & this.masks.Grade) >>> 16;
        return val > 64 ? String.fromCharCode(val) : ' ';
    }
    getCardId = () => this.key & this.masks.CardId;
}