import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import { withLocalize } from 'react-localize-redux';
import FormWorker from './FormWorker';
import { bindActionCreators } from 'redux';
import { getWorker, getWorkers, resetWorker } from '../../redux/worker/actions';
import { isNull } from '../SmartTrade/Common';
import { workerSelector, workersSelector } from '../../redux/worker/selector';
import { eWorkerType } from '../../helpers/general';

class WorkerPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const index = location.search.indexOf('workerType=');
    if (index !== -1) {
      const valWorkerType = parseInt(location.search.substr(index + 11, 1));
      this.state = {
        worker: {
          WorkerType: {
            value: valWorkerType,
            label: eWorkerType[valWorkerType],
          },
        },
      };
    }
  }
  componentDidMount() {
    const { match } = this.props;
    const id = match?.params?.id;
    this.props.resetWorkerActions();
    if (!isNull(id)) {
      this.props.getWorkerActions(id);
    }
  }
  render() {
    const { match } = this.props;
    const id = match?.params?.id;
    if (!isNull(id) && isNull(this.props.worker)) return <div>Loading...</div>;
    const worker = isNull(this.props.worker)
      ? isNull(this.state?.worker)
        ? null
        : this.state.worker
      : this.props.worker;
    const isUpdate = !(isNull(worker) || isNull(worker.WorkerId));
    worker.WorkerType =
      typeof worker.WorkerType === 'object'
        ? worker.WorkerType
        : { value: worker.WorkerType, label: eWorkerType[worker.WorkerType] };
    console.log(worker);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                {isUpdate ? 'Update' : 'Add'} Worker
              </CardHeader>
              <CardBody>
                <FormWorker worker={worker} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

WorkerPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    worker: workerSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkerActions: bindActionCreators(getWorker, dispatch),
    resetWorkerActions: bindActionCreators(resetWorker, dispatch),
  };
};
const connectedExchanges = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkerPage);

export default withLocalize(connectedExchanges);
