import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import { withLocalize } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { isNull } from '../SmartTrade/Common';
import TableScholars from './TableScholars';
import { scholarsSelector } from '../../redux/user/selector';
import { bindActionCreators } from 'redux';
import { getScholars, resetWallet } from '../../redux/user/actions';
import Button from '../../components/CustomButtons/Button';
import { Cached, Clear } from '@material-ui/icons';
import {
  createScholarsGridAction,
  resetScholarsColumns,
} from '../../redux/config/actions';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import {SmallCheckBox} from "../../components/Fields/SmallCheckBox";
import LabelCheckbox from "../../components/Form/LabelCheckbox";

class ScholarsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nbWeek: 0,
      withWalletInfo: false
    };
  }
  componentDidMount() {
    this.props.getScholarsActions(this.state.nbWeek);
    this.props.resetWalletActions();
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { scholars } = this.props;
    if (isNull(scholars)) return <div>Loading...</div>;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridItem
          container
          xs={12}
          sm={8}
          md={8}
          alignItems="center"
          justifyContent="flex-start"
        >
          <div>
            <span>
              <Button
                onClick={() => {
                  localStorage.removeItem('scholarsGrid');
                  this.props.resetAssetColumnsActions();
                }}
                size="sm"
              >
                <Cached />
                &nbsp; Reset Table
              </Button>
              <Button
                onClick={() => {
                  this.props.clearFilterAssetActions();
                }}
                size="sm"
              >
                <Clear />
                &nbsp; Reset filter
              </Button>
              <Button to={'/manageWorkers'} color="primary" component={Link}>
                Manage Worker
              </Button>
              <LabelCheckbox htmlFor={'withWalletInfo'}>
                With Wallet Info
              </LabelCheckbox>
              <SmallCheckBox
                  id={'withWalletInfo'}
                  onChange={() => this.setState({withWalletInfo: !this.state.withWalletInfo})}
                  input={{value:this.state.withWalletInfo}}
              />
              &nbsp;
              <TextField
                id="nbWeek"
                name="nbWeek"
                label="Number of Week"
                value={this.state.nbWeek}
                type="number"
                onChange={(e) => this.handleChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                onClick={() => {
                  this.props.getScholarsActions(this.state.nbWeek, this.state.withWalletInfo);
                }}
              >
                OK
              </Button>
            </span>
          </div>
        </GridItem>
        <TableScholars scholars={scholars} />
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    scholars: scholarsSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScholarsActions: bindActionCreators(getScholars, dispatch),
    resetWalletActions: bindActionCreators(resetWallet, dispatch),
    resetAssetColumnsActions: () => dispatch(resetScholarsColumns()),
    clearFilterAssetActions: () =>
      dispatch(createScholarsGridAction('filters', [])),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ScholarsPage),
);
