import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import FormExternal from './FormExternal';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transExternals from '../../../locales/externals.json';
import { isNull } from '../../SmartTrade/Common';
import { bindActionCreators } from 'redux';
import {
  deleteExchanges,
  getExchange,
  refreshExchange,
  resetWallet,
  updateExchanges,
} from '../../../redux/user/actions';
import { alertPopup } from '../../../redux/alert/alert.actions';
import { walletSelector } from '../../../redux/user/selector';
import { getWorkers } from '../../../redux/worker/actions';
import { workersSelector } from '../../../redux/worker/selector';
import FormResetPwd from "./FormResetPwd";

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class ExternalPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExternals);
  }
  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    this.props.resetWalletActions();
    if (!isNull(id)) this.props.getExchangeActions(id, true);
    this.props.getWorkersActions();
  }
  render() {
    const { classes, match, workers, location } = this.props;
    let { wallet } = this.props;
    const id = match?.params?.id;
    if (!isNull(id)) {
      if (isNull(wallet)) return <div />;
    }
    else
      wallet = null;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  <Translate id="externals.addExternal" />
                </h4>
                <p className={classes.cardCategoryWhite}>
                  <Translate id="externals.connect" />
                </p>
              </CardHeader>
              <CardBody>
                <FormExternal
                  wallet={wallet}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ExternalPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { user, form } = state;
  const { match } = props;
  // const id = match.params.id;
  return {
    wallet: walletSelector(state),
    exchanges: user.exchanges,
    formExchange: form.formExchange,
    workers: workersSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetWalletActions: bindActionCreators(resetWallet, dispatch),
    getExchangeActions: bindActionCreators(getExchange, dispatch),
    getWorkersActions: bindActionCreators(getWorkers, dispatch),
  };
};
const connectedExchanges = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalPage);

export default withLocalize(withStyles(styles)(connectedExchanges));
