import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { isNull } from '../../views/SmartTrade/Common';
import { alertConstants } from '../../redux/alert/alert.constants';
import { getTranslate } from 'react-localize-redux';
import {
  dangerColor,
  successColor,
} from '../../assets/jss/material-dashboard-react';
import { bindActionCreators } from 'redux';
import { clearAlert } from '../../redux/alert/alert.actions';
import DialogOptionsPaiement from '../../views/Paiement/DialogOptionsPaiement';

const styles = {};

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.chargeTheme();
    this.alertTimeout = null;
  }

  getPayload = (type) => {
    const { translate } = this.props;

    let ret = {
      showCloseButton: true,
    };
    switch (type) {
      case 'confirm':
        return {
          ...ret,
          title: translate('confirmation.areYouSure'),
          text: translate('confirmation.noRevertIt'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: translate('central.yes'),
          cancelButtonText: translate('central.cancel'),
          confirmButtonColor: successColor,
          cancelButtonColor: dangerColor,
        };
      case 'success':
        return {
          ...ret,
          icon: 'success',
        };
      case 'error':
        return {
          ...ret,
          icon: 'error',
        };
      case 'creatOrder':
        return {};
      default:
        return {};
    }
  };

  chargeTheme = () => {
    const { theme } = this.props;
    if (theme === 'dark') {
      require('@sweetalert2/theme-dark/dark.min.css');
    } else {
      require('@sweetalert2/theme-default/default.min.css');
    }
  };

  componentWillUnmount() {
    this.clearAlertTimeout();
  }
  clearAlertTimeout = () => {
    if (this.alertTimeout !== null) {
      clearTimeout(this.alertTimeout);
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { alert, theme } = this.props;

    if (theme !== prevProps.theme) {
      this.chargeTheme();
    }

    if (alert.payload) {
      this.showAlert();
    }
  };

  showAlert = () => {
    const { alert } = this.props;

    if (
      alert.type &&
      alert.type === alertConstants.ALERT &&
      !isNull(alert.payload)
    ) {
      const payload = {
        ...this.getPayload(alert.payload.type),
        ...alert.payload,
      };
      const pred = Swal.fire(payload);
      if (payload.then) {
        pred
          .then(alert.payload.then)
          .then(() => this.props.clearAlertActions());
      } else {
        pred.then(() => this.props.clearAlertActions());
      }
    }
  };

  render() {
    const { alert } = this.props;
    if (alert.popupOptionsPaiement) return <DialogOptionsPaiement />;
    return <div />;
  }
}

const mapStateToProps = (state, props) => {
  const { alert, config, localize } = state;
  return {
    translate: getTranslate(localize),
    alert,
    theme: config.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAlertActions: bindActionCreators(clearAlert, dispatch),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Alert),
);
