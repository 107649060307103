import React from 'react';
import { connect } from 'react-redux';
import Autorenew from '@material-ui/icons/Autorenew';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Visibility from '@material-ui/icons/Visibility';
import Create from '@material-ui/icons/Create';
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import { bindActionCreators } from 'redux';
import {
  deleteExchanges,
  updateExchanges,
  refreshExchange, getExchange,
} from '../../redux/user/actions';
import LittleButton from '../../components/CustomButtons/LittleButton';
import PropTypes from 'prop-types';
import { getTranslate, Translate } from 'react-localize-redux';
import {isEmpty, isNull, parseToFloat} from '../SmartTrade/Common';
import { alertPopup } from '../../redux/alert/alert.actions';
import { getDateHourStr, listArkhome, listRarity } from '../../helpers/general';
import Link from '@material-ui/core/Link';
import { getAssets } from '../../helpers/exchange';
import trisel from '../../assets/img/icon-token/trisel-20x20.png';
import cta from '../../assets/img/icon-token/cta-20x20.png';
import prana from '../../assets/img/icon-token/prana-20x20.png';
import {
  successColor,
  dangerColor,
} from '../../assets/jss/material-dashboard-react';
import {isAdminSelector} from "../../redux/authentication/selector";
import AssetsContainer from "./AssetsContainer";

class ExchangeContainer extends React.Component {
  onDelete = (id) => {
    this.props.deleteExchangesActions(id);
  };
  remove = (id) => {
    const { translate } = this.props;
    if (!window.confirm(translate('confirmation.areYouSure'))) return;
    this.onDelete(id);
  };
  refresh = (id) => {
    this.props.refreshActions(id);
  };

  getColorQuest(mintPassByName, lastInGameInfos) {
    const hasGolden = mintPassByName.filter(
      (obj) => obj.name.indexOf('Golden') !== -1,
    );
    const hasLegacy = mintPassByName.filter(
      (obj) => obj.name.indexOf('Legacy') !== -1,
    );
    const hasBlue = mintPassByName.filter(
      (obj) => obj.name.indexOf('Blue') !== -1,
    );
    const hasGreen = mintPassByName.filter(
      (obj) => obj.name.indexOf('Green') !== -1,
    );
    const hasRed = mintPassByName.filter(
      (obj) => obj.name.indexOf('Red') !== -1,
    );
    const cta = lastInGameInfos.Cta;
    if (cta < 500) return successColor;
    if (cta >= 500 && (hasBlue || hasGolden || hasLegacy)) return successColor;
    else if (cta >= 500 && hasGreen)
      return lastInGameInfos.NbQuestWeeklyCompleted >= 7
        ? successColor
        : dangerColor;
    if (cta >= 500 && hasRed)
      return lastInGameInfos.NbQuestWeeklyCompleted >= 14
        ? successColor
        : dangerColor;
    return dangerColor;
  }

  render() {
    const { exchange, showButton, translate, loading, isAdmin } = this.props;
    let { isDashboard } = this.props;
    if (isNull(isDashboard)) isDashboard = false;
    const mintPasses = getAssets(exchange, 1);
    let mintPassByName = [];
    for (const mp of mintPasses) {
      const name = mp?.Name;
      if (isNull(name)) continue;
      let elem = mintPassByName.find((x) => x.name === name);
      if (isNull(elem)) mintPassByName.push({ name: name, mps: [elem] });
      else elem.mps.push(mp);
    }

    const lastInGameInfos = isNull(exchange?.InGameInfos)
      ? null
      : exchange?.InGameInfos[0];

    const priceCta = 0.5;
    const priceTrisel = 2/1000;
    const pricePrana = 0.0145;

    const valueCta = isNull(lastInGameInfos)
      ? 0
      : parseToFloat(lastInGameInfos.Cta * priceCta, 0.01);
    const valuePrana = isNull(lastInGameInfos)
        ? 0
        : parseToFloat(lastInGameInfos.Prana * pricePrana, 0.01);
    const valueTrisel = isNull(lastInGameInfos)
      ? 0
      : parseToFloat(lastInGameInfos.Trisel * priceTrisel, 0.01);
    return (
      <Card>
        <CardHeader color="primary">
          <GridContainer alignItems="center">
            <GridItem xs>
              {exchange.Name}
              {/*<br/>*/}
              {/*<small>{exchange.Address}</small>*/}
            </GridItem>
            {exchange && (
              <GridItem
                xs
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                {!isEmpty(exchange.Address) && (
                    <div>
                  <Link
                    href={`https://immutascan.io/address/${exchange.Address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{position:'relative'}}>
                      <img
                        style={{ position: 'absolute', bottom: '0px', left: '12px', background: 'white' }}
                        width="16px"
                        height="16px"
                        src="https://www.ledger.com/wp-content/uploads/2021/11/Ledger_favicon.png"
                        alt="Go Ledger"
                      />
                      <img
                          style={{ marginTop: '4px', marginLeft: '2px' }}
                          width="26px"
                          height="26px"
                          src="https://immutascan.io/favicon.png"
                          alt="Go Ledger"
                      />
                    </div>
                  </Link>
                    </div>
                )}
                {!isEmpty(exchange.MagicLink) && (
                    <Link
                        href={`https://immutascan.io/address/${exchange.MagicLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <div style={{position:'relative'}}>
                        <img
                            style={{ position: 'absolute', bottom: '0px', left: '12px', background: 'white' }}
                            width="16px"
                            height="16px"
                            src="https://media.graphassets.com/resize=w:800,fit:crop/auto_image/compress/XHQzmJyDRmaCwGoEcb8i"
                            alt="Go MagicLink"
                        />
                        <img
                            style={{ marginTop: '4px', marginLeft: '2px' }}
                            width="26px"
                            height="26px"
                            src="https://immutascan.io/favicon.png"
                            alt="Go Ledger"
                        />
                      </div>
                    </Link>
                )}
                {showButton && (
                  <>
                    {/*<LittleButton*/}
                    {/*    tooltip={<Translate id="exchanges.refresh" />}*/}
                    {/*    icon={<ArrowDropDown />}*/}
                    {/*    onClick={() => this.props.getExchangeActions(exchange.WalletId, true, !isDashboard, 30, !isDashboard)}*/}
                    {/*/>*/}
                    {isAdmin && (
                      <LittleButton
                        tooltip={<Translate id="exchanges.refresh" />}
                        icon={<Autorenew />}
                        onClick={() => this.refresh(exchange.WalletId)}
                      />)}
                    <LittleButton
                      tooltip={<Translate id="exchanges.update" />}
                      color="warning"
                      icon={<Create />}
                      to={'/externals/' + exchange.WalletId}
                    />
                    <LittleButton
                      tooltip={<Translate id="exchanges.seeDetails" />}
                      color="info"
                      icon={<Visibility />}
                      to={'/exchange/' + exchange.WalletId}
                    />
                    <LittleButton
                      tooltip={<Translate id="central.delete" />}
                      color="danger"
                      icon={<DeleteForever />}
                      onClick={() => this.remove(exchange.WalletId)}
                    />
                  </>
                )}
              </GridItem>
            )}
          </GridContainer>
        </CardHeader>
        <CardBody>
          <AssetsContainer exchange={exchange} />
          <div>&nbsp;</div>
          {lastInGameInfos &&
            lastInGameInfos.Cta +
              lastInGameInfos.Prana +
              lastInGameInfos.Trisel >
              0 && (
              <div>
                <div>
                  <img width={20} height={20} src={cta} alt="CTA" />{' '}
                  <b>{lastInGameInfos.Cta.toLocaleString()}</b> &nbsp;
                  <img width={20} height={20} src={prana} alt="Prana" />{' '}
                  <b>{lastInGameInfos.Prana.toLocaleString()}</b> &nbsp;
                  <img width={20} height={20} src={trisel} alt="Trisel" />{' '}
                  <b>{lastInGameInfos.Trisel.toLocaleString()}</b>
                  <div
                    style={{
                      display: 'inline',
                      float: 'right',
                      marginTop: '5px',
                    }}
                  >
                    <img width={20} height={20} src={cta} alt="CTA" />{' '}{valueCta.toLocaleString()}$ {' '}
                    <img width={20} height={20} src={prana} alt="Prana" />{' '}{valuePrana.toLocaleString()}$
                    {isAdmin && (<span><img width={20} height={20} src={trisel} alt="Trisel" />{valueTrisel.toLocaleString()}$</span>)}
                  </div>
                </div>
                {lastInGameInfos.Level && (
                  <>
                    <div>
                      Level: {lastInGameInfos.Level} ({' '}
                      {lastInGameInfos.CurrentLevelPercent}% ) | Exp:{' '}
                      {lastInGameInfos.LevelExpTotal.toLocaleString()} ({' '}
                      {lastInGameInfos.NextLevelExpRequired.toLocaleString()} ){' '}
                      {lastInGameInfos.Date && (
                        <div
                          style={{
                            display: 'inline',
                            float: 'right',
                          }}
                        >
                          ( {getDateHourStr(lastInGameInfos.Date, 'fr')} )
                        </div>
                      )}
                    </div>
                  </>
                )}
                {lastInGameInfos && lastInGameInfos.NbQuestGeneral && (
                  <>
                    <span
                      style={{
                        color: this.getColorQuest(
                          mintPassByName,
                          lastInGameInfos,
                        ),
                      }}
                    >
                      Quest
                    </span>
                    :&nbsp; D: {lastInGameInfos.NbQuestDailyCompleted} /{' '}
                    {lastInGameInfos.NbQuestDaily} &nbsp; W:{' '}
                    {lastInGameInfos.NbQuestWeeklyCompleted} /{' '}
                    {lastInGameInfos.NbQuestWeekly} &nbsp; E:{' '}
                    {lastInGameInfos.NbQuestEventCompleted} /{' '}
                    {lastInGameInfos.NbQuestEvent} &nbsp; G:{' '}
                    {lastInGameInfos.NbQuestGeneralCompleted} /{' '}
                    {lastInGameInfos.NbQuestGeneral} &nbsp;
                  </>
                )}
                {lastInGameInfos.CurrentEraMmr && (
                  <>
                    <div>
                      Rank: {lastInGameInfos.CurrentEraMmr.toLocaleString()} W:{' '}
                      {lastInGameInfos.CurrentEraWinCount} L:{' '}
                      {lastInGameInfos.CurrentEraDuelCount -
                        lastInGameInfos.CurrentEraWinCount}{' '}
                      ({' '}
                      {parseToFloat(
                        lastInGameInfos.CurrentEraRatio * 100,
                        0.01,
                      )}{' '}
                      % )
                    </div>
                  </>
                )}
              </div>
            )}
          {exchange.Description && <div>{exchange.Description}</div>}
        </CardBody>
      </Card>
    );
  }
}

ExchangeContainer.propTypes = {
  exchange: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
};

function mapStateToProps(state) {
  const { localize, loading } = state;
  return {
    loading,
    translate: getTranslate(localize),
    isAdmin: isAdminSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    refreshActions: bindActionCreators(refreshExchange, dispatch),
    getExchangeActions: bindActionCreators(getExchange, dispatch),
    deleteExchangesActions: bindActionCreators(deleteExchanges, dispatch),
    updateExchangesActions: bindActionCreators(updateExchanges, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeContainer);
