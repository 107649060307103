import React from 'react';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import {
  getTargets,
  getStopLoss,
  getPanicSell,
  getStart,
  isActiveOrder,
  getProfitFromOrder,
  getResultFromOrder,
} from '../../helpers/order';
import { getActiveCode } from '../../helpers/localize';
import {
  parseToFloat,
  currentPriceSelector,
  stepPriceSelector,
  isNull,
  isEmpty,
} from '../SmartTrade/Common';
import { ShowPair, ShowExchange } from '../../components/Fields/LogoContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import OrderButtons from './OrderButtons';
import BuyType from '../../components/Order/BuyType';

import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider/Divider';
import { getTranslate, Translate } from 'react-localize-redux';

import ActionOrderLine from './ActionOrderLine';
import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyle from '../../assets/jss/material-dashboard-react/components/typographyStyle.jsx';
import { getDateHourStr } from '../../helpers/general';

class OrderContainer extends React.PureComponent {
  showProfit = (order, currentPrice, stepPrice) => {
    const { classes } = this.props;

    const profit = getProfitFromOrder(order, currentPrice);
    const result = getResultFromOrder(order, currentPrice, stepPrice);
    const pairs = order.Pair.split('-');

    if (profit === '-') return '-';
    if (parseToFloat(profit) >= 0) {
      return (
        <span className={classes.successText}>
          {'+'}
          {result} {pairs[1]}
          {' (+' + profit + '%)'}
        </span>
      );
    }
    return (
      <span className={classes.dangerText}>
        {result} {pairs[1]}
        {' (' + profit + '%)'}
      </span>
    );
  };

  render() {
    const { order, codeLang, stepPrice, currentPrice, translate } = this.props;
    if (!order) return <div />;
    const isPreview = order.OrderId === 0;
    if (!order || !Object.hasOwn(order, 'Pair')) return '';
    const pairs = order.Pair.split('-');
    const targets = getTargets(order.Triggers);
    const stopLoss = getStopLoss(order.Triggers);
    const panicSell = getPanicSell(order.Triggers);
    const start = getStart(order.Triggers);
    if (isNull(start)) return <div />;

    return (
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs>
              {ShowExchange(order.AAccount)}
              {ShowPair(order.Pair)}
            </GridItem>
            <GridItem>
              <b>
                <BuyType isBuy={order.IsBuy} />
              </b>
              <br />
              {order.Leverage !== null
                ? translate('smartTrade.leverage') +
                  ' ' +
                  (order.Leverage === 0 ? 'Cross' : order.Leverage + 'x')
                : ''}
            </GridItem>
            <GridItem xs container justifyContent="flex-end">
              <GridItem>
                <small>
                  <Translate id="orders.capital" />
                  {': '}
                  <b>{order.Pourcentage}%</b>
                </small>
                <br />
                <small>{getDateHourStr(order.DateCreated, codeLang)}</small>
              </GridItem>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Divider style={{ margin: '3px 0px' }} />
            </GridItem>
          </GridContainer>
          {start && <ActionOrderLine order={order} action={start} />}
          {targets &&
            targets.map((target) => (
              <ActionOrderLine
                key={target.TriggerId}
                order={order}
                action={target}
              />
            ))}
          {stopLoss && <ActionOrderLine order={order} action={stopLoss} />}
          {panicSell && <ActionOrderLine order={order} action={panicSell} />}
        </CardBody>
        <CardFooter>
          <GridContainer style={{ width: '100%' }}>
            {!isPreview && !isEmpty(order.Note) && (
              <GridItem xs={12} sm={12} md={12}>
                <Translate id="central.note" />: {order.Note}
              </GridItem>
            )}
            {!isPreview && (
              <GridItem xs>
                {currentPrice && (
                  <small>
                    <p style={{ lineHeight: '180%' }}>
                      <Translate id="smartTrade.profit" />:{' '}
                      <b>{this.showProfit(order, currentPrice, stepPrice)}</b>
                      <br />
                      <Translate id="orders.currentPrice" />:
                      <b>
                        {' '}
                        {currentPrice}
                        {pairs[1]}
                      </b>
                      <br />
                      {order.Group && order.Group.Name ? 'Trader: ' : ''}
                      <b>
                        {order.Group && order.Group.Name
                          ? order.Group.Name
                          : ''}
                      </b>
                    </p>
                  </small>
                )}
              </GridItem>
            )}
            {!isPreview && (
              <GridItem>
                <div style={{ marginTop: '10px' }}>
                  <OrderButtons order={order} />
                </div>
              </GridItem>
            )}
            {!isActiveOrder(order.State) && !isPreview && (
              <GridItem>
                <br />
                <small>{order.OrderId}</small>
              </GridItem>
            )}
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  return {
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
    currentPrice: currentPriceSelector(state, props),
    stepPrice: stepPriceSelector(state, props),
  };
};

export default withStyles(typographyStyle)(
  connect(mapStateToProps, null)(OrderContainer),
);
