import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import {
  getAssets,
  getCardsOnSell,
  getCardsSold,
  setDetail,
  setOnSell,
} from '../../redux/card/actions';
import {
  assetsSelector,
  cardsOnSellDetailSelector,
  cardsOnSellSelector,
  cardsSoldDetailSelector,
  cardsSoldSelector,
  detailSelector,
  onSellSelector,
} from '../../redux/card/selector';
import Button from '../../components/CustomButtons/Button';
import { Cached, Clear } from '@material-ui/icons';
import {
  createAssetsGridAction,
  resetAssetColumns,
} from '../../redux/config/actions';
import TableUIAssets from './TableUIAssets';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import {
  capitalize,
  listTokenType,
  listTokenTypeImage,
} from '../../helpers/general';
import { isNull } from '../SmartTrade/Common';
import TableAssets from "./TableAssets";
import TextField from "@material-ui/core/TextField";

class AssetsPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    const search = location.search ? location.search.toLowerCase() : '';
    let value = 0;
    listTokenTypeImage.forEach((item, index) => {
      if (search.indexOf(item) !== -1) value = index;
    });
    this.state = {
      tokenType: value,
      showTable: true,
      onSell: true,
      detail: false,
      triselPrice: 3,
    };
  }

  refresh = (status, tokenType, detail) => {
    this.props.getAssetsActions(status, tokenType, detail);
  };

  getStatus = (onSell) => (onSell ? 'active' : 'filled');

  componentDidMount() {
    const tokenType = this.state.tokenType;
    const { detail, onSell } = this.state;
    this.refresh(this.getStatus(onSell), tokenType, detail);
  }
  handleChange = (event, newValue) => {
    this.setState({ ...this.state, tokenType: newValue });
    const { detail, onSell } = this.state;
    this.refresh(this.getStatus(onSell), newValue, detail);
  };

  handleChangeGeneral = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const tokenType = this.state.tokenType;
    const showTable = this.state.showTable;
    const valueStr = capitalize(
      listTokenType[tokenType].toLowerCase().replaceAll('_', ' '),
    );
    const { detail, onSell } = this.state;
    const { assets } = this.props;
    if (isNull(assets)) return <div>Loading...</div>;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3 style={{ textAlign: 'center' }}>
          <b>
            {valueStr} {onSell ? 'On Sell' : 'Sold'}{' '}
            {detail ? 'Detailed' : 'Merged'}
          </b>
        </h3>
        <div>
          <Button
              onClick={() => {
                this.setState({showTable: !showTable});
              }}
              size="sm"
          >
            {showTable ? 'Hide Table' : 'Show Table'}
          </Button>
          {showTable && (<TableAssets assets={assets} elem={onSell ? 'FP' : 'Avg'} />)}
        </div>

        <span>Nb Assets: {assets.reduce((a, b) => a + b.Count, 0)}</span>
        <br />
        <GridItem
          container
          xs={12}
          sm={8}
          md={8}
          alignItems="center"
          justifyContent="flex-start"
        >
          <div>
            <span>
              <Button
                onClick={() => {
                  localStorage.removeItem('cardsGrid');
                  this.props.resetAssetColumnsActions();
                }}
                size="sm"
              >
                <Cached />
                &nbsp; Reset Table
              </Button>
              <Button
                onClick={() => {
                  this.props.clearFilterAssetActions();
                }}
                size="sm"
              >
                <Clear />
                &nbsp; Reset filter
              </Button>
              <Button
                onClick={() => {
                  this.props.setDetailActions(!detail);
                  this.setState({detail: !detail});
                  this.refresh(this.getStatus(onSell), tokenType, !detail);
                }}
                size="sm"
              >
                {detail ? 'Merge' : 'Detail'}
              </Button>
              <Button
                onClick={() => {
                  this.props.setOnSellActions(!onSell);
                  this.setState({onSell: !onSell});
                  this.refresh(this.getStatus(!onSell), tokenType, detail);
                }}
                size="sm"
              >
                {onSell ? 'Sold' : 'On Sell'}
              </Button>
              <TextField
                  id="triselPrice"
                  name="triselPrice"
                  label="Price of Trisel"
                  value={this.state.triselPrice}
                  type="number"
                  onChange={(e) => this.handleChangeGeneral(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />

            </span>
          </div>
        </GridItem>
        <CustomTabs
          value={this.state.tokenType}
          title=""
          onChange={this.handleChange}
          headerColor="primary"
          tabs={listTokenType.map((name, index) => ({
            tabName: name.replaceAll('_', ' '),
            tabContent: <TableUIAssets tokenType={index} assets={assets} triselPrice={this.state.triselPrice} />,
          }))}
        />
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    assets: assetsSelector(state),
    cardsSold: cardsSoldSelector(state),
    cardsOnSell: cardsOnSellSelector(state),
    cardsSoldDetail: cardsSoldDetailSelector(state),
    cardsOnSellDetail: cardsOnSellDetailSelector(state),
    detail: detailSelector(state),
    onSell: onSellSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAssetsActions: bindActionCreators(getAssets, dispatch),
    setDetailActions: bindActionCreators(setDetail, dispatch),
    setOnSellActions: bindActionCreators(setOnSell, dispatch),
    getCardsSoldActions: bindActionCreators(getCardsSold, dispatch),
    getCardsOnSellActions: bindActionCreators(getCardsOnSell, dispatch),
    resetAssetColumnsActions: () => dispatch(resetAssetColumns()),
    clearFilterAssetActions: () =>
      dispatch(createAssetsGridAction('filters', [])),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(AssetsPage),
);
