import {Magic} from "magic-sdk";
import { Web3Provider } from '@ethersproject/providers';
import {
    ImmutableX,
    Config,
    createStarkSigner,
    generateLegacyStarkPrivateKey
} from '@imtbl/core-sdk';
import {Link} from "@imtbl/imx-sdk";

export const WALLET_ADDRESS = 'WALLET_ADDRESS';
export const SELECTED_WALLET = 'SELECTED_WALLET';
export const WALLET_TYPE = 'WALLET_TYPE';
export const WALLET_EMAIL = 'WALLET_EMAIL';
export const WALLET_TYPE_MAGIC = 'magic';
export const WALLET_TYPE_LINK = 'link';
export const CTA_ADDRESS = '0xa04bcac09a3ca810796c9e3deee8fdc8c9807166';
const ENDPOINTS = {
    mainnet: {
        LINK: 'https://link.x.immutable.com',
        PUBLIC_API: 'https://api.x.immutable.com/v1',
    },
    ropsten: {
        LINK: 'https://link.ropsten.x.immutable.com',
        PUBLIC_API: 'https://api.ropsten.x.immutable.com/v1',
    },
};
const environment = 'mainnet';
export const getLinkClient = () => (new Link(ENDPOINTS[environment].LINK, null, 'v3'));
export const getIMXClient = () => (new ImmutableX(Config.PRODUCTION));
export const getWalletType = () => (localStorage.getItem(WALLET_TYPE));
export const getSelectedWallet = () => (JSON.parse(localStorage.getItem(SELECTED_WALLET)));
export const getAddressSelectedWallet = () => (getSelectedWallet()?.address);
export const setSelectedWallet = selectedWallet => (localStorage.setItem(SELECTED_WALLET, JSON.stringify(selectedWallet)));
export const getWalletAddress = () => (localStorage.getItem(WALLET_ADDRESS));
export const getWalletEmail = () => (localStorage.getItem(WALLET_EMAIL));
export const removeWallet = () => {
    localStorage.removeItem(WALLET_ADDRESS);
    localStorage.removeItem(WALLET_TYPE);
    localStorage.removeItem(WALLET_EMAIL);
    window.dispatchEvent(new Event(WALLET_ADDRESS))
}
export const setWallet = (address, type, email) => {
    localStorage.setItem(WALLET_ADDRESS, address.toLowerCase());
    localStorage.setItem(WALLET_TYPE, type);
    localStorage.setItem(WALLET_EMAIL, email);
    window.dispatchEvent(new Event(WALLET_ADDRESS))
}
export const logoutMagic = async () => {
    const magic = new Magic('pk_live_DC29A65A3D8E3BCC');
    await magic.user.logout()
};
export const getWalletConnection = async (email = '') => {
    const magic = new Magic('pk_live_DC29A65A3D8E3BCC');
    await magic.auth.loginWithMagicLink({ email });
    const provider = new Web3Provider(magic.rpcProvider);
    // Create Ethereum signer
    const ethSigner = provider.getSigner();
    // Get user's Ethereum public address
    const address = (await ethSigner.getAddress()).toLowerCase();
    // Create Stark signer
    const starkPrivateKey = await generateLegacyStarkPrivateKey(ethSigner);
    const starkSigner = createStarkSigner(starkPrivateKey);
    setWallet(address, WALLET_TYPE_MAGIC, email);
    const walletConnection = { address, ethSigner, starkSigner };
    return walletConnection;
};
