import GridItem from "../Grid/GridItem";
import {Twitter} from "@material-ui/icons";
import {Translate} from "react-localize-redux";
import Button from "./Button";
import React from "react";

export default function TwitterContactUsButton() {
    return (<Button round style={{backgroundColor: '#1DA1F2'}} href="https://twitter.com/messages/compose?recipient_id=1734095442130989056&ref_src=twsrc%5Etfw" target="_blank" without rel="noreferrer" >
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
            <Twitter style={{ marginTop: '-3px', marginRight: '5px' }} />
            <span>&nbsp;<Translate id="central.contactUs" /></span>
        </GridItem>
    </Button>);
}