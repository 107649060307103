export const adminConstants = {
  ACTION_GET_SCHOLARS: '@admin/ACTION_GET_SCHOLARS',
  ACTION_GET_USERS: '@admin/ACTION_GET_USERS',
  ACTION_GET_ORDER_DETAIL: '@admin/ACTION_GET_ORDER_DETAIL',
  ACTION_GET_ORDER_INFO: '@admin/ACTION_GET_ORDER_INFO',
  ACTION_GET_TRADING_SUMMARIES: '@admin/ACTION_GET_TRADING_SUMMARIES',
  ACTION_GET_USERS_VOLUME: '@admin/ACTION_GET_USERS_VOLUME',
  ACTION_GET_USER_ORDERS: '@admin/ACTION_GET_USER_ORDERS',
  ACTION_GET_USER_EXCHANGES: '@admin/ACTION_GET_USER_EXCHANGES',
  ACTION_GET_STATS_GROUP: '@admin/ACTION_GET_STATS_GROUP',
  ACTION_GET_ORDERS_GROUP: '@admin/ACTION_GET_ORDERS_GROUP',
  ACTION_GET_ACCOUNTS_GROUP: '@admin/ACTION_GET_ACCOUNTS_GROUP',
  ACTION_GET_HISTORIC_EMAIL: '@admin/ACTION_GET_HISTORIC_EMAIL',
  ACTION_GET_COMPANY_DATA: '@admin/ACTION_GET_COMPANY_DATA',
  ACTION_GET_PAIEMENTS: '@admin/ACTION_GET_PAIEMENTS',
  ACTION_GET_CURRENT_PRICES: '@admin/ACTION_GET_CURRENT_PRICES',
  ACTION_GET_SUBSCRIPTION_GROUP: '@admin/ACTION_GET_SUBSCRIPTION_GROUP',
};
