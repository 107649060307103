export const configConstants = {
  TAB_ORDERS: '@config/TAB_ORDERS',
  ORDERS_GRID_STATE_CHANGE_ACTION: '@config/ORDERS_GRID_STATE_CHANGE_ACTION',
  ORDERS_GRID_RESET: '@config/ORDERS_GRID_RESET',

  WORKERS_GRID_STATE_CHANGE_ACTION: '@config/WORKERS_GRID_STATE_CHANGE_ACTION',
  WORKERS_GRID_RESET: '@config/WORKERS_GRID_RESET',

  CARDS_GRID_RESET: '@config/CARDS_GRID_RESET',
  CARDS_GRID_STATE_CHANGE_ACTION: '@config/CARDS_GRID_STATE_CHANGE_ACTION',
  EXCHANGES_GRID_RESET: '@config/EXCHANGES_GRID_RESET',

  SUPPLIES_GRID_RESET: '@config/SUPPLIES_GRID_RESET',
  SUPPLIES_GRID_STATE_CHANGE_ACTION: '@config/SUPPLIES_GRID_STATE_CHANGE_ACTION',

  ASSETS_GRID_RESET: '@config/ASSETS_GRID_RESET',
  ASSETS_GRID_STATE_CHANGE_ACTION: '@config/ASSETS_GRID_STATE_CHANGE_ACTION',
  EXCHANGES_GRID_STATE_CHANGE_ACTION:
    '@config/EXCHANGES_GRID_STATE_CHANGE_ACTION',
  TRANSFER_GRID_STATE_CHANGE_ACTION:
      '@config/TRANSFER_GRID_STATE_CHANGE_ACTION',
  COLLAPSE_MENU: '@config/COLLAPSE_MENU',
  ACTION_CHANGE_THEME: '@config/ACTION_CHANGE_THEME',
  ACTION_CHANGE_NO_POPUP_TRADING: '@config/ACTION_CHANGE_NO_POPUP_TRADING',
  TAB_STRATEGY_VALUE: '@config/TAB_STRATEGY_VALUE',

  SCHOLARS_GRID_STATE_CHANGE_ACTION:
    '@config/SCHOLARS_GRID_STATE_CHANGE_ACTION',
  SCHOLARS_GRID_RESET: '@config/SCHOLARS_GRID_RESET',

  SCHOLAR_GRID_STATE_CHANGE_ACTION: '@config/SCHOLAR_GRID_STATE_CHANGE_ACTION',
  SCHOLAR_GRID_RESET: '@config/SCHOLAR_GRID_RESET',

  GET_NOTIFICATION: '@config/GET_NOTIFICATION',
  NOTIFICATION_ERROR: '@config/NOTIFICATION_ERROR',
};
