import React from 'react';

import GridContainer from '../../components/Grid/GridContainer.jsx';
import TableWallet from '../Accounts/Exchange/TableWallet';
import { bindActionCreators } from 'redux';
import { getExchanges } from '../../redux/user/actions';
import connect from 'react-redux/es/connect/connect';
import GridItem from '../../components/Grid/GridItem.jsx';
import { isLogged } from '../../helpers/general';
import transExchanges from '../../locales/exchanges';
import transExternals from '../../locales/externals.json';
import {Translate, withLocalize} from 'react-localize-redux';
import ExchangeContainer from '../Accounts/ExchangeContainer';
import { isNull } from '../SmartTrade/Common';
import { getAllExchanges } from '../../helpers/exchange';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transExchanges);
    this.props.addTranslation(transExternals);
  }
  componentDidMount() {
    const { auth } = this.props;
    if (isLogged(auth)) {
      this.props.getExchangesActions(true, false, 30, false);
    }
  }
  render() {
    const { exchanges } = this.props;

    const allExchanges = getAllExchanges(exchanges);

    if (isNull(exchanges) || exchanges.length === 0) return  <GridContainer>
      <GridItem md={1} container />
      <GridItem md={3} container justifyContent="center">
        <Tooltip
            title={<Translate id="externals.addExternal" />}
            enterDelay={200}
            leaveDelay={200}
        >
          <Button
              color="primary"
              fullWidth
              to={'/externals'}
              component={Link}
          >
            <Translate id="externals.addExternal" />
          </Button>
        </Tooltip>
      </GridItem>
    </GridContainer>;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
          <ExchangeContainer isDashboard={true} showButton={false} exchange={allExchanges} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TableWallet exchange={exchanges} />
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { user, authentication } = state;
  return {
    auth: authentication,
    exchanges: user.exchanges,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage),
);
