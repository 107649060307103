import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { isNull } from '../SmartTrade/Common';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import { Translate } from 'react-localize-redux';
import transConfirm from '../../locales/confirmation';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { getActiveCode } from '../../helpers/localize';
import { bindActionCreators } from 'redux';
import { hidePopupOptionsPaiementDispatch } from '../../redux/alert/alert.actions';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogOptionsPaiement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.addTranslation(transConfirm);
    this.state = {
      sendIsLock: false,
    };
  }

  handleClose = () => {
    const { hidePopupOptionsPaiementDispatchActions } = this.props;

    hidePopupOptionsPaiementDispatchActions();
    const { onClose } = this.props;

    if (!isNull(onClose)) onClose();
  };

  render() {
    const { codeLang, alert } = this.props;
    return (
      <Dialog
        open={alert.popupOptionsPaiement}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Translate
                        id="confirmation.popup2Abo"
                        options={{ renderInnerHtml: true }}
                      />
                      <br />
                      <br />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        fullWidth
                        to={'/subscriptions'}
                        component={Link}
                        color="primary"
                      >
                        <Translate id="confirmation.popup2BtnSubscribe" />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    alert: state.alert,
    codeLang: getActiveCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hidePopupOptionsPaiementDispatchActions: bindActionCreators(
      hidePopupOptionsPaiementDispatch,
      dispatch,
    ),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(DialogOptionsPaiement),
);
