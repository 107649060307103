import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import Button from '../../components/CustomButtons/Button.jsx';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { bindActionCreators } from 'redux';
import { register } from '../../redux/authentication/actions';
import connect from 'react-redux/es/connect/connect';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import validate from './RegisterValidate';
import { getTranslate, Translate } from 'react-localize-redux';
import Checkbox from '../../components/Form/Checkbox';
import { alertActions } from '../../redux/alert/alert.actions';
import { getActiveCode } from '../../helpers/localize';
import LanguageToggle from '../../components/Translate/LanguageToggle';
// import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

const styles = {};

class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
    };
  }
  componentDidMount() {
    // loadReCaptcha('6LeSFJYUAAAAAK5ZUbZxu6zkaoUknQdiTTcwXH3M');
  }
  // verifyCallback = recaptchaToken => {
  //   // Here you will get the final recaptchaToken!!!
  //   console.log(recaptchaToken, '<= your recaptcha token');
  // };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  handleClickShowPasswordConfirm = () => {
    this.setState((state) => ({
      showPasswordConfirm: !state.showPasswordConfirm,
    }));
  };

  handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      await this.register();
    }
  };

  register = () => {
    const { errors, translate, codeLang } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;

    if (!hasErrors) {
      const register = this.props.formRegister.values;
      register.Lang = codeLang;
      this.props.registerActions(register);
    } else {
      const { dispatch } = this.props;

      dispatch(
        alertActions.error(
          translate('confirmation.allFieldRequired') +
            translate('confirmation.acceptCGVU'),
        ),
      );
    }
  };

  render() {
    const { classes, errors, theme } = this.props;
    const hasErrors = Object.keys(errors).length !== 0;
    return (
      <div>
        <form onSubmit={this.register}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="email"
                type="email"
                label={<Translate id="email.emailAddress" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="password"
                type="password"
                label={<Translate id="email.password" />}
                fullWidth={true}
                component={ReactTextField}
                required
                InputProps={{
                  type: this.state.showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="confirmPassword"
                type="password"
                label={<Translate id="email.confirmPassword" />}
                fullWidth={true}
                component={ReactTextField}
                required
                InputProps={{
                  onKeyPress: this.handleKeyPress,
                  type: this.state.showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div style={{ marginTop: '12px' }}>
                <LanguageToggle withName={true} />
              </div>
            </GridItem>
            {/*<GridItem xs={12} sm={12} md={12}>*/}
            {/*  <Field*/}
            {/*    name="newsletter"*/}
            {/*    id="newsletter"*/}
            {/*    component={Checkbox}*/}
            {/*    style={{ padding: '6px' }}*/}
            {/*    type="checkbox"*/}
            {/*  />*/}
            {/*  <label htmlFor="newsletter">*/}
            {/*    <Translate id="email.newsletter" />*/}
            {/*  </label>*/}
            {/*</GridItem>*/}
            {/*<GridItem xs={12} sm={12} md={12}>*/}
            {/*  <Field*/}
            {/*    name="cgvu"*/}
            {/*    id="cgvu"*/}
            {/*    component={Checkbox}*/}
            {/*    style={{ padding: '6px' }}*/}
            {/*    type="checkbox"*/}
            {/*  />*/}
            {/*  <label htmlFor="cgvu">*/}
            {/*    <Translate*/}
            {/*      id="email.cgvu"*/}
            {/*      options={{ renderInnerHtml: true }}*/}
            {/*      data={{*/}
            {/*        classLink:*/}
            {/*          'link-text ' + (theme === 'dark' ? 'dark' : 'light'),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </label>*/}
            {/*</GridItem>*/}
          </GridContainer>
          <GridContainer justifyContent="center">
            <Button
              onClick={(e) => this.register(e)}
              className={classes.buttonCenter}
              color="primary"
              disabled={hasErrors} // || submitting || pristine}
              round
            >
              <Translate id="email.register" />
            </Button>
            {/*<ReCaptcha style={{bottom: '90px'}}*/}
            {/*sitekey="6LeSFJYUAAAAAK5ZUbZxu6zkaoUknQdiTTcwXH3M"*/}
            {/*action="login"*/}
            {/*verifyCallback={this.verifyCallback}*/}
            {/*/>*/}
          </GridContainer>
        </form>
      </div>
    );
  }
}

RegisterContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { form, localize, config } = state;
  return {
    formRegister: form.register,
    theme: config.theme,
    errors: getFormSyncErrors('register')(state),
    translate: getTranslate(localize),
    codeLang: getActiveCode(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    registerActions: bindActionCreators(register, dispatch),
  };
};

let registerContainer = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RegisterContainer),
);
export default reduxForm({
  form: 'register', // a unique identifier for this form
  validate: validate,
  // asyncValidate
})(registerContainer);
