import React from 'react';

import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import CustomTabs from '../../components/CustomTabs/CustomTabs.jsx';

import LoginContainer from './LoginContainer.jsx';
import RegisterContainer from './RegisterContainer.jsx';

import { withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import transEmail from '../../locales/email.json';
import transConfirmation from '../../locales/confirmation.json';
import AlokahPng from '../../assets/img/cta/background/Alokah.png';
import { darkColorTransparent, lightColorTransparent } from '../../assets/jss/material-dashboard-react';
import {connect} from "react-redux";

class IdentificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.addTranslation(transEmail);
    this.props.addTranslation(transConfirmation);
  }
  render() {
    const { location } = this.props;
    const theme = this.props.theme;
    const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;

    const value =
      location.search && location.search.indexOf('login') !== -1 ? 1 : 0;
    let tabs = [
      {
        tabName: <Translate id="email.login" />,
        // tabIcon: BugReport,
        tabContent: <LoginContainer />,
      },
    ];
    tabs.unshift({
      tabName: <Translate id="email.register" />,
      disabled: true,
      // tabIcon: Code,
      tabContent: <RegisterContainer />,
    });
    return (
      <GridContainer>
        <GridItem md={3} />
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <CustomTabs
            backgroundStyle={{ backgroundPosition: 'right', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(${color}, ${color}), url(${AlokahPng})`}}
            value={value}
            title=""
            headerColor="primary"
            tabs={tabs}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;
  return {
    theme: config.theme,
  };
}


export default connect(mapStateToProps, null)(withLocalize(IdentificationPage));
