import React from 'react';
import { alertPopup } from '../../redux/alert/alert.actions';
import connect from 'react-redux/es/connect/connect';
import { getTranslate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import LittleButton from '../../components/CustomButtons/LittleButton';
import Create from '@material-ui/icons/Create';
import { DeleteForever } from '@material-ui/icons';
import { deleteWorker } from '../../redux/worker/actions';
import {
  isAdminSelector,
  userIdSelector,
} from '../../redux/authentication/selector';

class OrderMenuButtons extends React.Component {
  render() {
    const { worker, userId, isAdmin } = this.props;
    const canIDelete = worker.CreatedBy === userId || isAdmin;
    return (
      <>
        <LittleButton
          tooltip={'Update'}
          size="xs"
          color="warning"
          icon={<Create />}
          to={'/worker/' + worker.WorkerId}
        />
        {canIDelete && (
          <LittleButton
            tooltip={'Delete'}
            size="xs"
            color="danger"
            icon={<DeleteForever />}
            onClick={() => {
              this.props.alertActions({
                type: 'confirm',
                then: (result) => {
                  if (result.value)
                    this.props.deleteWorkerActions(worker.WorkerId);
                },
              });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { localize } = state;
  return {
    translate: getTranslate(localize),
    userId: userIdSelector(state),
    isAdmin: isAdminSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    deleteWorkerActions: bindActionCreators(deleteWorker, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderMenuButtons);
