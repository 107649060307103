import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../../../components/CustomButtons/Button.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { postExternal, putExternal } from '../../../redux/user/actions';
import { Translate } from 'react-localize-redux';
import { isNull } from '../../SmartTrade/Common';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import Warning from '../../../components/Typography/Warning';
import WarningIcon from '@material-ui/icons/Warning';

import {
  isAdminSelector,
  userIdSelector,
} from '../../../redux/authentication/selector';
import { isDev } from '../../../helpers/fetcher';
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {successColor} from "../../../assets/jss/material-dashboard-react";
import MySwal from "sweetalert2";
import {faEgg} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FormExternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
    };
  }
  postExternal = () => {
    const externalWallet = this.props.myForm.values;
    const { wallet } = this.props;

    if (isNull(wallet?.WalletId)) this.props.postNewWallet(externalWallet);
    else this.props.putNewWallet(externalWallet);
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { myForm, wallet } = this.props;
    const isUpdate = !isNull(wallet);
    const showLogin = isDev();
    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="Name"
                label={<Translate id="central.name" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {!showLogin && (<Field
                    name="Address"
                    label={'Public Address'}
                    fullWidth={true}
                    component={ReactTextField}
                    required
                />)}
              {showLogin && (
              <CustomTabs
                  title=""
                  withCard={false}
                  headerColor="primary"
                  value={this.state.value}
                  onChange={this.handleChange}
                  tabs={[{
                    tabName: 'Address',
                    tabContent:
                        <GridItem xs={12} sm={12} md={12}>
                          <Field
                              name="Address"
                              label={'Public Address'}
                              fullWidth={true}
                              component={ReactTextField}
                              // required
                          />
                        </GridItem>,
                  },{
                    tabName: 'Login CTA',
                    tabContent:
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <Field
                            name="Login"
                            label="Login"
                            fullWidth={true}
                            component={ReactTextField}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Field
                            name="Password"
                            type="Password"
                            label="Password"
                            fullWidth={true}
                            component={ReactTextField}
                            InputProps={{
                              type: this.state.showPassword ? 'text' : 'password',
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                    >
                                      {this.state.showPassword ? (
                                          <VisibilityOff />
                                      ) : (
                                          <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                              ),
                            }}
                        />
                        <Warning severity="success" color="success">
                          <div className="icons-text">
                            <WarningIcon />
                          </div>
                          <Translate id="externals.disclaimer" />
                        </Warning>
                      </GridItem>
                    </>,
                  }]}
                />
              )}
            </GridItem>


          </GridContainer>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={this.postExternal}
            color="primary"
            round
            disabled={myForm && myForm.syncErrors}
          >
            {!isUpdate && <Translate id="externals.addWallet" />}
            {isUpdate && <Translate id="externals.updateWallet" />}
          </Button>
        </GridContainer>
        {/*<div style={{float: 'right'}}>*/}
        {/*  <Button size={'sm'} color="success" round onClick={async () => {*/}
        {/*    await MySwal.fire({*/}
        {/*      title: 'Claim',*/}
        {/*      html: 'Send this message "I found the egg : f07918cb" to reply of last tweet',*/}
        {/*      confirmButtonText: 'Ok',*/}
        {/*      minWidth: '800px',*/}
        {/*      confirmButtonColor: successColor,*/}
        {/*    });*/}
        {/*  }}><FontAwesomeIcon icon={faEgg} />&nbsp;Claim</Button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

let formExternal = reduxForm({
  form: 'formExternal', // a unique identifier for this form
  validate,
  // asyncValidate
})(FormExternal);

const mapStateToProps = (state, props) => {
  const { form } = state;
  const { wallet } = props;
  return {
    initialValues: wallet,
    myForm: form.formExternal,
    userId: userIdSelector(state),
    isAdmin: isAdminSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postNewWallet: bindActionCreators(postExternal, dispatch),
    putNewWallet: bindActionCreators(putExternal, dispatch),
  };
};

formExternal = connect(mapStateToProps, mapDispatchToProps)(formExternal);

export default formExternal;
