import GridItem from "../Grid/GridItem";
import {Twitter} from "@material-ui/icons";
import {Translate} from "react-localize-redux";
import Button from "./Button";
import React from "react";

export default function TwitterFollowButton() {
    return (<Button style={{backgroundColor: '#1DA1F2'}} href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&screen_name=CTAGameCom" target="_blank" without rel="noreferrer" >
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
            <Twitter style={{ marginTop: '-3px', marginRight: '5px' }} />
            <span>&nbsp;<Translate id="central.follow" /></span>
        </GridItem>
    </Button>);
}
