import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// core components
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';

import customTabsStyle from '../../assets/jss/material-dashboard-react/components/customTabsStyle.jsx';

class CustomTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value : 0,
    };
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps && this.props && prevProps.value !== this.props.value)
      this.setState({ value: this.props.value });
  };

  handleChange = (event, value) => {
    const { onChange } = this.props;
    this.setState({ value });

    if (
      onChange !== undefined &&
      onChange != null &&
      typeof onChange === 'function'
    )
      onChange(event, value);
  };

  displayTabs = (tabs) => (tabs.map((prop, key) => {
      if (key === this.state.value) {
        return <div key={key}>{prop.tabContent}</div>;
      }
      return null;
    }));
  displayHeaderTabs = ({tabs, classes}) => (<Tabs
      value={this.state.value}
      onChange={this.handleChange}
      classes={{
        root: classes.tabsRoot,
        indicator: classes.displayNone,
        scrollButtons: classes.displayNone,
      }}
      // scrollable={true}
      scrollButtons="auto"
  >
    {tabs.map((prop, key) => {
      let icon = {};
      if (prop.tabIcon) {
        icon = {
          icon: <prop.tabIcon />,
        };
      }
      return (
          <Tab
              classes={{
                root: classes.tabRootButton,
                // labelContainer: classes.tabLabelContainer,
                // label: classes.tabLabel,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              key={key}
              label={prop.tabName}
              {...icon}
          />
      );
    })}
  </Tabs>);

  render() {
    const { backgroundStyle, classes, headerColor, plainTabs, tabs, title, rtlActive, withCard = true } =
      this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive,
    });
    if (withCard === false) return <>
      {this.displayHeaderTabs({tabs, classes})}
      {this.displayTabs(tabs)}
    </>;
    return (
        // <Card plain={plainTabs} style={{backgroundImage: 'linear-gradient(0deg, #1e293b, rgba(15, 23, 42, 0.9)),url("https://cta-fansite.com/wp-content/uploads/2022/03/FMrL-TtXMAAffBe-1024x576.jpg")'}}>
        // <Card plain={plainTabs} style={{backgroundImage: 'linear-gradient(0deg, #1e293b, rgba(15, 23, 42, 0.6)),url("http://torrent.bozzano.fr/api/public/dl/4fJmSlfy?inline=true")'}}>
        // <Card>
      //   <img src={require('../../assets/img/cta/cards_characters/Chara-baram-24b.png')} alt="background" />
      //     <img src={require('../../assets/img/cta/cards_characters/Alokah.png')} alt="background" />
      //     <img src={'http://torrent.bozzano.fr/api/public/dl/JxJw-nHD?inline=true'} alt="background" />
      // <CardBody style={{background: 'url("../assets/img/cta/cards_characters/Alokah.png") no-repeat center center fixed'}}>
      // <CardBody style={{background: 'url("../assets/img/cta/cards_characters/Alokah.png") no-repeat center center fixed'}}>
    <Card plain={plainTabs} style={backgroundStyle}>
        <CardHeader color={headerColor} plain={plainTabs}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          {this.displayHeaderTabs({tabs, classes})}
        </CardHeader>
        <CardBody>
          {this.displayTabs(tabs)}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired,
    }),
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};

export default withStyles(customTabsStyle)(CustomTabs);
