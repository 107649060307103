import { workerConstants } from './constants';
import { isNull } from '../../views/SmartTrade/Common';

const initialState = { workers: [], worker: null };

export function worker(state = initialState, action) {
  switch (action.type) {
    case workerConstants.ACTION_GET_WORKERS:
    case workerConstants.ACTION_LOCAL_WORKERS:
      return {
        ...state,
        workers: action.payload,
      };
    case workerConstants.ACTION_GET_WORKER:
    case workerConstants.ACTION_PUT_WORKER: {
      const worker = action.payload;
      if (isNull(state.workers)) {
        return {
          ...state,
          worker: worker,
          workers: [worker],
        };
      }
      const index = state.workers.findIndex(
        (x) => x.WorkerId === worker?.WorkerId,
      );
      if (index !== -1) state.workers[index] = worker;
      return {
        ...state,
        worker: worker,
        workers: [...state.workers],
      };
    }
    case workerConstants.ACTION_POST_WORKER:
      return {
        ...state,
        workers: state.workers.concat(action.payload),
      };
    case workerConstants.ACTION_DELETE_WORKER:
      return {
        ...state,
        workers: state.workers.filter(
          (x) => x.WorkerId !== action.payload.WorkerId,
        ),
      };
    default:
      return state;
  }
}
