import React from 'react';

// @material-ui/core components
import Button from '../../components/CustomButtons/Button.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';

import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import { withLocalize } from 'react-localize-redux';
import GridItem from '../../components/Grid/GridItem.jsx';
import { bindActionCreators } from 'redux';
import { addFakeTelegram } from '../../redux/authentication/actions';
import { isDev } from '../../helpers/fetcher';

class NotificationsContainer extends React.Component {
  render() {
    const { id } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4>
              <Translate
                id="settings.descriptionTelegram"
                options={{ renderInnerHtml: true }}
              />
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              disabled
              color="primary"
              round
              target={'_blank'}
              href={
                (isDev()
                  ? 'https://telegram.me/CTAGameBot?start='
                  : 'https://telegram.me/CTAGameBot?start=') + id
              }
              onClick={(event) => {
                // event.preventDefault();
                // window.open('https://telegram.me/WallOfTradersBot?start=' + id);
                this.props.addFakeTelegramActions();
              }}
            >
              <Translate id="settings.connectTelegram" />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication } = state;

  return {
    id: authentication.auth.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFakeTelegramActions: bindActionCreators(addFakeTelegram, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer),
);
