import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// core components
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Checkbox from '@material-ui/core/Checkbox';

import { bindActionCreators } from 'redux';
import { getUsers, IsAdmin } from '../../../redux/admin/actions';
import TableUser from './TableUser';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/CustomButtons/Button';
import LineChartDataTime from '../../../components/Charts/LineChartDataTime';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      withOrders: false,
      withStats: false,
      showGraph: false,
    };
  }
  componentDidMount() {
    this.props.IsAdminActions();
    this.chargeData();
  }

  chargeData = () => {
    const { withOrders, withStats } = this.state;
    this.props.getUsersActions(withOrders, withStats);
  };

  handleChange = (event) => {
    const val = !this.state[event.target.name];
    this.setState({ [event.target.name]: val });
  };

  render() {
    const { users } = this.props;
    const { showGraph } = this.state;
    let graphDataByMonth = [];
    let graphDataByDay = [];
    let nbUsersByDay = [];
    let nbUsersByMonth = [];

    if (showGraph) {
      users.forEach((user) => {
        //"2020-05-16T01:20:29.12"
        const split = user.DateCreated.split('-');
        const dateMonth = split[0] + '-' + split[1] + '-01';
        const dateDay = user.DateCreated.split('T')[0];
        if (dateMonth in graphDataByMonth) {
          graphDataByMonth[dateMonth] += 1;
        } else {
          graphDataByMonth[dateMonth] = 1;
        }
        if (dateDay in graphDataByDay) {
          graphDataByDay[dateDay] += 1;
        } else {
          graphDataByDay[dateDay] = 1;
        }
      });
      for (const date in graphDataByDay) {
        nbUsersByDay.push({
          date: date,
          total: graphDataByDay[date],
        });
      }
      nbUsersByDay = nbUsersByDay.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      for (const date in graphDataByMonth) {
        nbUsersByMonth.push({
          date: date,
          total: graphDataByMonth[date],
        });
      }
      nbUsersByMonth = nbUsersByMonth.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    }

    return (
      <GridContainer>
        <GridItem
          container
          xs={12}
          sm={12}
          md={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          Nombre utilisateur: {users.length}
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <FormControlLabel
            control={<Checkbox id="withOrders" name="withOrders" />}
            onChange={(e) => this.handleChange(e)}
            label="Avec Orders"
          />
          <FormControlLabel
            control={<Checkbox id="withStats" name="withStats" />}
            onChange={(e) => this.handleChange(e)}
            label="Avec Stats"
          />
          <Button color={'primary'} onClick={this.chargeData}>
            Go
          </Button>
        </GridItem>
        <Button
          color={'primary'}
          onClick={() => this.setState({ showGraph: !showGraph })}
        >
          ShowGraph
        </Button>
        {showGraph && (
          <GridItem xs={12} sm={12} md={12}>
            Nb User By Day
            {nbUsersByDay && nbUsersByDay.length > 0 && (
              <LineChartDataTime
                data={[...nbUsersByDay]}
                name={'nbUserByDay'}
              />
            )}
            Nb User By Month
            {nbUsersByMonth && nbUsersByMonth.length > 0 && (
              <LineChartDataTime
                data={[...nbUsersByMonth]}
                name={'nbUsersByMonth'}
              />
            )}
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <TableUser users={users} />
        </GridItem>
      </GridContainer>
    );
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { admin } = state;
  return {
    users: admin.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersActions: bindActionCreators(getUsers, dispatch),
    IsAdminActions: bindActionCreators(IsAdmin, dispatch),
  };
};

const connectedUsers = connect(mapStateToProps, mapDispatchToProps)(UsersPage);
// export { connectedApp as App };
export default connectedUsers;

// export default withStyles(styles)(UserProfile);
