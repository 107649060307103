import React from "react";
import {getAssetsTokenType, getTableExchange} from "../../helpers/exchange";
import {isEmpty, isNull, parseToFloat} from "../SmartTrade/Common";
import CardRarity from "../../components/CTA/CardRarity";
import {listArkhome, listRarity} from "../../helpers/general";
import Arkhome from "../../components/CTA/Arkhome";

export default function AssetsContainer({exchange, directAsset}) {
    const getCountCard = (list) => {
        // console.log(list);
        return (
            list?.reduce((total, current) => {
                return total + (current?.Count ?? 0);
            }, 0) ?? 1
        );
    };
    const displayTotalValue = (FP, LS) => {
        if (FP === 0 && LS === 0) return (null);
        return (
            <div style={{ display: 'inline', float: 'right' }}>
                FP: {FP.toLocaleString()}$ / AVG: {LS.toLocaleString()}$
            </div>
        );
    };
    const getTotalFP = (list) =>
        list?.reduce(
            (total, current) =>
                total +
                (current?.Count ?? 0) * (current?.FP?.V ?? 0),
            0,
        ) ?? 0;
    const getTotalLS = (list) =>
        list?.reduce(
            (total, current) =>
                total +
                (current?.Count ?? 0) * (current?.LSDays?.A ?? 0),
            0,
        ) ?? 0;

    const showTotalValue = (list) => {
        const totalValueFP = parseToFloat(getTotalFP(list), 0.01);
        const totalValueLS = parseToFloat(getTotalLS(list), 0.01);
        return displayTotalValue(totalValueFP, totalValueLS);
    };

    const displayCards = (cards, label = 'NFT') => {
        let nbByRarityNonFoil = [];
        for (const card of cards.filter((x) => !x.Foil)) {
            const rarity = listRarity.indexOf(card?.Rarity);
            if (isNull(rarity)) continue;
            let elem = nbByRarityNonFoil.find((x) => x.rarity === rarity);
            if (isNull(elem)) {
                elem = { rarity: rarity, cards: [] };
                nbByRarityNonFoil.push(elem);
            }
            elem.cards.push(card);
        }
        nbByRarityNonFoil.sort((a, b) => a.rarity - b.rarity);
        let nbByRarityFoil = [];
        for (const card of cards.filter((x) => x.Foil)) {
            const rarity = listRarity.indexOf(card?.Rarity);
            if (isNull(rarity)) continue;
            let elem = nbByRarityFoil.find((x) => x.rarity === rarity);
            if (isNull(elem)) {
                elem = { rarity: rarity, cards: [] };
                nbByRarityFoil.push(elem);
            }
            elem.cards.push(card);
        }
        nbByRarityFoil.sort((a, b) => a.rarity - b.rarity);

        let nbByArkhome = [];
        for (const card of cards) {
            const arkhome = listArkhome.indexOf(card?.Arkhome);
            if (isNull(arkhome)) continue;
            let elem = nbByArkhome.find((x) => x.arkhome === arkhome);
            if (isNull(elem)) {
                elem = { arkhome: arkhome, cards: [] };
                nbByArkhome.push(elem);
            }
            elem.cards.push(card);
        }
        nbByArkhome.sort((a, b) => a.arkhome - b.arkhome);

        let nbRankGrade = [];
        for (const card of cards) {
            const rankGrade = isEmpty(card?.Grade) ? card?.Rank.toLocaleString() : card?.Grade;
            if (isNull(rankGrade)) continue;
            let elem = nbRankGrade.find((x) => x.rankGrade === rankGrade);
            if (isNull(elem)) {
                elem = { rankGrade: rankGrade, cards: [] };
                nbRankGrade.push(elem);
            }
            elem.cards.push(card);
        }
        nbRankGrade.sort((a, b) => {
            if (a.rankGrade < b.rankGrade) return -1;
            else if (a.rankGrade > b.rankGrade) return 1;
            return 0;
        });
        return (
            <>
                {nbByRarityNonFoil.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-8px',
                        }}
                    >
                        {label}:
                        <CardRarity value={'nonfoil'} />:
                        {nbByRarityNonFoil.map((rarity, key) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                key={key}
                            >
                                <CardRarity value={listRarity[rarity.rarity]} />{' '}
                                <b>{getCountCard(rarity.cards).toLocaleString()}</b>
                            </div>
                        ))}
                        {/*{showTotalValue(cards.filter((x) => !x.Foil))}*/}
                    </div>
                )}
                {nbByRarityFoil.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-8px',
                        }}
                    >
                        {label}:
                        <CardRarity value={'foil'} />:
                        {nbByRarityFoil.map((rarity, key) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                key={key}
                            >
                                <CardRarity value={listRarity[rarity.rarity]} />{' '}
                                <b>{getCountCard(rarity.cards).toLocaleString()}</b>
                            </div>
                        ))}
                    </div>
                )}
                {nbByArkhome.length > 0 && (
                    <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-8px',
                        }}
                    >
                        {label}:&nbsp;
                        {nbByArkhome.slice(0,7).map((arkhome, key) => (
                            <>
                                <Arkhome value={listArkhome[arkhome.arkhome]} key={key} />
                                <b>{getCountCard(arkhome.cards).toLocaleString()}</b>
                                &nbsp;&nbsp;
                            </>
                        ))}
                    </div>
                    <div
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '-8px',
                    }}
                            >
                        {label}:&nbsp;
                        {nbByArkhome.slice(7).map((arkhome, key) => (
                            <>
                                <Arkhome value={listArkhome[arkhome.arkhome]} key={key} />
                                <b>{getCountCard(arkhome.cards).toLocaleString()}</b>
                                &nbsp;&nbsp;
                            </>
                        ))}
                        </div>
                    </>
                )}
                {nbRankGrade.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-8px',
                            marginTop: '4px'
                        }}
                    >
                        {label}:&nbsp;
                        {nbRankGrade.map((rankGrade, key) => (
                            <>
                                &nbsp;&nbsp;{rankGrade.rankGrade}:&nbsp;
                                <b>{getCountCard(rankGrade.cards).toLocaleString()}</b>
                                &nbsp;&nbsp;
                            </>
                        ))}
                    </div>
                )}
            </>
        );
    };

    const getAssets = (exchange, tokenType) => {
        return directAsset ? getAssetsTokenType(exchange, tokenType) : getTableExchange(exchange, tokenType);
    }

    const assets = getAssets(exchange);
    const cards = getAssets(exchange, 0).filter((x) => x.Numbering !== 0);
    const cardsNoNFT = getAssets(exchange, 0).filter((x) => x.Numbering === 0);
    const mintPasses = getAssets(exchange, 1);
    const presalePacks = getAssets(exchange, 2);
    const souvenirCards = getAssets(exchange, 3);
    const chests = getAssets(exchange, 5);
    const shards = getAssets(exchange, 6);
    let mintPassByName = [];
    for (const mp of mintPasses) {
        const name = mp?.Name;
        if (isNull(name)) continue;
        let nb = mintPasses.find((x) => x.Name === name)?.Count ?? 0;
        const elem = mintPassByName.find((x) => x.name === name);
        if (isNull(elem)) mintPassByName.push({ name: name, nb: nb });
        else elem.nb += nb;
    }
    const totalFP = parseToFloat(getTotalFP(assets), 0.01);
    const totalLS = parseToFloat(getTotalLS(assets), 0.01);
    const totalMintPasses = showTotalValue(mintPasses);
    return (
        <>
            {assets && assets.length > 0 && (
                <div>
                    Nb Assets: {getCountCard(assets).toLocaleString()}{' '}
                    {displayTotalValue(totalFP, totalLS)}
                </div>
            )}
            {cards.length > 0 && (
                <div>
                    Nb Cards NFT: {getCountCard(cards).toLocaleString()}{' '}
                    {showTotalValue(cards)}{' '}
                </div>
            )}
            {cardsNoNFT.length > 0 && (
                <div>
                    Nb Cards NoNFT: {getCountCard(cardsNoNFT).toLocaleString()}{' '}
                    {showTotalValue(cardsNoNFT)}{' '}
                </div>
            )}
            {souvenirCards.length > 0 && (
                <div>
                    Nb Souvenir Cards:{' '}
                    {getCountCard(souvenirCards).toLocaleString()}{' '}
                    {showTotalValue(souvenirCards)}{' '}
                </div>
            )}
            {chests.length > 0 && (
                <div>
                    Chests/Shards: {getCountCard(chests).toLocaleString()}{' '}
                    {showTotalValue(chests)}{' '}
                </div>
            )}
            {shards.length > 0 && (
                <div>
                    Shards: {getCountCard(shards).toLocaleString()}{' '}
                    {showTotalValue(shards)}{' '}
                </div>
            )}
            {presalePacks.length > 0 && (
                <div>
                    Nb Presale Pack:{' '}
                    {getCountCard(presalePacks).toLocaleString()}{' '}
                    {showTotalValue(presalePacks)}
                </div>
            )}
            {mintPassByName.map((mp, key) => (
                <div key={key}>
                    {mp.name}
                    {mp.nb > 1 ? ': ' + mp.nb : ''}
                    &nbsp;
                    {showTotalValue(
                        mintPasses.filter((x) => x.Name === mp.name),
                    )}
                </div>
            ))}
            {mintPassByName.length > 0 && totalMintPasses && (
                <div>Total Mint Passes: {totalMintPasses}</div>
            )}
            <div>&nbsp;</div>
            {displayCards(cards)}
            <div>&nbsp;</div>
            {displayCards(cardsNoNFT, 'NoNFT')}
        </>
    )
}