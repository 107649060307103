import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../../components/CustomButtons/Button.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import ReactTextField from '../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { postWorker, putWorker } from '../../redux/worker/actions';
import { Translate } from 'react-localize-redux';
import { isNull } from '../SmartTrade/Common';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { workersFormSelector } from '../../redux/worker/selector';
import ReactSelect from '../../components/Form/ReactSelectUI';
import { eWorkerType } from '../../helpers/general';

class FormWorker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false,
    };
  }
  postWorker = () => {
    const { worker, myForm } = this.props;
    if (isNull(worker) || isNull(worker.WorkerId))
      this.props.postWorkerActions(myForm);
    else this.props.putWorkerActions(myForm);
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { myForm, worker } = this.props;
    const isUpdate = !isNull(worker);
    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="Name"
                label={<Translate id="central.name" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: 20 }}>
              <Field
                name="WorkerType"
                label={'WorkerType'}
                options={eWorkerType
                  .map((item, index) => ({ value: index, label: item }))
                  .slice(1)}
                fullWidth={true}
                component={ReactSelect}
                required
                // validate={[required]}
                //multi
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="Login"
                label="Login"
                fullWidth={true}
                component={ReactTextField}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="Password"
                type="Password"
                label="Password"
                fullWidth={true}
                component={ReactTextField}
                InputProps={{
                  type: this.state.showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="Description"
                label="Description"
                fullWidth={true}
                component={ReactTextField}
              />
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={this.postWorker}
            color="primary"
            round
            disabled={myForm && myForm.syncErrors}
          >
            {!isUpdate && 'Add'}
            {isUpdate && 'Update'}
          </Button>
        </GridContainer>
      </div>
    );
  }
}

let formWorker = reduxForm({
  form: 'formWorker', // a unique identifier for this form
  validate,
  // asyncValidate
})(FormWorker);

const mapStateToProps = (state, props) => {
  const { worker } = props;
  return {
    initialValues: worker,
    myForm: workersFormSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postWorkerActions: bindActionCreators(postWorker, dispatch),
    putWorkerActions: bindActionCreators(putWorker, dispatch),
  };
};

formWorker = connect(mapStateToProps, mapDispatchToProps)(formWorker);

export default formWorker;
