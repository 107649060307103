import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import {
  cancelAll,
  deleteEntryErrors,
  getAvailableAmount,
  getOrders,
  panicSellAll,
  refuseAllWaitValid,
} from '../../redux/order/actions';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import SwitchUI from '@material-ui/core/Switch/Switch';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { Translate } from 'react-localize-redux';
import OrdersContainer from './OrdersContainer';
import InputLabel from '@material-ui/core/InputLabel';
import TableUIOrders from './TableUIOrders';
import CustomTabs from '../../components/CustomTabs/CustomTabs.jsx';
import {
  Cached,
  List,
  ViewModule,
  Clear,
  DeleteForever,
  Eject,
} from '@material-ui/icons';
import {
  changeTabOrders,
  createOrdersGridAction,
  resetOrderColumns,
} from '../../redux/config/actions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withWidth } from '@material-ui/core';
import { nbColumn } from '../../helpers/general';
import Circular from '../../components/Progress/CircularProgress';
import { getStarts, isActiveOrder } from '../../helpers/order';
import { alertPopup } from '../../redux/alert/alert.actions';
import { getExchanges } from '../../redux/user/actions';
import { isNull } from '../SmartTrade/Common';

const refreshSec = 60;

class Orders extends React.PureComponent {
  constructor(props) {
    super(props);
    const showTable = localStorage.getItem('showTable');
    this.state = {
      autoRefresh: false,
      showTable: showTable === null || showTable !== 'false',
    };
  }
  handleChange = (event, newValue) => {
    this.props.changeTabOrdersActions(newValue);
  };

  toggleShowTable = (showTable) => {
    localStorage.setItem('showTable', showTable ? 'true' : 'false');
    this.setState({ ...this.state, showTable: showTable });
  };

  hasErrorEntriesFunc = (orders) => {
    if (isNull(orders)) return false;
    for (let order of orders) {
      const starts = getStarts(order.Triggers);
      for (let start of starts) {
        if (start.State === 5) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { forceTable, isAdmin, orders, userId, tabOrders } = this.props;
    const { showTable } = this.state;
    const isMobile = nbColumn(this.props.width) < 2;
    let showTableF = isMobile ? false : forceTable ? forceTable : showTable;
    const isHistoric = [2, 3].includes(tabOrders);
    const isTemplate = [4].includes(tabOrders);
    const ordersFilter =
      orders &&
      orders.filter((x) =>
        isHistoric ? !isActiveOrder(x.State) : isActiveOrder(x.State),
      );
    const ordersTemplate = orders && orders.filter((x) => x.State === 9);
    const hasOrderPending =
      ordersFilter && ordersFilter.filter((x) => x.State === 0).length > 0;
    const hasErrorEntries = this.hasErrorEntriesFunc(ordersFilter);
    return (
      <GridItem container>
        <GridItem container>
          <GridItem
            container
            xs={12}
            sm={8}
            md={8}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div>
              {!isMobile && (
                <span>
                  <Button
                    onClick={() => this.toggleShowTable(true)}
                    size="sm"
                    color={showTable ? 'primary' : 'default'}
                  >
                    <List />
                  </Button>
                  <Button
                    onClick={() => this.toggleShowTable(false)}
                    size="sm"
                    color={!showTable ? 'primary' : 'default'}
                  >
                    <ViewModule />
                  </Button>
                </span>
              )}
              {showTable && (
                <span>
                  <Button
                    onClick={() => {
                      localStorage.removeItem('ordersGrid');
                      this.props.resetOrderColumnsActions();
                    }}
                    size="sm"
                  >
                    <Cached />
                    &nbsp;
                    <Translate id="orders.resetTable" />
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.clearFilterOrderActions();
                    }}
                    size="sm"
                  >
                    <Clear />
                    &nbsp;
                    <Translate id="orders.resetFilter" />
                  </Button>
                </span>
              )}
            </div>
            {!isHistoric && (
              <div>
                {hasErrorEntries && (
                  <Button
                    onClick={() => {
                      this.props.alertActions({
                        type: 'confirm',
                        then: (result) => {
                          if (result.value) {
                            this.props.deleteEntryErrorsActions(userId);
                          }
                        },
                      });
                    }}
                    size="sm"
                  >
                    <DeleteForever />
                    &nbsp;
                    <Translate id="orders.deleteEntryError" />
                  </Button>
                )}
                {hasOrderPending && (
                  <Button
                    onClick={() => {
                      this.props.alertActions({
                        type: 'confirm',
                        then: (result) => {
                          if (result.value) {
                            this.props.refuseAllWaitValidActions(userId);
                          }
                        },
                      });
                    }}
                    size="sm"
                  >
                    <DeleteForever />
                    &nbsp;
                    <Translate id="orders.refuseAllWaitValid" />
                  </Button>
                )}
                <Button
                  onClick={() => {
                    this.props.alertActions({
                      type: 'confirm',
                      then: (result) => {
                        if (result.value) {
                          this.props.cancelAllActions(tabOrders, userId);
                        }
                      },
                    });
                  }}
                  color="danger"
                  size="sm"
                >
                  <DeleteForever />
                  &nbsp;
                  <Translate id="orders.cancelAllActions" />
                </Button>
                {!isTemplate && (
                  <Button
                    onClick={() => {
                      this.props.alertActions({
                        type: 'confirm',
                        then: (result) => {
                          if (result.value) {
                            this.props.panicSellAllActions(tabOrders, userId);
                          }
                        },
                      });
                    }}
                    size="sm"
                    color="rose"
                  >
                    <Eject />
                    &nbsp;
                    <Translate id="orders.panicSellAllActions" />
                  </Button>
                )}
                {/*Is historique*/}
                {/*{this.state.value === 1 && (*/}
                {/*<Button*/}
                {/*onClick={() => {*/}
                {/*if (isHistoric) {*/}
                {/*//Todo filter order historiaue stqrt erreur*/}
                {/*}*/}
                {/*}}*/}
                {/*size="sm"*/}
                {/*>*/}
                {/*<DeleteForever />*/}
                {/*&nbsp;*/}
                {/*<Translate id="orders.hideEntryError" />*/}
                {/*</Button>*/}
                {/*)}*/}
              </div>
            )}
          </GridItem>
          {!isHistoric && (
            <GridItem
              container
              xs={12}
              sm={4}
              md={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <InputLabel style={{ fontSize: '0.75rem' }} component="span">
                <Translate id="orders.autoRefresh" />
                <SwitchUI
                  checked={this.state.autoRefresh}
                  onChange={() => {
                    this.setState({ autoRefresh: !this.state.autoRefresh });
                  }}
                  color="primary"
                />
              </InputLabel>
              {this.state.autoRefresh && (
                <Circular
                  refreshSec={refreshSec}
                  actionToDo={() => {
                    //Ne fonctionne pas on ne peux pas rafraichir les historics
                    // const pIsHistoric = value === 0 ? false : true;
                    this.props.getOrdersActions(tabOrders);
                    this.props.getAvailableAmountActions();
                    this.props.getExchangesActions(false);
                  }}
                />
              )}
            </GridItem>
          )}
        </GridItem>
        {showTableF && (
          <CustomTabs
            value={tabOrders}
            title=""
            onChange={this.handleChange}
            headerColor="primary"
            tabs={[
              {
                tabName: <Translate id="orders.currentOrders" />,
                // tabIcon: Code,
                tabContent: (
                  <TableUIOrders
                    tabOrders={0}
                    isAdmin={isAdmin}
                    userId={userId}
                    orders={ordersFilter}
                  />
                ),
              },
              {
                tabName: <Translate id="orders.sellAllTo" />,
                // tabIcon: Code,
                tabContent: (
                  <TableUIOrders
                    tabOrders={1}
                    isAdmin={isAdmin}
                    userId={userId}
                    orders={ordersFilter}
                  />
                ),
              },
              {
                tabName: <Translate id="orders.historicSDOrders" />,
                // tabIcon: Code,
                tabContent: (
                  <TableUIOrders
                    tabOrders={2}
                    isAdmin={isAdmin}
                    userId={userId}
                    orders={ordersFilter}
                  />
                ),
              },
              {
                tabName: <Translate id="orders.historicNoSDOrders" />,
                // tabIcon: BugReport,
                tabContent: (
                  <TableUIOrders
                    tabOrders={3}
                    isAdmin={isAdmin}
                    userId={userId}
                    orders={ordersFilter}
                  />
                ),
              },
              {
                tabName: <Translate id="orders.botTradingView" />,
                // tabIcon: BugReport,
                tabContent: (
                  <TableUIOrders
                    tabOrders={6}
                    isAdmin={isAdmin}
                    userId={userId}
                    orders={ordersTemplate}
                  />
                ),
              },
            ]}
          />
        )}
        {!showTableF && (
          <div style={{ width: '100%' }}>
            <Tabs
              value={tabOrders}
              onChange={this.handleChange}
              // indicatorColor="primary"
              // textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab key={0} label={<Translate id="orders.currentOrders" />} />
              <Tab key={1} label={<Translate id="orders.sellAllTo" />} />
              <Tab key={2} label={<Translate id="orders.historicSDOrders" />} />
              <Tab
                key={3}
                label={<Translate id="orders.historicNoSDOrders" />}
              />
              <Tab key={4} label={<Translate id="orders.botTradingView" />} />
            </Tabs>
            {tabOrders === 0 && (
              <OrdersContainer
                tabOrders={0}
                orders={ordersFilter}
                userId={userId}
              />
            )}
            {tabOrders === 1 && (
              <OrdersContainer
                tabOrders={1}
                orders={ordersFilter}
                userId={userId}
              />
            )}
            {tabOrders === 2 && (
              <OrdersContainer
                tabOrders={2}
                orders={ordersFilter}
                userId={userId}
              />
            )}
            {tabOrders === 3 && (
              <OrdersContainer
                tabOrders={3}
                orders={ordersFilter}
                userId={userId}
              />
            )}
            {tabOrders === 4 && (
              <OrdersContainer
                tabOrders={6}
                orders={ordersTemplate}
                userId={userId}
              />
            )}
          </div>
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize, config, order, admin } = state;
  return {
    tabOrders: config.tabOrders,
    translate: getTranslate(localize),
    orders: props.isAdmin ? admin.orders : order.orders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertPopup, dispatch),
    changeTabOrdersActions: bindActionCreators(changeTabOrders, dispatch),
    getOrdersActions: bindActionCreators(getOrders, dispatch),
    getAvailableAmountActions: bindActionCreators(getAvailableAmount, dispatch),
    getExchangesActions: bindActionCreators(getExchanges, dispatch),
    refuseAllWaitValidActions: bindActionCreators(refuseAllWaitValid, dispatch),
    deleteEntryErrorsActions: bindActionCreators(deleteEntryErrors, dispatch),
    panicSellAllActions: bindActionCreators(panicSellAll, dispatch),
    cancelAllActions: bindActionCreators(cancelAll, dispatch),
    resetOrderColumnsActions: () => dispatch(resetOrderColumns()),
    clearFilterOrderActions: () =>
      dispatch(createOrdersGridAction('filters', [])),
  };
};
export default withWidth()(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Orders)),
);
