import { createSelector } from 'reselect';

export const exchangesFormSelector = (state) => {
  const { form } = state;
  return form?.formExchange?.values;
};

export const exchangesSelector = (state) => {
  const { user } = state;
  return user?.exchanges;
};

export const scholarSelector = (state) => {
  const { user } = state;
  return user?.scholar;
};

export const scholarsSelector = (state) => {
  const { user } = state;
  return user?.scholars;
};

export const walletSelector = (state) => {
  const { user } = state;
  return user?.exchange;
};

export const transferPopupSelector = (state) => {
  const { user } = state;
  return user?.transferPopup;
};

export const walletsSelector = (walletId) =>
  createSelector(exchangesSelector, (wallets) => {
    return wallets?.find((wallet) => wallet.WalletId == walletId);
  });
