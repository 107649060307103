import { adminConstants } from './constants';

// const exchanges = JSON.parse(localStorage.getItem('exchanges'));
const initialState = {
  users: [],
  orderDetail: '',
  orderInfo: [],
  orders: [],
  ordersGroup: [],
  exchanges: [],
  scholars: [],
  accountsGroup: [],
  statsGroup: [],
  tradingSummaries: [],
  usersVolume: [],
  monthlyStatsGroup: {},
  historicEmail: [],
  paiements: [],
  subscriptionsGroups: [],
  currentPrices: {},
  reqDate: new Date(),
  repTime: 0,
  companyDatas: null,
};

export function admin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.ACTION_GET_SCHOLARS:
      return {
        ...state,
        scholars: action.payload,
      };
    case adminConstants.ACTION_GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case adminConstants.ACTION_GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case adminConstants.ACTION_GET_ORDER_INFO:
      return {
        ...state,
        orderInfo: action.payload,
      };
    case adminConstants.ACTION_GET_TRADING_SUMMARIES:
      return {
        ...state,
        tradingSummaries: action.payload,
      };
    case adminConstants.ACTION_GET_USERS_VOLUME:
      return {
        ...state,
        usersVolume: action.payload,
      };
    case adminConstants.ACTION_GET_ORDERS_GROUP:
      return {
        ...state,
        ordersGroup: action.payload,
      };
    case adminConstants.ACTION_GET_HISTORIC_EMAIL:
      return {
        ...state,
        historicEmail: action.payload,
      };
    case adminConstants.ACTION_GET_ACCOUNTS_GROUP:
      return {
        ...state,
        accountsGroup: action.payload,
      };
    case adminConstants.ACTION_GET_STATS_GROUP:
      return {
        ...state,
        statsGroup: action.payload,
      };
    case adminConstants.ACTION_GET_USER_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case adminConstants.ACTION_GET_USER_EXCHANGES:
      return {
        ...state,
        exchanges: action.payload,
      };
    case adminConstants.ACTION_GET_CURRENT_PRICES:
      return {
        ...state,
        currentPrices: action.payload,
        reqDate: action.reqDate,
        repTime: action.repTime,
      };
    case adminConstants.ACTION_GET_COMPANY_DATA:
      return {
        ...state,
        companyDatas: action.payload,
      };
    case adminConstants.ACTION_GET_PAIEMENTS:
      return {
        ...state,
        paiements: action.payload,
      };
    case adminConstants.ACTION_GET_SUBSCRIPTION_GROUP:
      return {
        ...state,
        subscriptionsGroups: action.payload,
      };
    default:
      return state;
  }
}
