import React from 'react';

import connect from 'react-redux/es/connect/connect';
import { Translate, getTranslate } from 'react-localize-redux';
import DialogTrisel from './DialogTrisel';
import LittleButton from '../../components/CustomButtons/LittleButton';
import Eject from '@material-ui/icons/Eject';

class ButtonDialogTrisel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onCloseDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { order, walletId } = this.props;
    const { open } = this.state;
    return (
      <>
        <LittleButton
          size={'xs'}
          tooltip={'Trisel'}
          color="rose"
          icon={<Eject />}
          timeout={3000}
          onClick={() => {
            this.setState({ open: true });
          }}
        />
        {open && (
          <DialogTrisel
            walletId={walletId}
            onClose={this.onCloseDialog}
            order={order}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { localize } = state;
  return {
    translate: getTranslate(localize),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonDialogTrisel);
