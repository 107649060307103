import { traderConstants } from './constants';
import { isNull } from '../../views/SmartTrade/Common';

const initialState = {
  traders: [],
  paiement: '',
  statGroup: [],
  followers: [],
};

export function trader(state = initialState, action) {
  switch (action.type) {
    case traderConstants.ACTION_PAIEMENT: {
      if (typeof action.payload === 'string') {
        return {
          ...state,
          paiement: action.payload,
        };
      }
      const traders = [...state.traders];
      for (const sg of action.payload) {
        const index = state.traders.findIndex((x) => x.GroupId === sg.GroupId);
        traders[index].SubscriptionGroups = [sg];
      }
      return {
        ...state,
        traders: [...traders],
      };
    }
    case traderConstants.ACTION_GET_TRADERS:
      return {
        ...state,
        traders: action.payload,
      };
    case traderConstants.ACTION_GET_STAT_TRADER:
      return {
        ...state,
        statGroup: action.payload,
      };
    case traderConstants.ACTION_DELETE_TRADER:
      return {
        ...state,
        traders: state.traders.filter((x) => x.GroupId !== action.payload),
      };
    case traderConstants.ACTION_GET_TRADER: {
      // case exchangeConstants.ACTION_PUT_TRADER:
      if (isNull(state.traders)) {
        return {
          ...state,
          traders: [action.payload],
        };
      }
      const index = state.traders.findIndex(
        (x) => x.GroupId === action.payload.GroupId,
      );

      if (index !== -1) state.traders[index] = action.payload;
      else state.traders.unshift(action.payload);
      return {
        ...state,
        traders: [...state.traders],
      };
    }
    case traderConstants.ACTION_POST_SUBSCRIPTION_GROUP: {
      const index = state.traders.findIndex(
        (x) => x.GroupId === action.payload.GroupId,
      );
      const traders = [...state.traders];
      traders[index].SubscriptionGroups = [action.payload];
      return {
        ...state,
        traders: [...traders],
      };
    }
    case traderConstants.ACTION_POST_UNSUBSCRIBE_GROUP: {
      const index = state.traders.findIndex(
        (x) => x.GroupId === action.payload.GroupId,
      );
      if (index === -1 || state.traders[index].SubscriptionGroups.length === 0)
        return state;
      state.traders[index].SubscriptionGroups[0] = action.payload;
      return {
        ...state,
        traders: [...state.traders],
      };
    }
    case traderConstants.ACTION_GET_FOLLOWER: {
      if (isNull(state.followers)) {
        return {
          ...state,
          followers: [action.payload],
        };
      }
      const index = state.followers.findIndex(
        (x) => x.ApplicationUserId === action.payload.ApplicationUserId,
      );

      if (index !== -1) state.followers[index] = action.payload;
      else state.followers.unshift(action.payload);
      return {
        ...state,
        followers: [...state.followers],
      };
    }
    case traderConstants.ACTION_REFRESH_FOLLOWER: {
      const exchange = action.payload;
      const exchangeId = exchange.Id;
      const index = state.followers.findIndex(
        (x) => x.Exchange != null && x.Exchange.Id === exchangeId,
      );
      if (index === -1) return state;
      state.followers[index].Exchange = exchange;
      return {
        ...state,
        followers: [...state.followers],
      };
    }
    case traderConstants.ACTION_TOGGLE_FOLLOWER: {
      const userId = action.payload;
      const index = state.followers.findIndex(
        (x) => x.ApplicationUserId === userId,
      );
      if (index === -1) return state;
      state.followers[index].IsActive = !state.followers[index].IsActive;
      return {
        ...state,
        followers: [...state.followers],
      };
    }
    case traderConstants.ACTION_DELETE_FOLLOWER: {
      return {
        ...state,
        followers: state.followers.filter(
          (x) => x.ApplicationUserId !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
}
