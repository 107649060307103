import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import {
  TreeDataState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import connect from 'react-redux/es/connect/connect';
import { getActiveCode } from '../../../helpers/localize';
import { isNull, parseToFloat } from '../../SmartTrade/Common';
import { satoshi } from '../../../helpers/general';

const getCryptoPaiement = (charge) => {
  if (isNull(charge)) return [];
  let cryptoRet = [];
  for (const p of charge.payments) {
    const crypto = p.value.crypto;
    if (!isNull(crypto)) {
      const exist = cryptoRet.find((x) => x.currency === crypto.currency);
      if (isNull(exist)) cryptoRet.push({ ...crypto });
      else exist.amount += crypto.amount;
    }
  }
  return cryptoRet;
};

//Add Rate & Crypto to g
const getGroupInfo = (g, cryptos, totalPrice) => {
  let groupInfo = { ...g };
  if (!isNull(g)) {
    if (!isNull(g.Price)) {
      groupInfo.Rate = g.Price / totalPrice;
      groupInfo.Crypto = [];
      for (const c of cryptos) {
        groupInfo.Crypto[c.currency] = c.amount * groupInfo.Rate;
        if (g.GroupId !== 1) {
          groupInfo.Crypto[c.currency] = parseToFloat(
            groupInfo.Crypto[c.currency] * 0.85,
            satoshi,
          );
        } else {
          groupInfo.Crypto[c.currency] = parseToFloat(
            groupInfo.Crypto[c.currency] +
              c.amount * (1 - groupInfo.Rate) * 0.15,
            satoshi,
          );
        }
      }
    }
  }
  return groupInfo;
};

const getGroupStr = (trader, g) => {
  let ret = '';
  let recap = false;
  if (!isNull(g) && !isNull(trader)) {
    ret += trader.Name + ' ' + g.NbMonth + ' mois: ';
    if (!isNull(g.Price)) {
      for (const currency in g.Crypto) {
        if (!recap) {
          recap = true;
          ret += g.Price + ' € ( ' + g.Price / g.NbMonth + ' €/mois ) => ';
        }
        ret += g.Crypto[currency] + ' ' + currency + ' ';
      }
    }
    ret += ', ';
  }
  return ret;
};

class TablePaiements extends React.PureComponent {
  constructor(props) {
    super(props);

    let columns = [
      {
        name: 'User',
        title: 'User',
        getCellValue: (row) => {
          if (!Object.hasOwn(row['Paiement'], 'ApplicationUser')) return '';
          return row['Paiement']['ApplicationUser'].Email;
        },
      },
      {
        name: 'GroupIds',
        title: 'GroupIds',
        getCellValue: (row) => {
          const list = row['Paiement']['GroupIdList'];
          const totalPrice = parseFloat(row['Paiement']['TotalPrice']);
          const cryptos = getCryptoPaiement(row['Charge']);
          let ret = '';
          for (const g of list) {
            if (g.GroupId !== 1) {
              const trader = props.traders.find((x) => x.GroupId === g.GroupId);
              const groupInfo = getGroupInfo(g, cryptos, totalPrice);
              ret += getGroupStr(trader, groupInfo);
            }
          }
          const trader = props.traders.find((x) => x.GroupId === 1);
          let wot = list.find((x) => x.GroupId === 1);
          if (isNull(wot)) wot = { GroupId: 1, Price: 0, NbMonth: 0 };
          const groupInfo = getGroupInfo(wot, cryptos, totalPrice);
          ret += getGroupStr(trader, groupInfo);
          return ret;
        },
      },
      {
        name: 'TotalPrice',
        title: 'Total €',
        getCellValue: (row) => {
          return row['Paiement']['TotalPrice'] + ' EUR';
        },
      },
      {
        name: 'Charge',
        title: 'Total Crypto',
        getCellValue: (row) => {
          const cryptos = getCryptoPaiement(row['Charge']);
          let ret = '';
          for (const c of cryptos) {
            ret += c.amount + ' ' + c.currency + ' ';
          }
          return ret;
        },
      },
      {
        name: 'Code',
        title: 'Code',
        getCellValue: (row) => {
          return row['Paiement']['Code'];
        },
      },
      {
        name: 'Type',
        title: 'Type',
        getCellValue: (row) => {
          return row['Paiement']['Type'];
        },
      },
      {
        name: 'DateCreated',
        title: 'DateCreated',
        getCellValue: (row) => {
          return row['Paiement']['DateCreated'];
        },
      },
    ];

    let defaultColumnWidths = [
      { columnName: 'User', width: 150 },
      { columnName: 'GroupIds', width: 500 },
      { columnName: 'TotalPrice', width: 100 },
      { columnName: 'Code', width: 100 },
      { columnName: 'Type', width: 150 },
      { columnName: 'Charge', width: 150 },
      { columnName: 'DateCreated', width: 100 },
    ];

    this.state = {
      //table
      columns: columns,
      id: 1,
      rows: props.paiements,
      pageSizes: [5, 10, 20, 50, 0],
      defaultColumnWidths: defaultColumnWidths,
      filters: [],
    };
    this.changeFilters = (filters) => this.setState({ filters });
  }

  componentDidUpdate() {
    this.setState({ rows: this.props.paiements });
  }

  render() {
    const { rows, columns, defaultColumnWidths, filters } = this.state;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState
            filters={filters}
            onFiltersChange={this.changeFilters}
          />
          <SortingState
            defaultSorting={[{ columnName: 'DateCreated', direction: 'desc' }]}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <TreeDataState />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

          <TableHeaderRow showSortingControls={true} />
          <TableFilterRow />
          <PagingPanel pageSizes={[5, 10, 20, 50, 0]} />
        </Grid>
        {/*{rows && <pre>{JSON.stringify(rows, null, 2)}</pre>}*/}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
    // traders: tradersSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePaiements);
