import { combineReducers } from 'redux';

import { reducer as reduxFormReducer } from 'redux-form';
import { localizeReducer } from 'react-localize-redux';
import { connectRouter } from 'connected-react-router';
import { admin } from './admin/reducer';
import { alert } from './alert/alert.reducer';
import { loading } from './loading/loading.reducer';
import { user } from './user/reducer';
import { worker } from './worker/reducer';
import { authentication } from './authentication/reducer';
import { trader } from './trader/reducer';
import { order } from './order/reducer';
import { card } from './card/reducer';
import { trading } from './trading/reducer';
import { config } from './config/reducer';
import { tokens } from './tokens/reducer';
import { onboarding } from './onboarding/reducer';
import { referrals } from './referrals/reducer';
import { strategyConfiguration } from './strategyConfiguration/reducer';
import { imx } from './imx/reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    localize: localizeReducer,
    admin,
    alert,
    loading,
    user,
    worker,
    authentication,
    trader,
    card,
    trading,
    order,
    config,
    tokens,
    onboarding,
    referrals,
    imx,
    strategyConfiguration,
    form: reduxFormReducer,
  });
