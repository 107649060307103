import React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import {
  getFeeFromName,
  getProfitNetFromPrice,
  isBinanceFuture,
  isEmpty,
  isNull,
  parseToFloat,
} from '../views/SmartTrade/Common';

export const dateToBackFormat = (date) => {
  return new Date(date).toISOString();
};

export const getStateStr = (state) => {
  switch (state) {
    case 0: // WaitValidation
      return 'Wait validation';
    case 1: // Started
      return 'Started';
    case 2: // PanicSell
      return 'PanicSell';
    case 3: // StopLost
      return 'StopLoss';
    case 4: // Target
      return 'Target';
    case 5: // Finished
      return 'Finished';
    case 6: // Cancelled
      return 'Cancelled';
    case 7: // Error
      return 'Error';
    case 8: // Refused
      return 'Refused';
    default:
      return 'Unknown';
  }
};

export const getDefaultColumnWidthsTabStats = () => {
  return [
    { columnName: 'NbOrders', width: 75 },
    { columnName: 'NbOrdersTotal', width: 75 },
    { columnName: 'NbOrdersHorsStats', width: 75 },
    { columnName: 'NbCurrentOrders', width: 75 },
    { columnName: 'NbDoneOrders', width: 75 },
    { columnName: 'NbSignalsWeek', width: 75 },
    { columnName: 'NbDaysTrade', width: 75 },
    { columnName: 'PourcentageCapitalByDay', width: 95 },
    { columnName: 'PourcentageSuccess', width: 95 },
    { columnName: 'LastDateOrder', width: 105 },
    { columnName: 'ProfitTotal', width: 75 },
    { columnName: 'ProfitCapital', width: 75 },
    { columnName: 'ProfitTotalQuote', width: 95 },
    { columnName: 'ProfitTotalCapitalQuote', width: 95 },
    { columnName: 'ProfitPerTrade', width: 95 },
    { columnName: 'ProfitPerTradeCapital', width: 95 },
    { columnName: 'MaxDrawDown', width: 95 },
    { columnName: 'MaxDrawDownCapital', width: 95 },
  ];
};

export const getColumnTabStats = (inStats = false) => {
  const getRow = (row) => {
    return inStats ? row.Stats : row;
  };
  return [
    {
      name: 'NbOrders',
      title: 'NbOrders',
      getCellValue: (row) => {
        return getRow(row).NbOrders;
      },
    },
    {
      name: 'NbOrdersTotal',
      title: 'NbOrdersTotal',
      getCellValue: (row) => {
        return getRow(row).NbOrdersTotal;
      },
    },
    {
      name: 'NbOrdersHorsStats',
      title: 'NbOrdersHorsStats',
      getCellValue: (row) => {
        return getRow(row).NbOrdersTotal - getRow(row).NbOrders;
      },
    },
    {
      name: 'NbCurrentOrders',
      title: 'NbCurrentOrders',
      getCellValue: (row) => {
        return getRow(row).NbCurrentOrders;
      },
    },
    {
      name: 'NbDoneOrders',
      title: 'NbDoneOrders',
      getCellValue: (row) => {
        return getRow(row).NbDoneOrders;
      },
    },
    {
      name: 'NbSignalsWeek',
      title: 'NbSignalsWeek',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).NbSignalsWeek, 0.01);
      },
    },
    {
      name: 'NbDaysTrade',
      title: 'NbDaysTrade',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).NbDaysTrade, 0.01);
      },
    },
    {
      name: 'PourcentageSuccess',
      title: 'PourcentageSuccess',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).PourcentageSuccess, 0.01);
      },
    },
    {
      name: 'PourcentageCapitalByDay',
      title: 'PourcentageCapitalByDay',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).PourcentageCapitalByDay, 0.01);
      },
    },
    {
      name: 'LastDateOrder',
      title: 'LastDateOrder',
      getCellValue: (row) => {
        return getRow(row).LastDateOrder;
      },
    },
    {
      name: 'ProfitTotal',
      title: 'ProfitTotal',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).ProfitTotal, 0.01);
      },
    },
    {
      name: 'ProfitCapital',
      title: 'ProfitCapital',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).ProfitCapital, 0.01);
      },
    },
    {
      name: 'ProfitTotalQuote',
      title: 'ProfitTotalQuote',
      getCellValue: (row) => {
        let str = '';
        const tab = getRow(row).ProfitTotalQuote;
        for (const t in tab) {
          str += `${t}: ${tab[t].toFixed(2)}, `;
        }
        return str;
      },
    },
    {
      name: 'ProfitTotalCapitalQuote',
      title: 'ProfitTotalCapitalQuote',
      getCellValue: (row) => {
        let str = '';
        const tab = getRow(row).ProfitTotalCapitalQuote;
        for (const t in tab) {
          str += `${t}: ${tab[t].toFixed(2)}, `;
        }
        return str;
      },
    },
    {
      name: 'ProfitPerTrade',
      title: 'ProfitPerTrade',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).ProfitPerTrade, 0.01);
      },
    },
    {
      name: 'ProfitPerTradeCapital',
      title: 'ProfitPerTradeCapital',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).ProfitPerTradeCapital, 0.01);
      },
    },
    {
      name: 'MaxDrawDown',
      title: 'MaxDrawDown',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).MaxDrawDown, 0.01);
      },
    },
    {
      name: 'MaxDrawDownCapital',
      title: 'MaxDrawDownCapital',
      getCellValue: (row) => {
        return parseToFloat(getRow(row).MaxDrawDownCapital, 0.01);
      },
    },
  ];
};

export const getPriceTrigger = (target, precision) => {
  const res = getTriggerPrice(target);
  const price = res ? res.toFixed(precision) : '';
  return target && target.FinalPrice && target.Price && target.Price > 0 ? (
    <Tooltip
      title={`Asked price: ${target.Price}`}
      enterDelay={50}
      leaveDelay={200}
      style={{ cursor: 'help' }}
    >
      <span>{price}</span>
    </Tooltip>
  ) : (
    price
  );
};

export const getAllNoStart = (triggers) => {
  if (isNull(triggers)) return [];
  return triggers.filter((x) => x.Action !== 2);
};

export const getTargets = (triggers) => {
  if (isNull(triggers)) return [];
  return triggers.filter((x) => x.Action === 0);
};

export const getStopLoss = (triggers) => {
  if (isNull(triggers)) return null;
  return triggers.find((x) => x.Action === 1);
};

export const getStart = (triggers) => {
  if (isNull(triggers)) return null;
  return triggers.find((x) => x.Action === 2);
};

export const getStarts = (triggers) => {
  if (isNull(triggers)) return [];
  return triggers.filter((x) => x.Action === 2);
};

export const getPanicSell = (triggers) => {
  if (isNull(triggers)) return null;
  return triggers.find((x) => x.Action === 3);
};

export const getTriggerPrice = (trigger) => {
  if (!trigger) return 0;
  if (trigger.FinalPrice && trigger.FinalPrice > 0) return trigger.FinalPrice;
  return trigger.Price;
};

export const getTrigPrice = (trigger) => {
  if (!trigger) return null;
  if (trigger.TriggerPrice && trigger.TriggerPrice > 0)
    return trigger.TriggerPrice;
  return null;
};

export const isTriggered = (trigger) => {
  if (!trigger) return false;
  // Order placer sur une plateforme ou ordre en trailing
  return trigger.Guid !== null || trigger.State === 6;
};

export const getExchangeExEnum = (exchangeName) => {
  switch (exchangeName) {
    case 'Bittrex':
      return 0;
    case 'Binance_Spot':
      return 1;
    case 'Huobi':
      return 2;
    case 'Bitfinex':
      return 3;
    case 'Bitmex':
      return 4;
    case 'Kraken':
      return 5;
    case 'Binance_Margin':
      return 6;
    case 'Binance_US':
      return 7;
    case 'Binance_Futures':
      return 8;
    case 'FTX':
      return 9;
    case 'FTX_US':
      return 10;
    case 'Kucoin':
      return 11;
    case 'Binance_Test':
      return 12;
    case 'Okx':
      return 13;
    case 'CoinbasePro':
      return 14;
    case 'CryptoCom':
      return 15;
    case 'Bybit':
      return 16;
    case 'Coinbase':
      return 17;
    case 'Ascendex':
      return 18;
    case 'Binance_Futures_Test':
      return 19;
    default:
      return null;
  }
};
export const getExchangeExStr = (exchangeName) => {
  if (exchangeName === 0) return 'Bittrex';
  if (exchangeName === 1) return 'Binance_Spot';
  if (exchangeName === 2) return 'Huobi';
  if (exchangeName === 3) return 'Bitfinex';
  if (exchangeName === 4) return 'Bitmex';
  if (exchangeName === 5) return 'Kraken';
  if (exchangeName === 6) return 'Binance_Margin';
  if (exchangeName === 7) return 'Binance_US';
  if (exchangeName === 8) return 'Binance_Futures';
  if (exchangeName === 9) return 'FTX';
  if (exchangeName === 10) return 'FTX_US';
  if (exchangeName === 11) return 'Kucoin';
  if (exchangeName === 12) return 'Binance_Test';
  if (exchangeName === 13) return 'Okx';
  if (exchangeName === 14) return 'CoinbasePro';
  if (exchangeName === 15) return 'CryptoCom';
  if (exchangeName === 16) return 'Bybit';
  if (exchangeName === 17) return 'Coinbase';
  if (exchangeName === 18) return 'Ascendex';
  if (exchangeName === 19) return 'Binance_Futures_Test';
  return '';
};
export const getExchangeStr = (account) => {
  if (!account) return '';
  return getExchangeExStr(account.ExchangeName);
};

export const getLeverageLimit = (
  get,
  exchangeName,
  isBuy = true,
  leverageBuy = null,
  leverageSell = null,
) => {
  const isLeverage = haveLeverage(
    exchangeName,
    isBuy,
    leverageBuy,
    leverageSell,
  );
  const isKraken = exchangeName === 'Kraken';
  const isBinanceFutures = isBinanceFuture(exchangeName);
  let minLeverage = 0;
  let maxLeverage = 125;
  if (
    isLeverage &&
    (isKraken || isBinanceFutures) &&
    leverageBuy != null &&
    leverageBuy.length > 0 &&
    leverageSell != null &&
    leverageSell.length > 0
  ) {
    if (isBuy) {
      minLeverage = leverageBuy[0];
      maxLeverage = leverageBuy[leverageBuy.length - 1];
    } else {
      minLeverage = leverageSell[0];
      maxLeverage = leverageSell[leverageBuy.length - 1];
    }
  }
  if (get === 'min') return minLeverage;
  return maxLeverage;
};

export const convertOrderBeforeSend = (
  oldOrder,
  leverageBuy = null,
  leverageSell = null,
) => {
  const order = { ...oldOrder };

  if (isNull(order.isTemplate)) order.isTemplate = false;

  const isBinanceFutures =
    order.accountId && isBinanceFuture(order.accountId.exchange);
  if (order.targetLock) order.targets = [];
  else {
    const targets = [];
    for (const t of order.targets) {
      const tn = { ...t };
      if (tn.orderType === 'Limit') tn.orderType = 0;
      else if (tn.orderType === 'Market') tn.orderType = 1;
      if (
        !isEmpty(tn.pourcentage) ||
        (!isEmpty(tn.price) && !isEmpty(tn.profit))
      ) {
        if (isNull(tn.trailingCB) || tn.trailingCB === false)
          tn.trailing = null;
        if (order.isTemplate || tn.lastUpdated === 'profit') tn.price = null;
        else if (tn.lastUpdated === 'price') tn.profit = null;
        targets.push(tn);
      }
    }
    order.targets = targets;
  }
  if (order.stopLossLock) {
    order.stopPrice = null;
    order.stopProfit = null;
  } else if (order.isTemplate || order.lastUpdatedStop === 'profit')
    order.stopPrice = null;
  else if (order.lastUpdatedStop === 'price') order.stopProfit = null;
  if (order.followersLock) {
    order.groupId = null;
    order.dontDoOrder = false;
  }
  if (order.groupId && order.groupId.length === 0) {
    order.groupId = null;
  }
  // generate guid for auth
  if (isNull(order.tradingViewCB) || order.tradingViewCB === false)
    order.startTradingViewGuid = null;

  // Date to FormatBack
  order.timeOutStart =
    isNull(order.timeOutStartCB) || order.timeOutStartCB === false
      ? null
      : dateToBackFormat(order.timeOutStart);
  order.timeOutStopLoss =
    isNull(order.timeOutStopLossCB) || order.timeOutStopLossCB === false
      ? null
      : dateToBackFormat(order.timeOutStopLoss);

  if (isNull(order.startTriggerPriceCB) || order.startTriggerPriceCB === false)
    order.startTriggerPrice = null;
  if (isNull(order.trailingStartCB) || order.trailingStartCB === false)
    order.trailingStart = null;
  if (isNull(order.trailingStopLossCB) || order.trailingStopLossCB === false)
    order.trailingStopLoss = null;
  else order.trailingStopLoss *= -1;
  if (order.typeStart === 'Market') order.startPrice = 0;
  if (!isBinanceFutures) order.orderTypeStop = null;
  else if (order.orderTypeStop === 'Limit') order.orderTypeStop = 0;
  else if (order.orderTypeStop === 'Market') order.orderTypeStop = 1;
  if (order.order) order.OrderId = order.order.OrderId;
  order.quantity = order.firstQty;
  if (
    (order.accountId &&
      !haveLeverage(
        order.accountId.exchange,
        order.isBuy,
        leverageBuy,
        leverageSell,
      )) || // Exchange sans leverage
    isNull(order.leverageCB) ||
    order.leverageCB === false // Leverage désactiver
  ) {
    order.leverage = null;
  }
  return order;
};

export const haveLeverage = (
  exchangeName,
  isBuy = true,
  leverageBuy = null,
  leverageSell = null,
) => {
  let ret = [
    'Bitmex',
    'Kraken',
    'Binance_Futures',
    'Binance_Futures_Test',
  ].includes(exchangeName);
  const isKraken = exchangeName === 'Kraken';
  if (
    isKraken &&
    ((isBuy && (isNull(leverageBuy) || leverageBuy.length === 0)) ||
      (!isBuy && (isNull(leverageSell) || leverageSell.length === 0)))
  )
    ret = false;
  return ret;
};

// Wait, Started, Target
export const isActiveOrder = (state) => {
  return [0, 1, 4].includes(state);
};

export const startNoDone = (state) => {
  return [0, 1, 8].includes(state);
};

export const isFinishedOrder = (state) => {
  return [2, 3, 5].includes(state);
};

export const triggerIsDone = (trigger) => {
  if (!trigger || !trigger.State) return false;
  const stateAction = trigger.State;
  return [3].includes(stateAction); // Done
};

export const triggerIsWaiting = (trigger) => {
  if (!trigger || !trigger.State) return false;
  const stateAction = trigger.State;
  return [1, 6].includes(stateAction); // wait, trailing
};

export const abs = (val) => {
  if (val < 0) return val * -1;
  return val;
};

export const getDistanceNextMove = (order, currentPrice) => {
  let a = 0;
  if (!isActiveOrder(order.State)) {
    return 0;
  }
  if (startNoDone(order.State)) {
    const start = getStart(order.Triggers);
    const price = getTriggerPrice(start);
    a = abs(currentPrice - price);
  } else {
    for (const trigger of order.Triggers) {
      if (!triggerIsDone(trigger)) {
        const price = getTriggerPrice(trigger);
        const ab = abs(currentPrice - price);
        if (a === 0 || ab < a) a = ab;
      }
    }
  }
  return parseToFloat((100 * a) / currentPrice, 0.01);
};

export const getQtyRemaining = (order) => {
  const targets = getTargets(order.Triggers);
  // const start = getStart(order.Triggers);
  const qty = order.Quantity;
  let pourcRestant = 100;
  for (const target of targets) {
    if (triggerIsDone(target)) {
      pourcRestant -= target.Pourcentage;
    }
  }
  return (qty * pourcRestant) / 100;
};

export const getPercentageRemaining = (order) => {
  const targets = getTargets(order.Triggers);
  let percRemaining = 100;
  for (const target of targets) {
    if (triggerIsDone(target)) {
      percRemaining -= target.Pourcentage;
    }
  }
  return percRemaining;
};

export const getResultFromOrder = (order, currentPrice, stepPrice) => {
  const start = getStart(order.Triggers);
  const startPrice = getTriggerPrice(start);
  // const isBuy = order.IsBuy;
  const leverage = isEmpty(order.Leverage) ? 1 : order.Leverage;
  const profit = getProfitFromOrder(order, currentPrice);
  if (profit === '-') return profit;
  const price = startPrice;
  // isBuy ? startPrice : getProfitFromOrder(order, currentPrice, true);
  const qty = price * order.Quantity;
  return parseToFloat(((profit / leverage) * qty) / 100, stepPrice);
};

export const getFeeFromTrigger = (trigger, order) => {
  if (isNull(trigger) || isNull(order)) return 0;
  const account = order.AAccount;
  if (isNull(account)) return 0;
  const isTaker = trigger.OrderType === 1;
  const exchangeName = getExchangeStr(account);
  switch (exchangeName) {
    case 'Bittrex':
      return 0.0025;
    case 'Binance_Spot':
    case 'Binance_Test':
    case 'Binance_US':
    case 'Binance_Margin':
      return 0.001;
    case 'Huobi':
      return 0.002;
    case 'Bitfinex':
      return isTaker ? 0.002 : 0.001; // 0.001 if maker
    case 'Bitmex':
      return 0.0; // à vérifier
    case 'Kraken':
      return isTaker ? 0.0026 : 0.0016; // 0.0016 if maker
    case 'Binance_Futures':
    case 'Binance_Futures_Test':
      return isTaker ? 0.0004 : 0.0002; // 0.0002 if maker
    case 'FTX':
    case 'FTX_US':
      return isTaker ? 0.0007 : 0.0002; // 0.0002 if maker
    case 'Kucoin':
      return 0.001;
    default:
      return 0;
  }
};

export const getProfitFromOrder = (order, currentPrice, wantPrice = false) => {
  const start = getStart(order.Triggers);
  const leverage = isEmpty(order.Leverage) ? 1 : order.Leverage;
  if (!triggerIsDone(start)) return '-';
  const isCurrentPriceNull = currentPrice === null;
  if (isCurrentPriceNull) currentPrice = 0;
  const startPrice = getTriggerPrice(start);
  const fee =
    order && order.AAccount && order.AAccount.ExchangeName
      ? getFeeFromName(getExchangeStr(order.AAccount))
      : 0;

  let value = 0.0;
  let pourcRestant = 0.0;
  for (const target of order.Triggers) {
    if (target.Action === 2) continue; // Start
    if (!isCurrentPriceNull && order.State === 4 && triggerIsWaiting(target))
      continue; // Start

    let price = 0;
    // Prend en compte les SLs
    if (triggerIsDone(target) || (isCurrentPriceNull && target.Action === 1)) {
      price = getTriggerPrice(target);
    } else {
      continue;
    }
    if (price !== 0) {
      const feeTrigger = getFeeFromTrigger(target, order);
      const range = wantPrice
        ? price / 100
        : getProfitNetFromPrice(price, order.IsBuy, startPrice, feeTrigger);
      value += range * target.Pourcentage;
      pourcRestant += target.Pourcentage;
    }
  }
  const p = 100 - pourcRestant;
  const range = wantPrice
    ? currentPrice / 100
    : getProfitNetFromPrice(currentPrice, order.IsBuy, startPrice, fee);
  value += range * p;
  return (value * (wantPrice ? 1 : leverage)).toFixed(2);
};

// export const getPrecision = a => {
//   if (!isFinite(a)) return 0;
//   let e = 1,
//     p = 0;
//   while (Math.round(a * e) / e !== a) {
//     e *= 10;
//     p++;
//   }
//   return p;
// };
// export const KeyOrderTable = {
//   EXCHANGE: {
//     K: 0,
//     T: 'EXCHANGE'
//   },
//   PAIR: {
//     K: 1,
//     T: 'PAIR'
//   },
//   GROUP: {
//     K: 2,
//     T: 'GROUP'
//   },
//   ISBUY: {
//     K: 3,
//     T: 'ISBUY'
//   },
//   STATE: {
//     K: 4,
//     T: 'STATE'
//   },
//   POURCENTAGE: {
//     K: 5,
//     T: '%'
//   },
//   QUANTITY: {
//     K: 6,
//     T: 'QUANTITY'
//   },
//   STARTPRICE: {
//     K: 7,
//     T: 'STARTPRICE'
//   },
//   TARGETS: {
//     K: 8,
//     T: 'TARGETS'
//   },
//   STOPLOSS: {
//     K: 9,
//     T: 'STOPLOSS'
//   },
//   ERROR: {
//     K: 10,
//     T: 'ERROR'
//   },
//   CREATEDATE: {
//     K: 11,
//     T: 'CREATEDATE'
//   },
//   ID: {
//     K: 12,
//     T: 'ID'
//   }
// };
// export const getTargetStateStr = state => {
//   switch (state) {
//     case 0: //Off
//       return 'Off';
//     case 1: //Wait
//       return 'Wait';
//     case 2: //Trigged
//       return 'Trigged';
//     case 3: //Done
//       return 'Done';
//     case 4: //Cancelled
//       return 'Cancelled';
//     case 5: //Error
//       return 'Error';
//     case 6: //Trailing
//       return 'Trailing';
//     default:
//       return 'Unknown';
//   }
// };
//
// export const getTriggerOrderType = orderType => {
//   switch (orderType) {
//     case 0:
//       return 'Limit';
//     case 1:
//       return 'Market';
//     default:
//       return 'Unknown';
//   }
// };

// export const getTrailing = trailing => {
//   if (trailing && trailing != null) {
//     return trailing + '%';
//   }
//   return '';
// };
//
// export const getPrecisionPair = pair => {
//   switch (pair) {
//     case 'BTC':
//       return 8;
//     case 'ETH':
//       return 8;
//     case 'USDT':
//       return 2;
//     case 'USD':
//       return 2;
//     default:
//       return 0;
//   }
// };

// export const getPrice = (price, finalPrice) => {
//   if (finalPrice && finalPrice != null) return finalPrice + 'F';
//   return price;
// };

// const getTargetStr = targets => {
//   const ts = getTargets(targets);
//   let ret = '';
//   for (const t of ts) {
//     ret += `${t.Pourcentage}%: ${getPrice(t)} ${getTrailing(t.Trailing)}
//     ${getTargetStateStr(t.State)}
//     ${getTriggerOrderType(t.OrderType)[0]} `;
//   }
//   return ret;
// };
//
// export const getStopLossStr = targets => {
//   const sl = getStopLoss(targets);
//   return sl
//     ? getPrice(sl) +
//         ' ' +
//         getTrailing(sl.Trailing) +
//         ' ' +
//         getTargetStateStr(sl.State)
//     : '';
// };

// export const getObjOrder = order => {
//   const start = getStart(order.Triggers);
//   return {
//     [KeyOrderTable.EXCHANGE.K]: getExchangeStr(order.AAccount),
//     [KeyOrderTable.PAIR.K]: order.Pair,
//     [KeyOrderTable.GROUP.K]: order.Group ? order.Group.Name : '',
//     [KeyOrderTable.ISBUY.K]: order.IsBuy ? 'Buy' : 'Sell',
//     [KeyOrderTable.STATE.K]: getStateStr(order.State),
//     [KeyOrderTable.POURCENTAGE.K]: order.Pourcentage,
//     [KeyOrderTable.QUANTITY.K]: order.Quantity,
//     [KeyOrderTable.STARTPRICE.K]: start ? start.Price : 0,
//     [KeyOrderTable.TARGETS.K]: getTargetStr(order.Triggers),
//     [KeyOrderTable.STOPLOSS.K]: getStopLossStr(order.Triggers),
//     [KeyOrderTable.ERROR.K]: order.ErrorMsg,
//     [KeyOrderTable.CREATEDATE.K]: order.DateCreated,
//     [KeyOrderTable.ID.K]: order.OrderId
//   };
// };

// export const getTableOrder = (orders, showCurrent) => {
//   let table = [];
//   let i = 0;
//   if (orders && orders.length > 0) {
//     for (const order of orders) {
//       if (showCurrent && !isActiveOrder(order.State)) {
//         continue;
//       } else if (!showCurrent && isActiveOrder(order.State)) {
//         continue;
//       }
//       table[i] = getObjOrder(order);
//       ++i;
//     }
//   }
//   return table;
// };
