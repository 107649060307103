import React from 'react';
import { Translate } from 'react-localize-redux';

export default (values) => {
  const errors = {};
  const requiredFields = ['email', 'password'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = <Translate id="central.required" />;
    }
  });

  // Pour login avec username
  // if (
  //   values.email &&
  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  // ) {
  //   errors.email = <Translate id="email.invalidEmail" />;
  // }
  if (values.password) {
    if (
      !/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9\s:])([^]){8,100}$/.test(
        values.password,
      )
    ) {
      errors.password = <Translate id="email.checkPassword" />;
    }
  }
  return errors;
};
