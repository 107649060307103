import {arrFilterTypes} from "../../helpers/card";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import React from "react";
import ReactSelect from "../Form/ReactSelectUI";
import TextField from "@material-ui/core/TextField";

function LineField ({item, name, filter, setFilter}) {
    return (<>
        {item.values && (
            <ReactSelect
                multi={true}
                label={name}
                input={{
                    value: filter[name]?.map(value => ({value, label: value})) ?? [],
                    onChange: (v) => setFilter({
                        ...filter,
                        [name]: (v?.map(item => item.value) ?? [])
                    })
                }}
                options={item.values.map(value => ({value, label: value}))}
            />
        )}
        {!item.values && (
            <TextField
                id={name}
                name={name}
                label={name}
                value={filter[name] ?? ''}
                fullWidth
                onChange={(e) => setFilter({...filter, [name]: e.target.value})}
            />
        )}
    </>)
}

export default function FilterCTA({filter, setFilter}) {

    return (
        <>
        {arrFilterTypes.map((item, index) => {
                return (
                    <GridItem key={index} xs={12} sm={12} md={12} style={{marginBottom: '10px'}}>
                        <GridContainer>
                            <GridItem xs>
                                <LineField item={item} name={item.name} filter={{...filter}} setFilter={setFilter} />
                            </GridItem>
                            <GridItem xs>
                                <LineField item={item} name={item.name+'Ban'} filter={{...filter}} setFilter={setFilter} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                )}
            )}
        </>
    );
}