import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import { withLocalize } from 'react-localize-redux';
import connect from 'react-redux/es/connect/connect';
import { isNull } from '../SmartTrade/Common';
import TableScholar from './TableScholar';
import { scholarSelector } from '../../redux/user/selector';
import { bindActionCreators } from 'redux';
import { getScholar } from '../../redux/user/actions';
import Button from '../../components/CustomButtons/Button';
import { Cached, Clear } from '@material-ui/icons';
import {
  createScholarGridAction,
  resetScholarColumns,
} from '../../redux/config/actions';

class ScholarPage extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    if (!isNull(id)) this.props.getScholarActions(id);
  }
  render() {
    const { scholar } = this.props;
    if (isNull(scholar)) return <div>Loading...</div>;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridItem
          container
          xs={12}
          sm={8}
          md={8}
          alignItems="center"
          justifyContent="flex-start"
        >
          <div>
            <span>
              <Button
                onClick={() => {
                  localStorage.removeItem('scholarGrid');
                  this.props.resetAssetColumnsActions();
                }}
                size="sm"
              >
                <Cached />
                &nbsp; Reset Table
              </Button>
              <Button
                onClick={() => {
                  this.props.clearFilterAssetActions();
                }}
                size="sm"
              >
                <Clear />
                &nbsp; Reset filter
              </Button>
            </span>
          </div>
        </GridItem>
        <TableScholar scholar={scholar} />
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    scholar: scholarSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScholarActions: bindActionCreators(getScholar, dispatch),
    resetAssetColumnsActions: () => dispatch(resetScholarColumns()),
    clearFilterAssetActions: () =>
      dispatch(createScholarGridAction('filters', [])),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ScholarPage),
);
