import { push } from 'connected-react-router';
import { exchangeConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import {Translate} from '../../helpers/general';
import { alertActions } from '../alert/alert.actions';
import {imxConstants} from "../imx/constants";

export const refreshExchange = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Wallet/refreshWallet/${id}`, {});
  if (eu) {
    await fetchExchanges(dispatch, true);
    // dispatch({
    //   type: exchangeConstants.ACTION_GET_WALLET,
    //   payload: eu
    // });
    // localStorage.setItem('exchanges', JSON.stringify(eu));
  }
};

const fetchExchanges = async (
  dispatch,
  withDetail = false,
  withInGameInfo = false,
  nbDays = null,
  withInGame = false
) => {
  const eu = await fetcher(
    dispatch,
    `Wallet/Wallets?withDetail=${withDetail}&withInGame=${withInGame}&withInGameInfo=${withInGameInfo}${
      nbDays != null ? `&nbDays=${nbDays}` : ''
    }`,
    {},
  );
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_WALLETS,
      payload: eu,
    });
    // const euLocal = JSON.parse(localStorage.getItem('exchanges'));
    // if (withDetail || !euLocal)
    if (!withDetail)
      localStorage.setItem('exchanges', JSON.stringify(eu));
  }
};

export const getExchange =
  (id, withDetail = false, withInGameInfo = false, nbDays = null) =>
  async (dispatch, getState) => {
    const eu = await fetcher(
      dispatch,
      `Wallet?id=${id}&withDetail=${withDetail}&withInGameInfo=${withInGameInfo}${
        nbDays != null ? `&nbDays=${nbDays}` : ''
      }`,
      {},
    );
    if (eu) {
      dispatch({
        type: exchangeConstants.ACTION_GET_WALLET,
        payload: eu,
      });

      // for (const tokenType in listTokenType) {
      //   const table = getTableExchange(eu, tokenType);
      //   console.log(getState().config.exchangesGrid[tokenType].selection, table);
      //
      //   console.log(tokenType);
      //   // dispatch(createExchangesGridAction(tokenType,'selected', []))
      // }
    }
  };

export const postInsertInGameInfo = (data) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, 'Wallet/InsertInGameInfo', {
    method: 'POST',
    body: data,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_SCHOLARS,
      payload: eu,
    });
  }
};

export const getScholar = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Wallet/Scholar/${id}`, {});
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_SCHOLAR,
      payload: eu,
    });
  }
};

export const getScholars = (nbWeek, withWalletInfo = false) => async (dispatch) => {
  const eu = await fetcher(dispatch, `Wallet/Scholars?nbWeek=${nbWeek}&withWalletInfo=${withWalletInfo}`, {});
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_GET_SCHOLARS,
      payload: eu,
    });
  }
};

export const hideTransferPopup = () => async (dispatch) => {
  dispatch({ type: exchangeConstants.TRANSFER_POPUP, payload: false });
};

export const getAssets = (assets, address) => async (dispatch) => {
  const keysStr = JSON.stringify(assets.map((a) => a.Key+'-'+a.walletId));
  const eu = await fetcher(dispatch, `Wallet/Assets?keysStr=${keysStr}&address=${address}`, {});
  if (eu) {
    dispatch({
      type: imxConstants.ACTION_SET_ASSETS,
      payload: eu,
    });

    //Show popup
    dispatch({
      type: exchangeConstants.TRANSFER_POPUP,
      payload: true,
    });
  }
};

export const resetWallet = () => async (dispatch) => {
  dispatch({
    type: exchangeConstants.ACTION_RESET_WALLET,
  });
};

export const getExchanges =
  (withDetail = false, withInGameInfo = false, nbDays = null, withInGame = false) =>
  async (dispatch) => {
    const euLocal = JSON.parse(localStorage.getItem('exchanges'));
    if (euLocal) {
      dispatch({
        type: exchangeConstants.ACTION_LOCAL_WALLETS,
        payload: euLocal,
      });
    }
    await fetchExchanges(dispatch, withDetail, withInGameInfo, nbDays, withInGame);
  };

export const postExchanges = (exchangeUser) => async (dispatch, getState) => {
  exchangeUser.accountType = 'Wallets';
  const eu = await fetcher(dispatch, 'Wallet', {
    method: 'POST',
    body: exchangeUser,
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountCreated');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_POST_WALLET,
      payload: eu,
    });
    dispatch(push('/accounts'));
    await fetchExchanges(dispatch);
    // window.location.reload();
  }
};

export const postExchangeOnboarding = (exchangeUser) => async (dispatch) => {
  exchangeUser.accountType = 'Wallets';
  const eu = await fetcher(dispatch, 'Wallet', {
    method: 'POST',
    body: exchangeUser,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_POST_WALLET,
      payload: eu,
    });
  }
};

export const deleteExchanges = (id) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `Wallet/${id}`, {
    method: 'DELETE',
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountDeleted');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_DELETE_WALLET,
      payload: eu,
    });
  }
};

export const updateExchanges = (exchange) => async (dispatch, getState) => {
  const eu = await fetcher(dispatch, `Wallet/${exchange.id}`, {
    body: exchange,
    method: 'PUT',
  });
  if (eu) {
    const text = Translate(getState, 'exchanges.accountUpdated');
    dispatch(alertActions.alertSuccess(text));
    dispatch({
      type: exchangeConstants.ACTION_PUT_WALLET,
      payload: eu,
    });
    // localStorage.setItem(
    //   'exchanges',
    //   JSON.stringify(getState().user.exchanges)
    // );
    await fetchExchanges(dispatch);
    dispatch(push('/accounts'));
  }
};


export const postResetPwd = (walletId, code) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Wallet/ResetPwd', {
    method: 'POST',
    body: {walletId: walletId, code: code},
  });
  if (eu) {
    dispatch(alertActions.alertSuccess('Password updated'));
    dispatch(push('/accounts'));
  }
};

export const postExternal = (addExternal) => async (dispatch) => {
  // addExternal.accountType = 'Wallet';
  const eu = await fetcher(dispatch, 'Wallet', {
    method: 'POST',
    body: addExternal,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_PUT_WALLET,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};

export const putExternal =
  (addExternal) =>
  async (dispatch) => {
    addExternal.Assets = null;
    const eu = await fetcher(dispatch, `Wallet/${addExternal.WalletId}`, {
      method: 'PUT',
      body: addExternal,
    });
    if (eu) {
      dispatch({
        type: exchangeConstants.ACTION_PUT_WALLET,
        payload: eu,
      });
    }
  };

export const putExtra = (addExtra) => async (dispatch) => {
  addExtra.accountType = 'OffExchange';
  const eu = await fetcher(dispatch, `Wallet/${addExtra.id}`, {
    method: 'PUT',
    body: addExtra,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_PUT_WALLET,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};

export const postExtra = (addExtra) => async (dispatch) => {
  addExtra.accountType = 'OffExchange';
  const eu = await fetcher(dispatch, 'Wallet', {
    method: 'POST',
    body: addExtra,
  });
  if (eu) {
    dispatch({
      type: exchangeConstants.ACTION_POST_WALLET,
      payload: eu,
    });
    dispatch(push('/accounts'));
  }
};
