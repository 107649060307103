import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import buttonStyle from '../../assets/jss/material-dashboard-react/components/buttonStyle.jsx';
import { isNull } from '../../views/SmartTrade/Common';

class RegularButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledClick: false,
    };
  }
  render() {
    const {
      classes,
      color,
      round,
      children,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      onClick,
      ...rest
    } = this.props;
    let { timeout } = this.props;
    if (isNull(timeout)) timeout = 1000;
    const { disabledClick } = this.state;
    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });
    return (
      <Button
        {...rest}
        classes={muiClasses}
        className={btnClasses}
        disabled={disabled || disabledClick}
        onClick={() => {
          this.setState({ disabledClick: true });
          if (!isNull(onClick)) onClick();
          setTimeout(() => {
            this.setState({ disabledClick: false });
          }, timeout);
        }}
      >
        {children}
      </Button>
    );
  }
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'transparent',
    'default',
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
};

export default withStyles(buttonStyle)(RegularButton);
