import React, {useState} from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import {CTA_ADDRESS, getIMXClient, getWalletConnection} from "../../redux/imx/actions";
import {getArrayFromStrLow, isEmpty, isNull} from "../SmartTrade/Common";
import useLocalStorage from "../../hooks/useLocalStorage";
import {firstLower} from "../../helpers/general";
import useLocalStorageObj from "../../hooks/useLocalStorageObj";
import LabelCheckbox from "../../components/Form/LabelCheckbox";
import {SmallCheckBox} from "../../components/Fields/SmallCheckBox";
import useTranslation from "../../hooks/useTranslation";
import confirmation from "../../locales/confirmation.json";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import {
    dangerColor,
    darkLightColor,
    lightDarkColor,
    successColor
} from "../../assets/jss/material-dashboard-react";
import AssetsContainer from "../Accounts/AssetsContainer";
import {arrFilterTypes, fromImxToCard} from "../../helpers/card";
import {Card} from "@material-ui/core";
import FilterCTA from "../../components/CTA/FilterCTA";

export default function TransfersPage() {
    let totalAssetTransfer = [];
    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(false);
    const [emails, setEmails] = useLocalStorage(Array.from({length: 0}, (_, index) => 'rerootcta0' + (index + 1) + '@gmail.com').join('\n'));
    const [transfer, setTransfer] = useLocalStorageObj('transfer', {});
    const [destinationAddress, setDestinationAddress] = useLocalStorage('transfers.destinationAddress','0x83eeec26513f75362e59e7cdf95fc2ea48606e59');

    useTranslation(confirmation);
    const arrEmails = getArrayFromStrLow(emails);
    const goTransfer = async (batchTransfers, email) => {
        console.log('SEND', batchTransfers, email);
        // les envoyer par 500 ?
        setLoading(true);
        if (batchTransfers.length > 0) {
            const walletConnection = await getWalletConnection(email);
            const result  = await getIMXClient().batchNftTransfer(walletConnection, batchTransfers);
            totalAssetTransfer = totalAssetTransfer.concat(batchTransfers);
            console.log('SEND', batchTransfers, email, totalAssetTransfer);//, result);

        }
        setLoading(false);
    };
    const go = async (popupVerify) => {
        const imxClient = getIMXClient();

        for (const email of arrEmails) {
            if (isEmpty(email)) continue;
            const walletConnection = await getWalletConnection(email);
            let cursor = '';
            let nbElem = 200;
            let assets = [];
            while (nbElem === 200) {

                const assetResponse = await imxClient.listAssets({
                    collection: CTA_ADDRESS, // Collection contract address,
                    user: walletConnection.address, // Ethereum address of the user who owns these assets
                    pageSize: 200, // Page size of the result
                    status: 'imx', // Status of these assets | Allowed values: eth, imx, preparing_withdrawal, withdrawable, burned
                    cursor
                });
                console.log(assetResponse);
                cursor = assetResponse.cursor;
                assets = assets.concat(assetResponse.result);
                nbElem = assets.length;
            }
            // console.log(assets);

            let assetsFiltred = [...assets];
            for (const t of arrFilterTypes) {
                const arr = !isNull(t.values) ?
                    (transfer[t.name]?.map(x => x.toLowerCase()) ?? []) :
                    getArrayFromStrLow((transfer[t.name] ?? ''));

                const arrBan = !isNull(t.values) ?
                    (transfer[t.name + 'Ban']?.map(x => x.toLowerCase()) ?? []) :
                    getArrayFromStrLow((transfer[t.name + 'Ban'] ?? ''));
                //Take Only
                assetsFiltred = arr.length > 0 ?
                    assetsFiltred.filter(asset => arr.includes(asset.metadata[firstLower(t.name)]?.toString().toLowerCase())) :
                    assetsFiltred;

                // Remove Ban
                assetsFiltred = arrBan.length > 0 ?
                    assetsFiltred.filter(asset => !arrBan.includes(asset.metadata[firstLower(t.name)]?.toString().toLowerCase())) :
                    assetsFiltred;

                // console.log(t.name, assetsFiltred, arr, arrBan);
            }

            const currentBatchTransfers = assetsFiltred.map(asset => ({
                tokenId: asset.token_id.toString(),
                tokenAddress: CTA_ADDRESS,
                toAddress: destinationAddress,
                receiver: destinationAddress,
                asset
            }));

            console.log(currentBatchTransfers);
            if (popupVerify) {
                try {
                    await MySwal.fire({
                        title: 'Are you sure ?',
                        html: <Card style={{
                            padding: '8px',
                            color: lightDarkColor,
                            backgroundColor: darkLightColor
                        }}><AssetsContainer
                            directAsset={true}
                            exchange={{AssetsDto: currentBatchTransfers.map(x => fromImxToCard(x.asset))}}/></Card>,
                        icon: 'question',
                        minWidth: '800px',
                        showCancelButton: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        showDenyButton: true,
                        denyButtonText: `Cancel all`,
                        confirmButtonColor: successColor,
                        cancelButtonColor: dangerColor,
                        showDenyButtonColor: dangerColor,
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            ;
                        }
                        else if (result.isDenied) {
                            throw new Error('CancelAll');
                        } else {
                            throw new Error('Cancel');
                        }
                    });
                } catch ({name, message}) {
                    if (message === 'CancelAll')
                        return;
                    else
                        continue;
                }
            }
            if (currentBatchTransfers.length > 0) {
                await goTransfer(currentBatchTransfers, email);
            }
        }
        await MySwal.fire({
            title: 'Resume of transfer',
            html: <Card style={{padding: '8px', color:lightDarkColor, backgroundColor: darkLightColor}}>
                <AssetsContainer directAsset={true}
                                 exchange={{AssetsDto: totalAssetTransfer.map(x => fromImxToCard(x.asset))}} /></Card>,
            confirmButtonText: 'Ok',
            minWidth: '800px',
            confirmButtonColor: successColor,
        }).then(async (result) => {
            totalAssetTransfer = [];
        });
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom: '10px'}}>
                <GridContainer>
                    <GridItem xs>
                        <InputLabel>Destination Address</InputLabel>
                        <Input
                            value={destinationAddress}
                            onChange={(e) => setDestinationAddress(e.target.value)}
                            fullWidth={true}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <FilterCTA filter={transfer} setFilter={setTransfer} />

            <GridItem xs={12} sm={12} md={6}>
                <InputLabel>Emails</InputLabel>
                <Input
                    value={emails}
                    multiline={true}
                    fullWidth={true}
                    minRows={10}
                    onChange={(e) => setEmails(e.target.value)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <LabelCheckbox htmlFor={'verify'} style={{marginTop: '20px'}}>
                    Popup Verify
                </LabelCheckbox>
                &nbsp;
                <SmallCheckBox
                    id={'verify'}
                    onChange={() => setVerify(!verify)}
                    input={{value: verify}}
                />
                <Button color={'primary'} onClick={() => go(verify)}>Go {arrEmails.length}{loading && <CircularProgress style={{marginLeft: '4px'}} size={16} />}</Button>
                <Button onClick={() => setTransfer({})}>Clear all</Button>
                <pre>{JSON.stringify(transfer, null, 2)}</pre>
            </GridItem>
        </GridContainer>
    );

}
