export const cardsConstants = {
  ACTION_SET_DETAIL: '@tokens/ACTION_SET_DETAIL',
  ACTION_SET_ON_SELL: '@tokens/ACTION_SET_ON_SELL',
  ACTION_GET_ASSETS: '@tokens/ACTION_GET_ASSETS',
  ACTION_GET_SUPPLIES: '@tokens/ACTION_GET_SUPPLIES',
  ACTION_GET_CARDS_SOLD: '@tokens/ACTION_GET_CARDS_SOLD',
  ACTION_GET_CARDS_ON_SELL: '@tokens/ACTION_GET_CARDS_ON_SELL',
  ACTION_GET_CARDS: '@tokens/ACTION_GET_CARDS',
  ACTION_LOCAL_CARDS: '@tokens/ACTION_LOCAL_CARDS',
};
