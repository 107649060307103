import { useState} from 'react';

function useLocalStorageObj(key, initialValue) {
    // Récupère la valeur actuelle dans le localStorage
    const storedValue = localStorage.getItem(key);

    // Initialise l'état avec la valeur du localStorage ou avec une valeur initiale fournie
    const [value, setValue] = useState(!storedValue ? initialValue : JSON.parse(storedValue));

    // Fonction pour mettre à jour la valeur et la stocker dans le localStorage
    const updateValue = (newValue) => {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
    };
    if (!storedValue) updateValue(initialValue);
    return [value, updateValue];
}

export default useLocalStorageObj;