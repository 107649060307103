import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import {
  getFeeFromTrigger,
  getPriceTrigger,
  getStart,
  getStopLoss,
  getTriggerPrice,
  isTriggered,
  triggerIsDone,
} from '../../helpers/order';
import {
  nbStep,
  getProfitNetFromPrice,
  stepPriceSelector,
  parseToFloat,
  isEmpty,
  isNull,
  getPriceFromProfit,
  currentPriceSelector,
  capitalSelector,
  secPriceSelector,
} from '../SmartTrade/Common';
import Divider from '@material-ui/core/Divider/Divider';
import React from 'react';

import Done from '@material-ui/icons/Done';
import AccessTime from '@material-ui/icons/AccessTime';
import Stop from '@material-ui/icons/Stop';
import Error from '@material-ui/icons/Error';
import Cancel from '@material-ui/icons/Cancel';
import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingDown from '@material-ui/icons/TrendingDown';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Eject from '@material-ui/icons/Eject';
import TrackChanges from '@material-ui/icons/TrackChanges';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import connect from 'react-redux/es/connect/connect';
import { Translate } from 'react-localize-redux';
import { getActiveCode, getDateToFormat } from '../../helpers/localize';
import Danger from '../../components/Typography/Danger';
import { createTradingViewJson } from '../../redux/order/actions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

export const getColorStateActions = (action) => {
  if (action) {
    if (action.OrderId === 0) return 'action'; //preview
    const stateAction = action.State;

    if ([0, 4].includes(stateAction)) return 'disabled';
    if ([1, 6].includes(stateAction)) return 'primary';
    if ([3].includes(stateAction)) return 'action'; //finish
    if ([5].includes(stateAction)) return 'error';
  }
  // if ([0, 1].includes(state)) return 'disabled';
  // if ([4].includes(state)) return 'primary';
  // if ([2, 3, 5].includes(state)) return 'action'; //finish
  // if ([7, 8].includes(state)) return 'error';
  return 'error';
};

const getIconState = (action, size = 'medium') => {
  const color = getColorStateActions(action);
  if (action) {
    const stateAction = action.State;
    switch (stateAction) {
      case 0:
        return <Stop fontSize={size} color={color} />;
      case 1:
        return <AccessTime fontSize={size} color={color} />;
      case 2: //Triggered
        return <Error fontSize={size} color="error" />; //Never used
      case 3:
        return <Done fontSize={size} color={color} />;
      case 4: //Cancelled
        return <Cancel fontSize={size} color={color} />;
      case 5: //Error
        return <Error fontSize={size} color="error" />;
      case 6: //Trailing
        return <TrackChanges fontSize={size} color={color} />;
      default:
        //ERROR
        return <Error fontSize={size} color="error" />;
    }
  }
  return '';
};

export const getTooltip = (action, inside, codeLang) => {
  if (isNull(action)) return inside;
  const errMsg = getErrorMessage(action);
  const title =
    action && action.DateTrigged
      ? new Date(action.DateTrigged).toLocaleString(codeLang) +
        (isEmpty(errMsg) ? '' : ' | ' + errMsg)
      : action.State === 6
      ? 'Trailing'
      : 'Waiting';
  if (isEmpty(title)) return inside;
  return (
    <Tooltip title={title} enterDelay={200} leaveDelay={200}>
      {inside}
    </Tooltip>
  );
};

export const getIconStateActions = (
  action,
  codeLang = 'gb',
  size = 'medium',
) => {
  if (!action) return '';
  return getTooltip(action, getIconState(action, size), codeLang);
};

export const getErrorMessage = (action) => {
  const order = action.Order;
  const msg = isEmpty(action.ErrorMsg)
    ? !isEmpty(order)
      ? order.ErrorMsg
      : ''
    : action.ErrorMsg;
  if (msg === 'popup.optionsPaiement')
    return <Translate id="smartTrade.optionsPaiement" />;
  if (isEmpty(msg)) return '';
  return msg;
};

const getTooltipStr = (action) => {
  if (action) {
    const stateAction = action.State;
    switch (stateAction) {
      case 0: //Off
        return 'Off';
      case 1: //Wait
        return 'Wait';
      case 2: //Triggered
        return 'Trigged';
      case 3: //Done
        return 'Done';
      case 4: //Cancelled
        return 'Cancelled';
      case 5: //Error
        return getErrorMessage(action);
      case 6: //Trailing
        return 'Trailing';
      default:
        return 'Unknown';
    }
  }
  return '';
};

export const getIconAction = (action) => {
  const color = getColorStateActions(action);
  if (action.Action === 0) return <TrendingUp color={color} />;
  if (action.Action === 1) return <TrendingDown color={color} />;
  if (action.Action === 2) return <PlayArrow color={color} />;
  if (action.Action === 3) return <Eject color={color} />;
};

class ActionOrderLine extends React.PureComponent {
  render() {
    const {
      order,
      action,
      codeLang,
      stepPrice,
      currentPrice,
      capital,
      secPrice,
    } = this.props;
    if (!order) return <div />;
    const isPreview = order.OrderId === 0;
    if (isPreview && action.State === 4) return <div />;
    const pairs = order.Pair.split('-');
    const start = getStart(order.Triggers);
    const stopLoss = getStopLoss(order.Triggers);
    const leverage = isEmpty(order.Leverage) ? 1 : order.Leverage;
    const startPrice = getTriggerPrice(start);
    const errorMsg = getErrorMessage(action);
    const fee = getFeeFromTrigger(action, order);
    const isStart = action.Action === 2;
    const isSL = action.Action === 1;
    const isTemplate = order.State === 9;

    const getPrice = () => {
      const basePrice = startPrice === 0 ? currentPrice : startPrice;
      if (action.Price === null)
        return parseToFloat(
          getPriceFromProfit(action.Profit, order.IsBuy, basePrice, fee), // * leverage,
          stepPrice,
        );
      return getPriceTrigger(action, nbStep(stepPrice));
    };

    const getProfit = () => {
      const basePrice = startPrice === 0 ? currentPrice : startPrice;
      const price = getTriggerPrice(action);
      if (price === null && action.Profit !== null)
        return ((action.Profit - fee * 100 * 2) * leverage).toFixed(2);
      const profit = getProfitNetFromPrice(price, order.IsBuy, basePrice, fee);
      return Number(profit * leverage * 100).toFixed(2);
    };

    return (
      <GridContainer key={action.TriggerId}>
        <GridItem>
          <Tooltip
            title={getTooltipStr(action)}
            enterDelay={200}
            leaveDelay={200}
          >
            {getIconAction(action)}
          </Tooltip>
          {!isStart && (
            <div>
              <span>{action.Pourcentage}%</span>
            </div>
          )}
          {action.Trailing && (
            <div>
              <span>
                {getIconStateActions(
                  { State: 6, OrderId: 0 },
                  codeLang,
                  'inherit',
                )}
                : {action.Trailing}%
              </span>
            </div>
          )}
        </GridItem>
        {!isStart && (
          <GridItem
            container
            xs
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <GridItem container xs> */}
            {!isTemplate && (
              <GridItem xs>
                <div>
                  <Translate id="smartTrade.price" />
                  <br />
                  {action.Price === null ? (
                    <span>
                      {getPrice()}&nbsp;{pairs[1]}
                    </span>
                  ) : (
                    <b>
                      {getPrice()}&nbsp;{pairs[1]}
                    </b>
                  )}
                </div>
              </GridItem>
            )}
            <GridItem>
              <Translate id="smartTrade.profitNet" />
              <br />
              {action.Profit === null ? (
                <span>{getProfit()} &nbsp; %</span>
              ) : (
                <b>{getProfit()} &nbsp; %</b>
              )}
            </GridItem>
          </GridItem>
        )}
        {isStart && (
          <GridItem container xs>
            <div>
              {!isNull(action.TradingViewGuid) && (
                <div>
                  {isNull(action.Guid) && (
                    <small>
                      <Translate id="orders.tvmessage" />:
                      <div>
                        {JSON.stringify(
                          createTradingViewJson(action.TradingViewGuid),
                        )}
                      </div>
                    </small>
                  )}
                  {!isNull(action.Guid) && (
                    <small>
                      <Translate id="orders.tvmessageDone" />
                    </small>
                  )}
                </div>
              )}
              {action.TriggerPrice && action.TriggerPrice > 0 && (
                <div>
                  <small>
                    {!isTriggered(action) && action.TriggerType === 1 ? (
                      <Translate
                        id="orders.startIfPriceHigher"
                        options={{ renderInnerHtml: true }}
                      />
                    ) : (
                      ''
                    )}
                    {!isTriggered(action) && action.TriggerType === 2 ? (
                      <Translate
                        id="orders.startIfPriceLower"
                        options={{ renderInnerHtml: true }}
                      />
                    ) : (
                      ''
                    )}
                    {isTriggered(action) && action.TriggerType === 1 ? (
                      <Translate
                        id="orders.triggeredPriceHigher"
                        options={{ renderInnerHtml: true }}
                      />
                    ) : (
                      ''
                    )}
                    {isTriggered(action) && action.TriggerType === 2 ? (
                      <Translate
                        id="orders.triggeredPriceLower"
                        options={{ renderInnerHtml: true }}
                      />
                    ) : (
                      ''
                    )}{' '}
                    <b>{action.TriggerPrice}</b> {pairs[1]} :
                  </small>
                  <br />
                </div>
              )}
              {isEmpty(order.Quantity) && (
                <div>
                  <div>
                    {order.Pourcentage} %
                    <ArrowRightAltIcon style={{ marginBottom: '-6px' }} />
                    {parseToFloat(
                      (((capital * order.Pourcentage) / 100) * leverage) /
                        secPrice,
                      stepPrice,
                    )}{' '}
                    {pairs[1]}
                  </div>
                </div>
              )}
              {!isEmpty(order.Quantity) && (
                <div>
                  <div>
                    {order.IsBuy ? '+' : '-'}
                    {order.Quantity} {pairs[0]}
                    {' x '}
                    {getPriceTrigger(action, nbStep(stepPrice))} {pairs[1]}
                  </div>
                  <div>
                    {'= '}
                    {!order.IsBuy ? '+' : '-'}
                    {parseToFloat(order.Quantity * startPrice, stepPrice)}{' '}
                    {pairs[1]}
                  </div>
                </div>
              )}
            </div>
          </GridItem>
        )}
        <GridItem>
          <div style={{ float: 'right', width: '30px' }}>
            {!isPreview && getIconStateActions(action, codeLang)}
          </div>
          <br />
          <small>
            {action.OrderType === 0 ? ( //Limit
              <Translate id="orders.limit" />
            ) : (
              <Translate id="orders.market" />
            )}
          </small>
          {/*</Tooltip>*/}
        </GridItem>
        {!triggerIsDone(action) && !isNull(action.TimeOut) && (
          <GridItem xs={12} sm={12} md={12} style={{ width: 0 }}>
            <small>
              <Translate
                id={
                  'smartTrade.' + (isStart ? 'entryTimeout' : 'stopLossTimeout')
                }
              />
              : {getDateToFormat(action.TimeOut, codeLang)}
            </small>
          </GridItem>
        )}
        {!isEmpty(errorMsg) && (
          <GridItem xs={12} sm={12} md={12} style={{ width: 0 }}>
            <Danger>
              <small>{errorMsg}</small>
            </Danger>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Divider style={{ margin: '3px 0px' }} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    codeLang: getActiveCode(state),
    stepPrice: stepPriceSelector(state, props),
    currentPrice: currentPriceSelector(state, props),
    capital: capitalSelector(state, props),
    secPrice: secPriceSelector(state, props),
  };
};

export default connect(mapStateToProps, null)(ActionOrderLine);
