import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { createWorkersGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import { ButtonWorkersProvider, DateProvider } from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';
import withWidth from '@material-ui/core/withWidth';
import { isNull } from '../SmartTrade/Common';

class TableWorkers extends React.PureComponent {
  render() {
    const {
      columnWidths,
      colorColumns,
      columnOrder,
      rows,
      columns,
      filters,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      totalSummaryItems,
      onFiltersChange,
      onPageSizeChange,
      onColumnOrderChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          <SummaryState totalItems={totalSummaryItems} />
          {/*<SelectionState />*/}

          {/*<GroupingState />*/}
          {/*<IntegratedGrouping />*/}

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          {/*<IntegratedSelection />*/}

          <DragDropProvider />

          <ButtonWorkersProvider for={['WorkerId']} />

          <Table />
          {/*<TableSelection showSelectAll={true} />*/}
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableSummaryRow />
          <TableFilterRow showFilterSelector={true} />
          <PagingPanel pageSizes={pageSizes} />
          {/*<TableGroupRow />*/}
          {/*<Toolbar />*/}
          {/*<GroupingPanel showSortingControls={true} />*/}
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workerType } = props;
  const { config } = state;
  const { workersGrid } = config;
  const {
    filters,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
  } = workersGrid;
  const codeLang = getActiveCode(state);
  const workers = props.workers ? props.workers : [];
  let ret = {
    codeLang: codeLang,
    rows: workers,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    filters: filters,
    pageSizes: pageSizes,
    sorting: sorting,
    totalSummaryItems: totalSummaryItems,
    columns: [
      { name: 'WorkerId', title: 'WorkerId' },
      { name: 'Name', title: 'Name' },
      { name: 'Description', title: 'Description' },
      {
        name: 'NbWallets',
        title: 'NbWallets',
        getCellValue: (row) =>
          isNull(row.Wallets)
            ? ''
            : row.Wallets.length < 2
            ? row.Wallets.map((wallet) => wallet.Name)
            : row.Wallets.length,
      },
      // // { name: 'CostByTriselUsed', title: 'CostByTriselUsed for 1000' },
      // { name: 'ValueUSD', title: 'ValueUSD' },
    ],
  };
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  onSortingChange: (sorting) =>
    dispatch(createWorkersGridAction('sorting', sorting)),
  onSelectionChange: (selection) =>
    dispatch(createWorkersGridAction('selection', selection)),
  onExpandedRowIdsChange: (expandedRowIds) =>
    dispatch(createWorkersGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: (grouping) =>
    dispatch(createWorkersGridAction('grouping', grouping)),
  onExpandedGroupsChange: (expandedGroups) =>
    dispatch(createWorkersGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: (filters) =>
    dispatch(createWorkersGridAction('filters', filters)),
  onCurrentPageChange: (currentPage) =>
    dispatch(createWorkersGridAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(createWorkersGridAction('pageSize', pageSize)),
  onColumnOrderChange: (order) =>
    dispatch(createWorkersGridAction('columnOrder', order)),
  onColumnWidthsChange: (widths) =>
    dispatch(createWorkersGridAction('columnWidths', widths)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableWorkers));
