import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../../../components/CustomButtons/Button.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import {postExternal, postResetPwd, putExternal} from '../../../redux/user/actions';
import { Translate } from 'react-localize-redux';
import {
  isAdminSelector,
  userIdSelector,
} from '../../../redux/authentication/selector';
import Link from "@material-ui/core/Link";

class FormResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  postResetPwd = () => {
    const { myForm, walletId } = this.props;
    const split = myForm.values.Url.split('/');
    const code = split[split.length - 1];
    this.props.postResetPwdActions(walletId, code)
  };

  render() {
    const { myForm } = this.props;

    return (
      <div>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Link
                  href={'https://crosstheages.com/portal/reset-password'}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Reset Password CTA
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="Url"
                label={"URL"}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={this.postResetPwd}
            color="primary"
            round
            disabled={myForm && myForm.syncErrors}
          >
            Send
          </Button>
        </GridContainer>
      </div>
    );
  }
}

let formResetPwd = reduxForm({
  form: 'formResetPwd', // a unique identifier for this form
  validate: (values) => {
    const errors = {};
    const requiredFields = ['Url'];
    requiredFields.forEach((field) => {
      if (
          (values[field] &&
              Array.isArray(values[field]) &&
              values[field].length === 0) ||
          !values[field]
      ) {
        errors[field] = <Translate id="central.required" />;
      }
    });

    return errors;
  },
  // asyncValidate
})(FormResetPwd);

const mapStateToProps = (state, props) => {
  const { form } = state;
  const { wallet } = props;
  return {
    initialValues: wallet,
    myForm: form.formResetPwd,
    userId: userIdSelector(state),
    isAdmin: isAdminSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    postResetPwdActions: bindActionCreators(postResetPwd, dispatch),
  };
};

formResetPwd = connect(mapStateToProps, mapDispatchToProps)(formResetPwd);

export default formResetPwd;
