import moment from 'moment';
import MomentUtils from '@date-io/moment';
import React from 'react';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'moment/locale/fr';
import 'moment/locale/ru';
import { bindActionCreators } from 'redux';
import { change, formValueSelector, touch } from 'redux-form';
import { connect } from 'react-redux';
import {
  formatDateEN,
  getDateFormat,
  getIndexLang,
} from '../../helpers/localize';
import { Translate } from 'react-localize-redux';
import CurrentServerDateTime from './CurrentServerDateTime';

const MyDateTimePicker = (props) => {
  const { value, formName, name, disablePast, onChange } = props;
  let lang = Object.hasOwn(props, 'lang') ? props.lang : 'en';
  if (lang === 'gb') lang = 'en';

  moment.locale(lang); // it is required to select default locale manually
  const locale = lang;

  const format = getDateFormat(lang);
  const keyLang = getIndexLang(lang);
  const invalidDateMessages = [
    'La date est invalide',
    'Invalid Date Format',
    'Неверный формат даты',
  ];
  const minDateMessages = [
    'La date doit être avant la date minimum',
    'Date should not be before minimal date',
    'Дата меньше мин даты',
  ];
  const maxDateMessages = [
    'La date doit être après la date maximum',
    'Date should not be after maximal date',
    'Дата больше макс даты',
  ];
  return (
    <div style={props.style}>
      <div>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={locale}
        >
          <KeyboardDateTimePicker
            value={value}
            onChange={(date) => {
              const format = date.format(formatDateEN);
              if (onChange) onChange(format);
              if (formName) props.change(formName, name, format);
            }}
            disablePast={disablePast === undefined ? true : disablePast}
            autoOk
            ampm={false}
            format={format}
            invalidDateMessage={invalidDateMessages[keyLang]}
            minDateMessage={minDateMessages[keyLang]}
            maxDateMessage={maxDateMessages[keyLang]}
          />
        </MuiPickersUtilsProvider>
      </div>
      <small>
        <Translate id="central.currentServerDateTime" />:{' '}
        <CurrentServerDateTime codeLang={lang} />
      </small>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  if (props.formName) {
    const formSelector = formValueSelector(props.formName);
    const name = props.name;
    return {
      value: formSelector(state, name),
    };
  }
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDateTimePicker);
