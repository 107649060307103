import React from 'react';
import {
    SortingState,
    FilteringState,
    IntegratedFiltering,
    IntegratedSorting,
    IntegratedPaging,
    PagingState,
    DataTypeProvider,
    IntegratedSummary,
    SummaryState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableFilterRow,
    TableColumnResizing,
    TableColumnReordering,
    DragDropProvider,
    PagingPanel,
    TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { createSuppliesGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import {
    ArkhomeProvider, NameAndImageProvider,
    NumberEditorBase,
    numberFilterOperations, PourcentageProvider, RarityProvider,
} from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';

import withWidth from '@material-ui/core/withWidth';
import {listArkhome, listCollection, listRarity} from "../../helpers/general";
import {isEmpty} from "../SmartTrade/Common";

class TableSupplies extends React.PureComponent {
    render() {
        const {
            columnWidths,
            colorColumns,
            columnOrder,
            rows,
            columns,
            filters,
            currentPage,
            pageSize,
            pageSizes,
            sorting,
            totalSummaryItems,
            onFiltersChange,
            onPageSizeChange,
            onColumnOrderChange,
            onCurrentPageChange,
            onSortingChange,
            onColumnWidthsChange,
        } = this.props;

        return (
            <Card>
                <Grid rows={rows} columns={columns}>
                    <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
                    <SortingState
                        defaultSorting={sorting}
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                    />
                    <PagingState
                        defaultCurrentPage={currentPage}
                        defaultPageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                        onCurrentPageChange={onCurrentPageChange}
                    />
                    <SummaryState totalItems={totalSummaryItems} />
                    {/*<SelectionState />*/}

                    {/*<GroupingState />*/}
                    {/*<IntegratedGrouping />*/}

                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <IntegratedSummary />
                    {/*<IntegratedSelection />*/}

                    <DragDropProvider />
                    <RarityProvider for={['Rarity']} />
                    <NameAndImageProvider for={['Name']} />
                    <ArkhomeProvider for={['Element']} />
                    <PourcentageProvider for={['PourcSupplyFoil', 'PourcSupplyNoFoil']} />
                    <DataTypeProvider
                        for={[
                            'CardId',
                            'Total',
                            'TotalFoil',
                            'TotalNoFoil',
                            'PourcSupplyFoil',
                            'PourcSupplyNoFoil',
                            'Foil.1',
                            'Foil.2',
                            'Foil.3',
                            'Foil.4',
                            'Foil.5',
                            'NoFoil.1',
                            'NoFoil.2',
                            'NoFoil.3',
                            'NoFoil.4',
                            'NoFoil.5',
                            'Foil.C',
                            'Foil.B',
                            'Foil.A',
                            'Foil.S',
                            'NoFoil.C',
                            'NoFoil.B',
                            'NoFoil.A',
                            'NoFoil.S',
                            'AnimationLevel',
                        ]}
                        editorComponent={NumberEditorBase}
                        availableFilterOperations={numberFilterOperations}
                    />

                    <Table />
                    {/*<TableSelection showSelectAll={true} />*/}
                    <TableColumnResizing
                        defaultColumnWidths={columnWidths}
                        columnWidths={columnWidths}
                        onColumnWidthsChange={onColumnWidthsChange}
                    />
                    <TableHeaderRow showSortingControls={true} />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={onColumnOrderChange}
                    />
                    <TableSummaryRow />
                    <TableFilterRow showFilterSelector={true} />
                    <PagingPanel pageSizes={pageSizes} />
                    {/*<TableGroupRow />*/}
                    {/*<Toolbar />*/}
                    {/*<GroupingPanel showSortingControls={true} />*/}
                </Grid>
            </Card>
        );
    }
}

function getColumnOrder(foil) {
    return [
        foil+'.1',
        foil+'.2',
        foil+'.3',
        foil+'.4',
        foil+'.5',
    ];
}

function getColumn(foil) {
    const title = foil === 'Foil' ? 'F ' : 'NF ';
    return [
        { name: foil + '.1', title: title+'1', getCellValue: (row) => row[foil][''] ?? 0 },
        { name: foil + '.2', title: title+'2', getCellValue: (row) => row[foil][''] ?? 0 },
        { name: foil + '.3', title: title+'3', getCellValue: (row) => row[foil][''] ?? 0 },
        { name: foil + '.4', title: title+'4', getCellValue: (row) => row[foil][''] ?? 0 },
        { name: foil + '.5', title: title+'5', getCellValue: (row) => row[foil][''] ?? 0 },
    ];
}

function getColumnWidths(foil) {
    return [
        { columnName: foil + '.1', width: 75 },
        { columnName: foil + '.2', width: 75 },
        { columnName: foil + '.3', width: 75 },
        { columnName: foil + '.4', width: 75 },
        { columnName: foil + '.5', width: 75 },
    ];
}

function getTotalSummaryItems(foil) {
    return [
        { columnName: foil + '.1', type: 'sum' },
        { columnName: foil + '.1', type: 'avg' },
        { columnName: foil + '.2', type: 'sum' },
        { columnName: foil + '.2', type: 'avg' },
        { columnName: foil + '.3', type: 'sum' },
        { columnName: foil + '.3', type: 'avg' },
        { columnName: foil + '.4', type: 'sum' },
        { columnName: foil + '.4', type: 'avg' },
        { columnName: foil + '.5', type: 'sum' },
        { columnName: foil + '.5', type: 'avg' },
    ];
}

function getColumnOrderAlt(foil) {
    return [
        foil+'.C',
        foil+'.B',
        foil+'.A',
        foil+'.S',
    ];
}

function getColumnAlt(foil) {
    const title = foil === 'Foil' ? 'F ' : 'NF ';
    return [
        { name: foil + '.C', title: title+'C', getCellValue: (row) => row[foil]['C'] ?? 0 },
        { name: foil + '.B', title: title+'B', getCellValue: (row) => row[foil]['B'] ?? 0 },
        { name: foil + '.A', title: title+'A', getCellValue: (row) => row[foil]['A'] ?? 0 },
        { name: foil + '.S', title: title+'S', getCellValue: (row) => row[foil]['S'] ?? 0 },
    ];
}

function getColumnWidthsAlt(foil) {
    return [
        { columnName: foil + '.C', width: 75 },
        { columnName: foil + '.B', width: 75 },
        { columnName: foil + '.A', width: 75 },
        { columnName: foil + '.S', width: 75 },
    ];
}

function getTotalSummaryItemsAlt(foil) {
    return [
        { columnName: foil + '.C', type: 'sum' },
        { columnName: foil + '.C', type: 'avg' },
        { columnName: foil + '.B', type: 'sum' },
        { columnName: foil + '.B', type: 'avg' },
        { columnName: foil + '.A', type: 'sum' },
        { columnName: foil + '.A', type: 'avg' },
        { columnName: foil + '.S', type: 'sum' },
        { columnName: foil + '.S', type: 'avg' },
    ];
}


const mapStateToProps = (state, props) => {
    const { config } = state;
    const { suppliesGrid } = config;
    const { supplies, supplyType, set, groupAnimationLevel, groupElement } = props;
    let {
        filters,
        pageSizes,
        columnOrder,
        columnWidths,
        sorting,
        totalSummaryItems,
    } = suppliesGrid;
    const codeLang = getActiveCode(state);
    let columns = [
        { name: 'CardId', title: 'Id' },
        { name: 'Name', title: 'Name' },
        { name: 'Rarity', title: 'Rarity', getCellValue: (row) => listRarity[row.Rarity] },
        { name: 'Total', title: 'Total' },
        { name: 'TotalFoil', title: 'Foil (F)' },
        { name: 'TotalNoFoil', title: 'No Foil (NF)' },
        { name: 'PourcSupplyFoil', title: '% F', getCellValue: (row) => isEmpty(row.MaxFoil) ? 0 : row.TotalFoil / row.MaxFoil * 100 },
        { name: 'PourcSupplyNoFoil', title: '% NF', getCellValue: (row) => isEmpty(row.MaxNoFoil) ? 0 : row.TotalNoFoil / row.MaxNoFoil * 100 },

        // { name: 'Prana', title: 'Prana' },
        // {
        //   name: 'QuestDay',
        //   title: 'D. Completed',
        //   getCellValue: (row) => row.Quests['DAILY'].NbQuest,
        // },
    ];
    if (supplyType === 0 || supplyType === 3) {
        columns = columns.concat(getColumn('Foil'));
        columnWidths = columnWidths.concat(getColumnWidths('Foil'));
        columnOrder = columnOrder.concat(getColumnOrder('Foil'));
        totalSummaryItems = totalSummaryItems.concat(getTotalSummaryItems('Foil'));

        columns = columns.concat(getColumn('NoFoil'));
        columnWidths = columnWidths.concat(getColumnWidths('NoFoil'));
        columnOrder = columnOrder.concat(getColumnOrder('NoFoil'));
        totalSummaryItems = totalSummaryItems.concat(getTotalSummaryItems('NoFoil'));
    }
    if (supplyType > 0) {
        columns = columns.concat(getColumnAlt('Foil'));
        columnWidths = columnWidths.concat(getColumnWidthsAlt('Foil'));
        columnOrder = columnOrder.concat(getColumnOrderAlt('Foil'));
        totalSummaryItems = totalSummaryItems.concat(getTotalSummaryItemsAlt('Foil'));

        columns = columns.concat(getColumnAlt('NoFoil'));
        columnWidths = columnWidths.concat(getColumnWidthsAlt('NoFoil'));
        columnOrder = columnOrder.concat(getColumnOrderAlt('NoFoil'));
        totalSummaryItems = totalSummaryItems.concat(getTotalSummaryItemsAlt('NoFoil'));
    }
    if (supplyType === 3)
    {
        columns = columns.concat([{ name: 'Advancement', title: 'Advancement' }]);
        columnWidths = columnWidths.concat([{ columnName: 'Advancement', width: 140 }]);
        columnOrder = columnOrder.concat(['Advancement']);
    }
    if (set === null) {
        columns = columns.concat([{ name: 'Set', title: 'Set', getCellValue: (row) => listCollection[row.Set] }]);
        columnWidths = columnWidths.concat([{ columnName: 'Set', width: 75 }]);
        columnOrder = columnOrder.concat(['Set']);
    }
    if (!groupAnimationLevel) {
        columns = columns.concat([{ name: 'AnimationLevel', title: 'AnimationLevel' }]);
        columnWidths = columnWidths.concat([{ columnName: 'AnimationLevel', width: 75 }]);
        columnOrder = columnOrder.concat(['AnimationLevel']);
    }
    if (!groupElement) {
        columns = columns.concat([{ name: 'Element', title: 'Element', getCellValue: (row) => listArkhome[row.Element] }]);
        columnWidths = columnWidths.concat([{ columnName: 'Element', width: 100 }]);
        columnOrder = columnOrder.concat(['Element']);
    }
    let ret = {
        codeLang: codeLang,
        rows: supplies,
        columnOrder: columnOrder,
        columnWidths: columnWidths,
        filters: filters,
        pageSizes: pageSizes,
        sorting: sorting,
        totalSummaryItems: totalSummaryItems,
        columns: columns,
    };
    return ret;
};

const mapDispatchToProps = (dispatch) => ({
    onSortingChange: (sorting) =>
        dispatch(createSuppliesGridAction('sorting', sorting)),
    onSelectionChange: (selection) =>
        dispatch(createSuppliesGridAction('selection', selection)),
    onExpandedRowIdsChange: (expandedRowIds) =>
        dispatch(createSuppliesGridAction('expandedRowIds', expandedRowIds)),
    onGroupingChange: (grouping) =>
        dispatch(createSuppliesGridAction('grouping', grouping)),
    onExpandedGroupsChange: (expandedGroups) =>
        dispatch(createSuppliesGridAction('expandedGroups', expandedGroups)),
    onFiltersChange: (filters) =>
        dispatch(createSuppliesGridAction('filters', filters)),
    onCurrentPageChange: (currentPage) =>
        dispatch(createSuppliesGridAction('currentPage', currentPage)),
    onPageSizeChange: (pageSize) =>
        dispatch(createSuppliesGridAction('pageSize', pageSize)),
    onColumnOrderChange: (order) =>
        dispatch(createSuppliesGridAction('columnOrder', order)),
    onColumnWidthsChange: (widths) =>
        dispatch(createSuppliesGridAction('columnWidths', widths)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWidth()(TableSupplies));
