import React from 'react';
import {
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedPaging,
  PagingState,
  SummaryState,
  IntegratedSummary,
  DataTypeProvider, SelectionState, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  PagingPanel,
  TableSummaryRow,
  TableColumnReordering,
  DragDropProvider, TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { createExchangesGridAction } from '../../redux/config/actions';
import Card from '../../components/Card/Card';
import {
  ArkhomeProvider,
  ImageAndLinksProvider,
  DollarProvider,
  NumberEditorBase,
  numberFilterOperations,
  PourcentageProvider,
  RarityProvider,
  LinksProvider,
  DateProvider,
} from '../../helpers/table';
import { getActiveCode } from '../../helpers/localize';
import withWidth from '@material-ui/core/withWidth';
import {
  getColumnsAssets,
  getColumnsCard,
  getColumnsMintPass,
  getColumnsSouvenirCard,
} from '../../helpers/card';
import {configConstants} from "../../redux/config/constants";
import {isNull} from "../../views/SmartTrade/Common";

class TableUIWallet extends React.PureComponent {
  componentDidMount() {
    const { selection } = this.props;
    this.props.onSelectionChange(selection);
  }
  render() {
    const {
      tokenType,
      columnWidths,
      colorColumns,
      columnOrder,
      rows,
      columns,
      filters,
      selection,
      currentPage,
      pageSize,
      pageSizes,
      sorting,
      totalSummaryItems,
      onFiltersChange,
      onSelectionChange,
      onPageSizeChange,
      onColumnOrderChange,
      onCurrentPageChange,
      onSortingChange,
      onColumnWidthsChange,
    } = this.props;

    return (
      <Card>
        <Grid rows={rows} columns={columns}>
          <FilteringState filters={filters} onFiltersChange={onFiltersChange} />
          <SortingState
            defaultSorting={sorting}
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
          <PagingState
            defaultCurrentPage={currentPage}
            defaultPageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
          />
          {/*<SelectionState*/}
          {/*    selection={selection}*/}
          {/*    onSelectionChange={onSelectionChange}*/}
          {/*/>*/}
          <SummaryState totalItems={totalSummaryItems} />

          <IntegratedFiltering />
          <IntegratedSorting />
          {/*<IntegratedSelection />*/}
          <IntegratedPaging />
          <IntegratedSummary />

          <DragDropProvider />
          <ImageAndLinksProvider for={['Image']} />
          <LinksProvider for={['FP', 'LS']} />
          <ArkhomeProvider for={['Arkhome']} />
          <RarityProvider for={['Rarity']} />
          <PourcentageProvider for={['RatioSupply', 'Ratio']} />
          <DollarProvider
            for={[
              'FPPrice',
              'LSPrice',
              'OnSellPrice',
              'LSDaysValueUSD',
              'LSDaysAvg',
              'LSDaysAvgEth',
              'LSDaysAvgUsdc',
              'LSDaysMin',
              'LSDaysMax',
            ]}
          />
          <DateProvider for={['UpdatedAt']} />
          <DataTypeProvider
            for={[
              'Count',
              'LSDaysCount',
              'Ian',
              'CardId',
              'Value',
              'Amount',
              'Rank',
              'AnimationLevel',
              'Power',
            ]}
            editorComponent={NumberEditorBase}
            availableFilterOperations={numberFilterOperations}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableHeaderRow showSortingControls={true} />
          {/*<TableSelection showSelectAll />*/}
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableSummaryRow />
          <TableFilterRow showFilterSelector={true} />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { tokenType } = props;
  const { config } = state;
  const { exchangesGrid, transfersGrid } = config;
  const grid = isNull(props.type) ? exchangesGrid : props.type === 'transfer' ? transfersGrid : exchangesGrid;
  const {
    filters,
    selection,
    pageSizes,
    columnOrder,
    columnWidths,
    sorting,
    totalSummaryItems,
  } = grid[tokenType];
  const codeLang = getActiveCode(state);
  const exchanges = props.exchange ? props.exchange : [];
  const withInfoPrice = props.withInfoPrice === undefined ? true : props.withInfoPrice;
  let columns = [];
  if (tokenType === 0) columns = getColumnsCard((row) => row, codeLang, withInfoPrice);
  else if (tokenType === 1)
    columns = getColumnsMintPass((row) => row, codeLang, withInfoPrice);
  else if (tokenType === 2) columns = getColumnsAssets((row) => row, codeLang, withInfoPrice);
  else if (tokenType === 3)
    columns = getColumnsSouvenirCard((row) => row, codeLang, withInfoPrice);
  else columns = getColumnsAssets((row) => row, codeLang, withInfoPrice);

  let ret = {
    codeLang: codeLang,
    rows: exchanges,
    columnOrder: columnOrder,
    columnWidths: columnWidths,
    totalSummaryItems: totalSummaryItems,
    filters: filters,
    selection: selection,
    pageSizes: pageSizes,
    sorting: sorting,
    columns: columns.concat(withInfoPrice ? [
      { name: 'walletName', title: 'walletName' },
      { name: 'login', title: 'login' },
      { name: 'Count', title: 'Count' },
      {
        name: 'FPPrice',
        title: 'FPPrice',
        getCellValue: (row) => row.FP?.V ?? 0,
      },
      {
        name: 'FP',
        title: 'FP',
        getCellValue: (row) => row.FP ?? null,
      },
      {
        name: 'LSPrice',
        title: 'LSPrice',
        getCellValue: (row) => row.LS?.V ?? 0,
      },
      {
        name: 'LS',
        title: 'LS',
        getCellValue: (row) => row.LS ?? null,
      },
      {
        name: 'LSDaysValueUSD',
        title: 'ValueUSDLSDays',
        getCellValue: (row) => row.LSDays?.V ?? 0,
      },
      {
        name: 'LSDaysCount',
        title: 'CountLSDays',
        getCellValue: (row) => row.LSDays?.C ?? 0,
      },
      {
        name: 'LSDaysAvg',
        title: 'AvgLSDays',
        getCellValue: (row) => row.LSDays?.A ?? 0,
      },
      {
        name: 'LSDaysAvgEth',
        title: 'AvgEthLSDays',
        getCellValue: (row) => row.LSDays?.AE ?? 0,
      },
      {
        name: 'LSDaysAvgUsdc',
        title: 'AvgUsdcLSDays',
        getCellValue: (row) => row.LSDays?.AU ?? 0,
      },
      {
        name: 'LSDaysMin',
        title: 'MinLSDays',
        getCellValue: (row) => row.LSDays?.Mi ?? 0,
      },
      {
        name: 'LSDaysMax',
        title: 'MaxLSDays',
        getCellValue: (row) => row.LSDays?.Ma ?? 0,
      },
      {
        name: 'IsFP',
        title: 'IsFP',
        getCellValue: (row) => isNull(row.OnSell?.V) ? '' : row.OnSell?.V === (row.FP?.V ?? 0) ? 'true' : 'false',
      },
      {
        name: 'OnSellPrice',
        title: 'OnSellPrice',
        getCellValue: (row) => row.OnSell?.V ?? null,
      },
    ] : []),
  };
  return ret;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const type = isNull(ownProps.type) ? configConstants.EXCHANGES_GRID_STATE_CHANGE_ACTION :
      ownProps.type === 'transfer' ? configConstants.TRANSFER_GRID_STATE_CHANGE_ACTION : configConstants.EXCHANGES_GRID_STATE_CHANGE_ACTION;
  return {
    onSortingChange: (sorting) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'sorting', sorting, type)),
    onSelectionChange: (selection) => {
      const selected = selection.map((s) => ownProps.exchange[s]);

      dispatch(createExchangesGridAction(ownProps.tokenType, 'selected', selected, type));
      dispatch(createExchangesGridAction(ownProps.tokenType, 'selection', selection, type));
    },
    onExpandedRowIdsChange: (expandedRowIds) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'expandedRowIds', expandedRowIds, type)),
    onGroupingChange: (grouping) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'grouping', grouping, type)),
    onExpandedGroupsChange: (expandedGroups) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'expandedGroups', expandedGroups, type)),
    onFiltersChange: (filters) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'filters', filters, type)),
    onCurrentPageChange: (currentPage) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'currentPage', currentPage, type)),
    onPageSizeChange: (pageSize) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'pageSize', pageSize, type)),
    onColumnOrderChange: (order) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'columnOrder', order, type)),
    onColumnWidthsChange: (widths) =>
        dispatch(createExchangesGridAction(ownProps.tokenType, 'columnWidths', widths, type)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(TableUIWallet));
