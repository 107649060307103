import { profilConstant } from '../../views/Onboarding/profil.constant';
import { onboardingConstants } from './constants';

const onboardingLS = localStorage.getItem('Onboarding');
const authLS = localStorage.getItem('authentication');
const showOnboarding = !authLS
  ? false
  : !onboardingLS || onboardingLS === 'true'
  ? true && localStorage.setItem('Onboarding', 'true')
  : false;

const onboardingInitialState = {
  profilUser: '',
  step: profilConstant.STEP_PROFIL,
  groupId: 0,
  groupName: '',
  groupLink: '',
  showDialog: showOnboarding,
};

export const onboarding = (state = onboardingInitialState, action) => {
  switch (action.type) {
    case onboardingConstants.CHANGE_PROFIL:
      return {
        ...state,
        profilUser: action.payload,
      };
    case onboardingConstants.CHANGE_STEP:
      return {
        ...state,
        previousStep: state.step,
        step: action.payload,
      };
    case onboardingConstants.CHANGE_DATA_TRADER:
      return {
        ...state,
        groupId: action.payload.GroupId,
        groupName: action.payload.Name,
        groupLink: action.payload.Link,
      };
    case onboardingConstants.CHANGE_SHOW_DIALOG:
      return {
        ...state,
        showDialog: action.payload,
      };
    default:
      return state;
  }
};
