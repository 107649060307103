import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput';
import MyDateTimePicker from '../../components/Fields/MyDateTimePicker';
import { postInsertInGameInfo } from '../../redux/user/actions';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogTrisel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      walletId: props.walletId,
      open: true,
      trisel: -1500,
      description: '',
      date: new Date(),
    };
  }

  onClick = () => {
    this.props.postInsertInGameInfoActions(this.state);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ open: false });
    const { onClose } = this.props;

    onClose();
  };

  onChangeDate = (date) => {
    this.setState({ date: new Date(date) });
  };

  onChangeDescription = (e) => {
    this.setState({ description: e.target.value });
  };
  onChangeTrisel = (e) => {
    this.setState({ trisel: parseInt(e.target.value) });
  };

  render() {
    const { open, date, trisel } = this.state;

    return (
      <Dialog
        maxWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <GridItem container>
          <GridItem xs={10} sm={10} md={10}>
            <DialogTitle id="alert-dialog-slide-title">
              Dépense Trisel
            </DialogTitle>
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <IconButton aria-label="close" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </GridItem>
        </GridItem>
        <DialogContent>
          <GridItem>
            {/*<GridItem>*/}
            {/*  Toto*/}
            {/*</GridItem>*/}
            <GridItem>
              <GridItem container justifyContent="center">
                <GridItem xs={12} sm={12} md={12}>
                  <div>Négatif: si c&apos;est une dépense</div>
                  <div>Positif: pour donner des Trisels au scholars</div>
                  <br />
                  <CustomInput
                    name="Trisel"
                    labelText="Trisel"
                    inputProps={{
                      defaultValue: trisel,
                      type: 'number',
                      onChange: this.onChangeTrisel,
                      // inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">Trisel</InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridItem>
              <GridItem container justifyContent="center">
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    name="Description"
                    labelText="Description"
                    style={{ marginTop: 0 }}
                    inputProps={{
                      onChange: this.onChangeDescription,
                    }}
                  />
                  <br />
                  <br />
                </GridItem>
              </GridItem>
              <GridItem container justifyContent="center">
                <GridItem>
                  {/*<DateTime name={'Date'} />*/}
                  <MyDateTimePicker
                    value={date}
                    onChange={this.onChangeDate}
                    disablePast={false}
                    lang={'en'}
                    // lang={codeLang}
                    name="Date"
                    // formName={'Trisel'}
                    // formName={formName}
                  />
                </GridItem>
              </GridItem>
              <GridItem container justifyContent="center">
                <Button
                  color="info"
                  position="center"
                  onClick={() => this.onClick()}
                  // disabled={this.state.invalidPercentage}
                >
                  Add
                  {/*<Translate id="smartTrade.sellMarket" />*/}
                </Button>
              </GridItem>
            </GridItem>
          </GridItem>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    postInsertInGameInfoActions: bindActionCreators(
      postInsertInGameInfo,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogTrisel);
