import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {getCodeLang, getDateStr} from "../../helpers/general";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import LanguageToggle from "../../components/Translate/LanguageToggle";
import CharaRavixPng from "../../assets/img/cta/background/Chara-ravix.png";
import {connect} from "react-redux";
import {darkColorTransparent, lightColorTransparent} from "../../assets/jss/material-dashboard-react";
import {CalendarToday, LibraryAdd, Redeem} from "@material-ui/icons";
import GridItem from "../../components/Grid/GridItem";
import TwitterFollowButton from "../../components/CustomButtons/TwitterFollowButton";
import DateRange from "@material-ui/icons/DateRange";

/* eslint-disable react/no-unknown-property */
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function miseAJourCompteur(codeLang) {
    const maintenant = new Date();
    const noel = new Date('Dec 19, ' + maintenant.getFullYear());
    const tempsRestant = noel - maintenant;

    let jours = Math.floor(tempsRestant / (1000 * 60 * 60 * 24));
    let heures = Math.floor((tempsRestant % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((tempsRestant % (1000 * 60 * 60)) / (1000 * 60));
    let secondes = Math.floor((tempsRestant % (1000 * 60)) / 1000);

    if (document.getElementById("temps-restant")) {
        if (codeLang === 'fr')
            document.getElementById("temps-restant").innerHTML = jours + " Jours " + heures + " Heures " + minutes + " Minutes " + secondes + " Secondes";
        else
            document.getElementById("temps-restant").innerHTML = jours + " days " + heures + " hours " + minutes + " minutes " + secondes + " seconds";
    }

}
setInterval(miseAJourCompteur, 1000);
let codeLang = 'fr';

const ComingSoonDialog = props => {
    codeLang = getCodeLang(props);
    const theme = props.theme;
    const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;
    return (<Dialog
        fullWidth
        // fullScreen
        // style={{width: '80%'}}
        // maxWidth={true}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        // onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent style={{ backgroundPosition: 'right', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(${color}, ${color}), url(${CharaRavixPng})`}}>
            <div>
                <div>
                    <p style={{fontSize: '20px'}}><DateRange />&nbsp;<Translate id="central.messageCommingSoon" /></p>
                    <p style={{fontSize: '20px'}}><LibraryAdd />&nbsp;<Translate id="central.info" /></p>
                    <p style={{fontSize: '20px'}}><Redeem /> <Translate id="central.info3" /></p>
                    <p style={{fontSize: '20px'}}><Translate id="central.info2" /></p>
                    <div style={{textAlign:'center'}}><b><div id="temps-restant">Soon...</div></b></div>
                </div>
                <br />

                <div>
                    <GridItem container justifyContent="center">
                        <TwitterFollowButton />
                    </GridItem>
                    <div style={{position: 'relative', float: 'right'}}><LanguageToggle /></div>
                </div>
            </div>
        </DialogContent>
    </Dialog>)
};
function mapStateToProps(state) {
    const { config } = state;
    return {
        theme: config.theme,
    };
}

export default connect(mapStateToProps, null)(withLocalize(ComingSoonDialog));