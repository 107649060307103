import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change, FormSection } from 'redux-form';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SwitchUI from '@material-ui/core/Switch/Switch';
import { putUserNotifications } from '../../redux/config/actions';
import Button from '../../components/CustomButtons/Button';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { querySelector } from '../../helpers/selector.js';

const formName = 'notificationForm';
const NotificationsForm = (props) => {
  const dispatch = useDispatch();
  const { valuesForm } = props;
  const sectionName = 'Options';
  const query = useSelector(querySelector);

  const isAllEmailChecked = () => {
    let result = true;
    valuesForm.Options.forEach((option) => {
      if (option.Email === false) result = false;
    });
    return result;
  };

  const isAllTelegramChecked = () => {
    let result = true;
    valuesForm.Options.forEach((option) => {
      if (option.Telegram === false) result = false;
    });
    return result;
  };

  const changeAll = (bolean, type) => {
    for (let i = 0; i < valuesForm.Options.length; i += 1)
      dispatch(change(formName, `${sectionName}.${i}.${type}`, bolean));
  };

  return (
    <form>
      <FormSection name={sectionName}>
        {valuesForm.Options?.map((option, key) => {
          const transName = 'settings.notifications.' + option.Notification;
          return (
            <div key={key}>
              <GridContainer>
                <GridItem xs={4} sm={6} md={6}>
                  <div style={{ marginTop: '8px' }}>
                    <Translate id={transName} />
                  </div>
                </GridItem>
                <GridItem xs={4} sm={3} md={3}>
                  <SwitchUI
                    checked={option.Email}
                    onChange={() => {
                      dispatch(
                        change(
                          formName,
                          `${sectionName}.${key}.Email`,
                          !option.Email,
                        ),
                      );
                    }}
                    value="theme"
                    color="primary"
                  />
                </GridItem>
                <GridItem xs={4} sm={3} md={3}>
                  <SwitchUI
                    checked={option.Telegram}
                    onChange={() => {
                      dispatch(
                        change(
                          formName,
                          `${sectionName}.${key}.Telegram`,
                          !option.Telegram,
                        ),
                      );
                    }}
                    value="theme"
                    color="primary"
                  />
                </GridItem>
              </GridContainer>
              <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
            </div>
          );
        })}
        <GridContainer>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ marginTop: '8px' }}>
              <Translate id="settings.selectDeselectAll" />
            </div>
            {/* <Translate id={transName} /> */}
          </GridItem>
          <GridItem xs={4} sm={3} md={3}>
            <SwitchUI
              checked={isAllEmailChecked()}
              onChange={() => {
                changeAll(!isAllEmailChecked(), 'Email');
              }}
              value="theme"
              color="primary"
            />
          </GridItem>
          <GridItem xs={4} sm={3} md={3}>
            <SwitchUI
              checked={isAllTelegramChecked()}
              onChange={() => {
                changeAll(!isAllTelegramChecked(), 'Telegram');
              }}
              value="theme"
              color="primary"
            />
          </GridItem>
        </GridContainer>
        <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
        <GridContainer justifyContent="center">
          {/* <GridItem xs={12} sm={12} md={12}> */}
          <Button
            color="primary"
            round
            target={'_blank'}
            onClick={() => {
              dispatch(
                putUserNotifications(valuesForm, query?.email, query?.token),
              );
            }}
            style={{ marginTop: '10px', marginBottom: '-5px' }}
          >
            <Translate id="central.save" />
          </Button>
          {/* </GridItem> */}
        </GridContainer>
      </FormSection>
    </form>
  );
};

let notificationsForm = reduxForm({
  form: formName, // a unique identifier for this form
})(NotificationsForm);

const mapStateToProps = (state, props) => {
  const { form } = state;
  return {
    valuesForm:
      form && form[formName] && form[formName].values
        ? form[formName].values
        : props.notificationSettings,
    initialValues: props.notificationSettings,
  };
};

export default connect(mapStateToProps, null)(notificationsForm);
