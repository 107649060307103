import React from 'react';
import GridItem from '../../components/Grid/GridItem.jsx';
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import AureliusPng from "../../assets/img/cta/background/Chara-aurelius-24b.png";
import CardBody from "../../components/Card/CardBody";
import {Translate, withLocalize} from "react-localize-redux";
import {darkColorTransparent, lightColorTransparent} from "../../assets/jss/material-dashboard-react";
import {connect} from "react-redux";
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";

const HomePage = props => {
    const theme = props.theme;
    const color = theme === 'dark' ? darkColorTransparent : lightColorTransparent;
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}></GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card style={{ backgroundPosition: 'right', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(${color}, ${color}), url(${AureliusPng})`}}>
                    <CardBody>
                        <center><h3><Translate id="central.welcomeMessage" options={{ renderInnerHtml: true }} /></h3></center>
                        <h4><Translate id="central.currentFeatures" options={{ renderInnerHtml: true }} />&nbsp;:</h4>
                        <ul>
                            <li><Translate id="central.accountPage" options={{ renderInnerHtml: true }} /></li>
                        </ul>
                        <h4><Translate id="central.futureFeatures" options={{ renderInnerHtml: true }} />&nbsp;:</h4>
                        <ul>
                            <li><Translate id="central.collectionPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.guildsPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.marketPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.swapNFTPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.deckBuildingPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.statsPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.alertsPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.tournamentPage" options={{ renderInnerHtml: true }} /></li>
                            <li><Translate id="central.miniGamePage" options={{ renderInnerHtml: true }} /></li>
                        </ul>
                        <br/>
                        <GridContainer justifyContent="center">
                            <Link to={'/identification'}>
                                <Button round color="primary"><Translate id="menu.identification" /></Button>
                            </Link>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}



function mapStateToProps(state) {
    const { config } = state;
    return {
        theme: config.theme,
    };
}

export default connect(mapStateToProps, null)(withLocalize(HomePage));