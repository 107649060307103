import { cardsConstants } from './constants';
import {
  getObjectFromAsset,
  getOrderDetailFromJson,
  getOrderFromTab,
  getTabFromJson,
  insertDetailInOrder,
} from '../../helpers/card';

const initialState = {
  cards: [],
  cardsSold: [], //Card a l'unité
  cardsOnSell: [], //Card a l'unité
  cardsSoldDetail: [], //Card regrouper par key
  cardsOnSellDetail: [], //Card regrouper par key
  tabSold: [], // Dictionnary par key
  tabOnSell: [], // Dictionnary par key
  assets: [],
  supplies: [],
  detail: false,
  onSell: false,
};

export function card(state = initialState, action) {
  switch (action.type) {
    case cardsConstants.ACTION_SET_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case cardsConstants.ACTION_SET_ON_SELL:
      return {
        ...state,
        onSell: action.payload,
      };
    case cardsConstants.ACTION_GET_CARDS_ON_SELL: {
      const tab = getTabFromJson(action.payload);
      const orders = getOrderFromTab(tab);
      const ordersOther = state.cardsSold;
      for (const order of orders) insertDetailInOrder(order, state.tabSold);
      for (const order of ordersOther) insertDetailInOrder(order, tab);

      return {
        ...state,
        cardsSold: ordersOther,
        tabOnSell: tab,
        cardsOnSell: orders,
        cardsOnSellDetail: getOrderDetailFromJson(action.payload),
      };
    }
    case cardsConstants.ACTION_GET_CARDS_SOLD: {
      const tab = getTabFromJson(action.payload);
      const orders = getOrderFromTab(tab);
      const ordersOther = state.cardsOnSell;
      for (const order of orders) insertDetailInOrder(order, state.tabOnSell);
      for (const order of ordersOther) insertDetailInOrder(order, tab);

      return {
        ...state,
        cardsOnSell: ordersOther,
        tabSold: tab,
        cardsSold: orders,
        cardsSoldDetail: getOrderDetailFromJson(action.payload),
      };
    }
    case cardsConstants.ACTION_GET_ASSETS:
      action.payload.forEach(
        (x) => { x.Asset = getObjectFromAsset(x.AssetDto);
          return x;
        },
      );
      return {
        ...state,
        assets: action.payload,
      };
    case cardsConstants.ACTION_GET_CARDS:
    case cardsConstants.ACTION_LOCAL_CARDS: {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case cardsConstants.ACTION_GET_SUPPLIES:
      return {
        ...state,
        supplies: action.payload,
      };
    default:
      return state;
  }
}
