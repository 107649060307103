import { push } from 'connected-react-router';
import { traderConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';
import { alertActions } from '../alert/alert.actions';
import { Translate } from '../../helpers/general';
import { onboardingConstants } from '../onboarding/constants';

export const getTraders = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Group/Groups', {});
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_TRADERS,
      payload: eu,
    });
  }
};

export const getTrader =
  (id, reference = '') =>
  async (dispatch) => {
    const eu = await fetcher(
      dispatch,
      `api/v1/Group?id=${id}&reference=${reference}`,
      {},
    );
    if (eu) {
      dispatch({
        type: traderConstants.ACTION_GET_TRADER,
        payload: eu,
      });
    }
  };

export const getStatTrader = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/StatGroup/${id}`, {});
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_STAT_TRADER,
      payload: eu,
    });
  }
};

export const postSubscriptionGroup = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Group/SubscriptionGroup', {
    method: 'POST',
    body,
  });
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_POST_SUBSCRIPTION_GROUP,
      payload: eu,
    });
  }
};

export const postUnSubscribeGroup = (id) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/SubscriptionGroup/${id}`, {
    method: 'DELETE',
  });
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_POST_UNSUBSCRIBE_GROUP,
      payload: eu,
    });
  }
};
export const postPaiement = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/PaiementCoinbase/CreatePaiement', {
    method: 'POST',
    body,
  });
  if (eu && eu.Success === true) {
    dispatch({
      type: traderConstants.ACTION_PAIEMENT,
      payload: eu.Result,
    });
  }
};

export const postGroup =
  (body, redirect = false) =>
  async (dispatch) => {
    const eu = await fetcher(dispatch, 'api/v1/Group', {
      method: 'POST',
      body,
    });
    if (eu) {
      dispatch({
        type: traderConstants.ACTION_GET_TRADER,
        payload: eu,
      });
      dispatch({
        type: onboardingConstants.CHANGE_DATA_TRADER,
        payload: eu,
      });
      if (redirect) dispatch(push(`/manageFollowers/${eu.GroupId}`));
    }
  };

export const putGroup = (body) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/${body.groupId}`, {
    method: 'PUT',
    body,
  });
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_TRADER,
      payload: eu,
    });
    dispatch(push('/traders'));
  }
};

export const deleteGroup = (groupId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/${groupId}`, {
    method: 'DELETE',
  });
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_DELETE_TRADER,
      payload: groupId,
    });
    dispatch(push('/traders'));
  }
};

export const addTrader = (reference) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Group/AddTrader?reference=${reference}`,
    {
      method: 'POST',
      body: reference,
    },
  );
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_TRADER,
      payload: eu,
    });
  }
};

export const addFollower =
  (request, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, 'api/v1/Group/AddUser', {
      method: 'POST',
      body: request,
    });
    if (eu) {
      const text = Translate(getState, 'confirmation.followerAdded');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      // dispatch(reset(traderConstants.FORM_ADD_FOLLOWER));
      dispatch(push(`/manageFollowers/${request.groupId}`));
    }
  };

export const updateFollower =
  (request, noPopupTrading = false) =>
  async (dispatch, getState) => {
    const eu = await fetcher(dispatch, 'api/v1/Group/UpdateUser/', {
      method: 'PUT',
      body: request,
    });
    if (eu) {
      const text = Translate(getState, 'confirmation.followerUpdated');
      if (!noPopupTrading) dispatch(alertActions.alertSuccess(text));
      dispatch(push(`/manageFollowers/${request.groupId}`));
    }
  };

export const getFollower = (groupId, userId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Group/${groupId}/User/${userId}`,
    {},
  );
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_FOLLOWER,
      payload: eu,
    });
  }
};

export const getFollowers = (groupId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/${groupId}/Users`, {});
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_SCHOLARS,
      payload: eu,
    });
  }
};

export const cleanOrders = (groupId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/CleanOrders/${groupId}`, {});
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_SCHOLARS,
      payload: eu,
    });
  }
};

export const refreshAccountGroup = (accountId) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `api/v1/Group/RefreshAccount?id=${accountId}`,
    {},
  );
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_REFRESH_FOLLOWER,
      payload: eu,
    });
  }
};
export const toggleActiveUser = (groupId, userId) => async (dispatch) => {
  const eu = await fetcher(dispatch, `api/v1/Group/${groupId}/User/${userId}`, {
    method: 'PUT',
  });
  if (eu) {
    dispatch({
      type: traderConstants.ACTION_GET_FOLLOWER,
      payload: eu,
    });
  }
};
