import React from 'react';
import Button from '../CustomButtons/Button';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { isNull } from '../../views/SmartTrade/Common';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { alertPopup } from '../../redux/alert/alert.actions';

const DisconnectAs = () => {
  const authAs = JSON.parse(localStorage.getItem('AuthAs'));
  const dispatch = useDispatch();
  if (isNull(authAs)) return <div />;
  return (
    <div style={{ textAlign: 'center' }}>
      <Translate id="central.authAs" />
      :&nbsp;
      <Button
        style={{ textTransform: 'none', padding: '8px' }}
        onClick={() => {
          alertPopup({
            type: 'confirm',
            then: (result) => {
              if (result.value) {
                localStorage.removeItem('AuthAs');
                window.location.reload();
              }
            },
          })(dispatch);
        }}
        color="primary"
        round
      >
        <div style={{ textAlign: 'center' }}>
          <PowerSettingsNew />
          &nbsp;
          {authAs.email}
        </div>
      </Button>
    </div>
  );
};

export default DisconnectAs;
