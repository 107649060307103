import { isNull } from '../../views/SmartTrade/Common';

export const userIdSelector = (state) => {
  const authAs = JSON.parse(localStorage.getItem('AuthAs'));
  if (authAs != null) return authAs.userId;
  if (
    isNull(state) ||
    isNull(state.authentication) ||
    isNull(state.authentication.auth) ||
    isNull(state.authentication.auth.id)
  )
    return '';
  return state.authentication.auth.id;
};

export const loggedInSelector = (state) => {
  if (!state || !state.authentication || !state.authentication.loggedIn)
    return false;
  return state.authentication.loggedIn;
};

export const rolesSelector = (state) => {
  if (
    !state ||
    !state.authentication ||
    !state.authentication.auth ||
    !state.authentication.auth.roles
  )
    return [];
  return state.authentication.auth.roles;
};

export const isAdminSelector = (state) => {
  const authAs = JSON.parse(localStorage.getItem('AuthAs'));
  if (authAs != null) return false;
  const roles = rolesSelector(state);
  return roles.includes('Admin');
};
