export const exchangeConstants = {
  TRANSFER_POPUP: '@user/TRANSFER_POPUP',
  ACTION_GET_SCHOLARS: '@user/ACTION_GET_SCHOLARS',
  ACTION_GET_SCHOLAR: '@user/ACTION_GET_SCHOLAR',

  ACTION_RESET_WALLET: '@user/ACTION_RESET_WALLET',
  ACTION_GET_WALLET: '@user/ACTION_GET_WALLET',
  ACTION_GET_WALLETS: '@user/ACTION_GET_WALLETS',
  ACTION_LOCAL_WALLETS: '@user/ACTION_LOCAL_WALLETS',
  ACTION_POST_WALLET: '@user/ACTION_POST_WALLET',
  ACTION_PUT_WALLET: '@user/ACTION_PUT_WALLET',
  ACTION_DELETE_WALLET: '@user/ACTION_DELETE_WALLET',

  ACTION_GET_AVAILABLE_AMOUNT: '@user/ACTION_GET_AVAILABLE_AMOUNT',
  ACTION_LOCAL_AVAILABLE_AMOUNT: '@user/ACTION_LOCAL_AVAILABLE_AMOUNT',
  ACTION_GET_ORDERS: '@user/ACTION_GET_ORDERS',
  ACTION_LOCAL_ORDERS: '@user/ACTION_LOCAL_ORDERS',
};
