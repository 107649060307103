import { push } from 'connected-react-router';
import { onboardingConstants } from './constants';
import { Translate } from '../../helpers/general';
import { alertActions } from '../alert/alert.actions';
import { fetcher } from '../../helpers/fetcher';

export const changeProfil = (profil) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_PROFIL,
    payload: profil,
  });
};

export const changeStep = (step) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_STEP,
    payload: step,
  });
};

// eslint-disable-next-line no-unused-vars
export const sendWhiteLabelText = (body) => async (dispatch, getState) => {
  // const eu = await fetcher(dispatch, 'api/WhiteLabelText', {
  //   method: 'POST',
  //   body: body,
  // });
  // if (eu) {
  const text = Translate(getState, 'onboarding.whiteLabelTextSent');
  dispatch(alertActions.alertSuccess(text));
  // }
};

export const changeShowDialog = (dialog) => async (dispatch) => {
  dispatch({
    type: onboardingConstants.CHANGE_SHOW_DIALOG,
    payload: dialog,
  });
};

export const addFollowerOnboarding = (request) => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/Group/AddUser', {
    method: 'POST',
    body: request,
  });
  if (eu) {
    dispatch(push(`/addFollower/${request.groupId}`));
  }
};
