import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  successColor,
  warningColor,
  dangerColor,
  darkColor,
} from '../../material-dashboard-react.jsx';
import { lightColor } from '../../material-dashboard-react';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.type === 'dark' ? darkColor : lightColor,
    overflow: 'hidden',
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  logo: {
    position: 'relative',
    padding: '10px 2px',
    zIndex: '4',
    backgroundColor: theme.palette.type === 'dark' ? darkColor : lightColor,
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: theme.palette.type === 'dark' ? darkColor : lightColor,
      color: theme.palette.type === 'dark' ? lightColor : darkColor,
    },
  },
  logoLink: {
    ...defaultFont,
    // textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
    '&:hover,&:focus,&:visited,&': {
      color: theme.palette.type === 'dark' ? lightColor : darkColor,
    },
  },
  logoImage: {
    width: '50px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    width: '60px',
    top: '5px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: '#000',
      opacity: '.8',
    },
  },
  list: {
    marginTop: '0px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: theme.palette.type === 'dark' ? lightColor : darkColor,
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '4px 10px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '4px 5px',
    backgroundColor: 'transparent',
    ...defaultFont,
  },
  itemIcon: {
    display: 'inline-block',
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '15px',
    marginTop: '2px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
    // color: 'rgba(255, 255, 255, 0.8)'
  },
  itemText: {
    ...defaultFont,
    display: 'inline-block',
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    color: theme.palette.type === 'dark' ? lightColor : darkColor,
    // color: '#FFFFFF'
  },
  itemIconCollapse: {
    marginTop: '2px',
    float: 'right',
  },
  whiteFont: {
    color: lightColor,
  },
  purple: {
    backgroundColor: primaryColor,
    ...primaryBoxShadow,
    '&:hover': {
      backgroundColor: primaryColor,
      ...primaryBoxShadow,
    },
  },
  blue: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 12px 20px -10px rgba(43,82,120,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(43,82,120,.2)',
    '&:hover': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 12px 20px -10px rgba(43,82,120,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(43,82,120,.2)',
    },
  },
  green: {
    backgroundColor: successColor,
    boxShadow:
      '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
    '&:hover': {
      backgroundColor: successColor,
      boxShadow:
        '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
    },
  },
  orange: {
    backgroundColor: warningColor,
    boxShadow:
      '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
    '&:hover': {
      backgroundColor: warningColor,
      boxShadow:
        '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
    },
  },
  red: {
    backgroundColor: dangerColor,
    boxShadow:
      '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
    '&:hover': {
      backgroundColor: dangerColor,
      boxShadow:
        '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
    },
  },
  sidebarWrapper: {
    backgroundColor: theme.palette.type === 'dark' ? darkColor : lightColor,
    position: 'relative',
    height: 'calc(100vh - 115px)',
    overflow: 'auto',
    width: '260px',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
});

export default sidebarStyle;
