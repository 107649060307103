import React from 'react';
import { FileCopyTwoTone } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import '../../assets/css/copy-function.css';
import Box from "@material-ui/core/Box";

export default function CopyButton({copyContent, style}) {
    const [copied, setCopied] = React.useState(false);

    const copy = async (toCopy) => {
        await navigator.clipboard.writeText(toCopy);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };
    return (
        <div onClick={() => copy(typeof copyContent === 'function' ? copyContent() : copyContent)} style={{...style, cursor: 'pointer'}}>
            {!copied ? <FileCopyTwoTone/> : <CheckIcon className="wobble"/>}
        </div>
    );
}