import { cardsConstants } from './constants';
import { fetcher } from '../../helpers/fetcher';

export const setDetail = (detail) => async (dispatch) => {
  dispatch({
    type: cardsConstants.ACTION_SET_DETAIL,
    payload: detail,
  });
};
export const setOnSell = (onSell) => async (dispatch) => {
  dispatch({
    type: cardsConstants.ACTION_SET_ON_SELL,
    payload: onSell,
  });
};

export const getSupplies = (supplyType, set, groupAnimationLevel, groupElement) => async (dispatch) => {
  const eu = await fetcher(
      dispatch,
      `Onchain/supply`,{
        method: 'POST',
        body: {
          supplyType,
          set,
          groupAnimationLevel,
          groupElement
        }
      },
  );
  if (eu) {
    dispatch({
      type: cardsConstants.ACTION_GET_SUPPLIES,
      payload: eu,
    });
  }
};

export const getAssets = (status, tokenType, detail) => async (dispatch) => {
  const eu = await fetcher(
    dispatch,
    `Onchain/orders?status=${status}&tokenType=${tokenType}&detail=${detail}`,
    {},
  );
  if (eu) {
    dispatch({
      type: cardsConstants.ACTION_GET_ASSETS,
      status: status,
      tokenType: tokenType,
      payload: eu,
    });
  }
};

export const getCardsSold = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Onchain/orders?status=filled', {});
  if (eu) {
    dispatch({
      type: cardsConstants.ACTION_GET_CARDS_SOLD,
      payload: eu,
    });
  }
};

export const getCardsOnSell = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'Onchain/orders?status=active', {});

  if (eu) {
    dispatch({
      type: cardsConstants.ACTION_GET_CARDS_ON_SELL,
      payload: eu,
    });
  }
};
export const getCards = () => async (dispatch) => {
  const eu = await fetcher(dispatch, 'api/v1/Card/Cards', {});

  if (eu) {
    dispatch({
      type: cardsConstants.ACTION_GET_CARDS,
      payload: eu,
    });
  }
};
